<template>
  <span class="ads-type-21 cursor-pointer" @click.prevent="onAdsClick">
    <component :is="pageLayout" :material="materials[0]" />
  </span>
</template>

<script>
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import PcJob from "./layout/PcJob.vue";
import MobileJob from "./layout/MobileJob.vue";
import AppJobPage from "./layout/AppJobPage.vue";

export default {
  name: "AdsType21",
  components: {
    [PAGE_ENUM.PC__JOB_PAGE]: PcJob,
    [PAGE_ENUM.MOBILE__JOB_PAGE]: MobileJob,
    [PAGE_ENUM.APP__JOB_PAGE]: AppJobPage,
    [PAGE_ENUM.APP__PUSH]: PcJob
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    pageLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    linkHref() {
      return this.materials[0].contents.bannerText.link;
    }
  },
  mounted() {
    this.$emit("ads:entry", 0);
  },
  methods: {
    onAdsClick() {
      this.$emit("ads:click", this.linkHref, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.ads-type-21 {
  display: inline-block;
}
</style>
