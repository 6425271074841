var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"type-root",staticClass:"ads-type-18",on:{"mouseenter":_vm.pauseTimer,"mouseleave":_vm.restartTimer}},[_c('MainSlider',{attrs:{"material":_vm.mainMaterial},on:{"ads:click":_vm.clickMainSlider}}),_c('SubSliderWindow',{scopedSlots:_vm._u([_vm._l(([
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right'
      ]),function(name,index){return {key:name,fn:function(ref){
      var activeSlot = ref.activeSlot;
return [_c('SubSlider',{key:name,attrs:{"material":_vm.subMaterials[index],"active":activeSlot === name},on:{"ads:click":function (linkHref) { return _vm.clickSubSlider(linkHref, index); }}})]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }