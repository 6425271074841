import {
  AdsmartBoard,
  AdsmartBoardEntry,
  AdsmartBoardDynamic,
  AdsmartBoardKeyword,
  AdsmartBoardCompose,
  AdsmartBoardComposeEntry,
  AdsmartBoardComposeDynamic,
  AdsmartBoardComposeKeyword
} from "./AdsmartBoard";
import typeConfig from "../settings/typesConfig.js";
import { MODE_ENUM } from "../settings/typesConfig.js";

export default class AdsmartBoardsMap extends Map {
  constructor() {
    super();
  }

  get isEveryBoardsShow() {
    const headBoard = this.values();
    for (let i = 0; i < this.size; i++) {
      if (!headBoard.next().value.isMaterialsEmpty) {
        return true;
      }
    }
    return false;
  }

  getAdsmartBoardClass(mode) {
    switch (mode) {
      case MODE_ENUM.COMPOSE:
      case MODE_ENUM.COMPOSE_CUSTNO:
        return AdsmartBoardCompose;
      case MODE_ENUM.COMPOSE_KEYWORD:
        return AdsmartBoardComposeKeyword;
      case MODE_ENUM.COMPOSE_ENTRY:
        return AdsmartBoardComposeEntry;
      case MODE_ENUM.COMPOSE_DYNAMIC:
        return AdsmartBoardComposeDynamic;
      case MODE_ENUM.DYNAMIC:
        return AdsmartBoardDynamic;
      case MODE_ENUM.KEYWORD:
        return AdsmartBoardKeyword;
      case MODE_ENUM.CUSTNO:
        return AdsmartBoard;
      case MODE_ENUM.ENTRY:
      default:
        return AdsmartBoardEntry;
    }
  }

  createBoard(boardData, adsmartTracker) {
    const { mode } = typeConfig[boardData.typeId];
    const AdsmartBoardClass = this.getAdsmartBoardClass(mode);
    return new AdsmartBoardClass(boardData, adsmartTracker);
  }
}
