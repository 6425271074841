import ClickExpose from "./ClickExpose.js";
import ViewExpose from "./ViewExpose.js";
import EntryViewExpose from "./EntryViewExpose.js";

export default class AdsmartExposeController {
  #clickExpose;
  #viewExpose;
  #entryViewExpose;

  constructor(token = "", groupNo = 0) {
    this.token = token;
    this.groupNo = groupNo;
    this.#clickExpose = this.#getClickExpose();
    this.#viewExpose = this.#getViewExpose();
    this.#entryViewExpose = this.#getEntryViewExpose();
  }

  #getClickExpose(ClickExposeClass = ClickExpose) {
    return new ClickExposeClass(this.token);
  }

  #getViewExpose(ViewExposeClass = ViewExpose) {
    return new ViewExposeClass(this.token);
  }

  #getEntryViewExpose(EntryViewExposeClass = EntryViewExpose) {
    return new EntryViewExposeClass(this.#viewExpose);
  }

  async click(shelfId) {
    await this.#clickExpose.expose(shelfId, this.groupNo);
  }

  async view(shelfIdAry) {
    await this.#viewExpose.expose(shelfIdAry, this.groupNo);
  }

  async entry(boardIdentify, shelfIdAry) {
    await this.#entryViewExpose.entry(boardIdentify, shelfIdAry, this.groupNo);
  }

  addEntryQueue(boardIdentify) {
    this.#entryViewExpose.addEntryQueue(boardIdentify);
  }
}
