const NORMAL_TYPE = {
  TYPE_1: 1,
  TYPE_2: 2,
  TYPE_4: 4,
  TYPE_5: 5,
  TYPE_7: 7,
  TYPE_8: 8,
  TYPE_9: 9,
  TYPE_14: 14,
  TYPE_15: 15,
  TYPE_16: 16,
  TYPE_17: 17,
  TYPE_18: 18,
  TYPE_20: 20,
  TYPE_21: 21,
  TYPE_22: 22,
  TYPE_25: 25,
  TYPE_26: 26,
  TYPE_27: 27,
  TYPE_28: 28,
  TYPE_29: 29
};

export const COMPOSE_TYPE = {
  TYPE_100: 100,
  TYPE_101: 101,
  TYPE_102: 102,
  TYPE_103: 103,
  TYPE_104: 104,
  TYPE_105: 105,
  TYPE_106: 106,
  TYPE_107: 107,
  TYPE_108: 108
};

export default {
  ...NORMAL_TYPE,
  ...COMPOSE_TYPE
};
