<template>
  <div class="app-who-notify d-flex">
    <div class="app-who-notify__image">
      <img
        :src="
          material.contents.image.element ||
          `${env.VUE_APP_CDN_STATIC_URL}images/personalHeader/img-blank-profile@2x.png`
        "
      />
    </div>

    <div class="app-who-notify__content">
      <p class="app-who-notify__content__title">
        {{ material.contents.title.element }}
      </p>
      <p class="app-who-notify__content__subtitle">
        {{ material.contents.subtitle.element }}
      </p>
    </div>

    <div class="app-who-notify__sponsor">
      <span>贊助</span>
    </div>
  </div>
</template>

<script>
import env from "@/utils/env.js";

export default {
  name: "AppWhoNotify",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      env
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-who-notify {
  display: flex;
  padding: 12px 16px;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 16px;
    overflow: hidden;

    &__title {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: normal;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      color: #7e7e7e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__sponsor {
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: start;
    min-width: 28px;
    margin-top: 2px;
    font-size: 10px;
    line-height: 18px;
    color: #ea475b;

    > span {
      padding: 0 4px;
      border: 1px solid #ea475b;
      border-radius: 4px;
      white-space: nowrap;
    }
  }
}
</style>
