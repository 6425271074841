<template>
  <span class="mobile-search-bar">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "MobileSearchBar"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.mobile-search-bar {
  display: block;
  width: 100%;
  padding: 8px;
  color: #ff7800;
  font-size: 15px;
  line-height: 24.3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
