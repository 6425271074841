<template>
  <div class="advert-type17">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div
          v-for="(material, index) of materials"
          :key="`slide-${index}`"
          :data-material-index="index"
          class="swiper-slide"
        >
          <section class="job-card">
            <div class="job-card__content">
              <div
                class="job-card__img cursor-pointer"
                data-content-name="headerImage"
                @click="onClick(material.contents.headerImage.link)"
              >
                <PreventLink
                  class="job-card__img__link"
                  :href="material.contents.bodyTitle.linkWithJobsource"
                >
                  <img :src="material.contents.headerImage.element" />
                </PreventLink>
              </div>
              <div class="job-card__body">
                <h5
                  class="job-card__body__title cursor-pointer"
                  data-content-name="bodyTitle"
                  @click="onClick(material.contents.bodyTitle.link)"
                >
                  <PreventLink
                    class="job-card__body__title__link"
                    :href="material.contents.bodyTitle.linkWithJobsource"
                    v-text="material.contents.bodyTitle.element"
                  />
                </h5>
                <p
                  class="job-card__body__text cursor-pointer"
                  data-content-name="bodyContent"
                  @click="onClick(material.contents.bodyContent.link)"
                >
                  <PreventLink
                    class="job-card__body__text__link"
                    :href="material.contents.bodyContent.linkWithJobsource"
                    v-text="material.contents.bodyContent.element"
                  />
                </p>
              </div>
            </div>
            <div class="job-card__footer">
              <ul class="job-card__footer__title-list list-inline m-0">
                <li class="job-card__footer__title-list__icon">
                  <img src="@/assets/adType17/icon_apply.svg" />
                </li>
                <li
                  class="job-card__footer__title-list__item cursor-pointer list-inline-item"
                  data-content-name="footerTextLeft"
                  @click="onClick(material.contents.footerTextLeft.link)"
                >
                  <PreventLink
                    :href="material.contents.footerTextLeft.linkWithJobsource"
                    v-text="material.contents.footerTextLeft.element"
                  />
                </li>
                <li
                  class="job-card__footer__title-list__item cursor-pointer list-inline-item"
                  data-content-name="footerTextRight"
                  @click="onClick(material.contents.footerTextRight.link)"
                >
                  <PreventLink
                    :href="material.contents.footerTextRight.linkWithJobsource"
                    v-text="material.contents.footerTextRight.element"
                  />
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <template v-if="materials.length > 1">
        <div ref="swiper-button-prev" class="swiper-button-prev">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-left-gray.png"
          />
        </div>
        <div ref="swiper-button-next" class="swiper-button-next">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-right-gray.png"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "AdsType17",
  components: { PreventLink },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    adsLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    isMultiple() {
      return this.materials.length > 1;
    }
  },
  mounted() {
    const vm = this;
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, Navigation],
      enabled: vm.materials.length > 1,
      allowTouchMove: false,
      preventClicks: true,
      loop: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      navigation: {
        nextEl: this.$refs["swiper-button-next"],
        prevEl: this.$refs["swiper-button-prev"]
      },
      on: {
        click(swiper, event) {
          swiper.allowClick = false;
          const materialIndex = Number(
            swiper?.clickedSlide?.dataset?.materialIndex
          );
          if (swiper.realIndex !== materialIndex) {
            console.log("click", swiper.realIndex, materialIndex);
          }
          const material = vm.materials[materialIndex];
          const { contentName } = event.path[0].dataset;
          if (!contentName) return;
          const { link } = material.contents[contentName];
          if (!link) return;
          vm.$emit("ads:click", link, materialIndex);
        },
        realIndexChange(swiper) {
          vm.$emit("ads:view", swiper.realIndex);
        }
      }
    });

    this.$emit("ads:entry", 0);
  },
  methods: {
    onClick(linkHref) {
      if (this.materials.length === 1) {
        this.$emit("ads:click", linkHref, 0);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";
@import "node_modules/swiper/swiper.scss";

.advert-type17 {
  width: 240px;
}

.swiper {
  position: relative;
  z-index: 0;
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  position: absolute;
  top: 32px;
  outline: 0;
  margin: 0;
  z-index: 10;
  width: 16px;
  height: 16px;
  cursor: pointer;
  > img {
    display: block;
  }
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-slide {
  .job-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;

    &__content {
      // cursor: pointer;

      .job-card__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 140px;
        position: relative;

        &__link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .job-card__body {
        &__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.2;

          &__link {
            display: block;
            padding-top: 14px;
            padding-bottom: 8px;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            color: #292929;
          }
        }

        &__text {
          position: relative;
          height: 66px;
          padding-bottom: 6px;
          color: #7e7e7e;
          overflow: hidden;

          &__link {
            display: block;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            color: #7e7e7e;
          }
        }
      }

      &:hover {
        .job-card__img__link img {
          opacity: 0.8;
        }
        .job-card__body__title__link {
          color: #ff7800;
        }
      }
    }

    &__footer {
      background-color: #ffffff;
      height: 24px;
      line-height: 24px;

      &__title-list {
        display: table;
        word-spacing: -1em;
        margin: 0;
        padding-left: 0;
        list-style: none;

        &__icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 8px;

          img {
            width: 100%;
            vertical-align: middle;
          }
        }

        &__item {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 0.5rem;
          }

          & a {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: #1654b9;

            &:visited {
              color: #168ccc;
            }
            &:hover {
              color: #4e91ff;
            }
          }
        }
      }
    }
  }
}
</style>
