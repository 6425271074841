<template>
  <component
    :is="pageLayout"
    :material="materials[0]"
    :ads-layout="adsLayout"
    @ads:click="onAdsClick(materials[0].contents.companyLogo.link)"
  />
</template>

<script>
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import PcCompanyList from "./layout/PcCompanyList.vue";
import MobileCompanyList from "./layout/MobileCompanyList.vue";
import AppCompanyList from "./layout/AppCompanyList.vue";

export default {
  name: "AdsType27",
  components: {
    [PAGE_ENUM.PC__COMPANY_EVENT_LIST]: PcCompanyList,
    [PAGE_ENUM.MOBILE__COMPANY_EVENT_LIST]: MobileCompanyList,
    [PAGE_ENUM.APP__COMPANY_EVENT_LIST]: AppCompanyList
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    adsLayout: {
      type: String,
      default: ""
    },
    pageLayout: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.$emit("ads:entry", 0);
  },
  methods: {
    onAdsClick(linkHref) {
      this.$emit("ads:click", linkHref, 0);
    }
  }
};
</script>
