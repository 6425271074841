<template>
  <section
    class="brand_card"
    :class="{ 'cursor-pointer': Boolean(material.contents.bodyTitle.link) }"
    @click="onClick(material.contents.bodyTitle.link)"
  >
    <div
      class="brand_card__img"
      :style="{
        'background-image': `url(${material.contents.imageLg.element})`
      }"
    ></div>
    <div class="brand_card__overlay">
      <h5
        class="brand_card__title cursor-pointer"
        @click.stop="onClick(material.contents.bodyTitle.link)"
      >
        <PreventLink
          :href="material.contents.bodyTitle.linkWithJobsource"
          v-text="material.contents.bodyTitle.element"
        />
      </h5>
      <ul class="brand_card__list">
        <li
          v-for="(listItem, index) in material.contents.bodyList"
          :key="index"
          class="cursor-pointer"
          @click.stop="onClick(listItem.link)"
        >
          <PreventLink
            :href="listItem.linkWithJobsource"
            v-text="listItem.element"
          />
        </li>
      </ul>
      <div
        class="brand_card__button cursor-pointer"
        @click.stop="onClick(material.contents.moreButton.link)"
      >
        <PreventLink
          :href="material.contents.moreButton.linkWithJobsource"
          v-text="material.contents.moreButton.element"
        />
      </div>
      <p class="brand_card__text">
        {{ material.contents.footerLeft.element }}‧{{
          material.contents.footerRight.element
        }}
      </p>
    </div>
  </section>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "MainSlider",
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onClick(linkHref) {
      this.$emit("ads:click", linkHref);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.brand_card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 522px;
  height: 402px;
  border: 1px solid #d6d6d6;
  border-radius: 0;

  &__img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 244px;
    height: 225px;
    margin: 158px 260px 20px 20px;
    padding: 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px;

    a {
      color: #292929;
    }
  }

  &__list {
    margin-bottom: 12px;

    li {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;

      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
        margin-bottom: 1.5px;
        border: 2px solid #a9a9a9;
      }

      &:hover a {
        color: #4e91ff;
      }

      a {
        color: #1654b9;

        &:visited {
          color: #168ccc;
        }
      }
    }
  }

  &__button {
    display: inline-block;
    height: 32px;
    width: 120px;
    padding: 0 12px;
    border: 1px solid #ff7800;
    border-radius: 0.25rem;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #ff7800;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      color: #ff9100;
      box-shadow: 0 0 4px 0 #ff9100;
      background-color: transparent;
    }
  }

  &__text {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
