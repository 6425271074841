import req from "@/api/https";

export const apiGetPageData = (siteID, channelID, conditions) => {
  return req("get", `/site/${siteID}/channel/${channelID}`, conditions);
};

export const apiGetChannelData = (channelIdentify, conditions) => {
  return req(
    "get",
    `/channel/${channelIdentify}`,
    conditions ? { ...conditions, condition: "1" } : undefined
  );
};

export const postViewExpose = payload => {
  return req("get", `/view`, {
    shelfIds: payload.shelfIds,
    _trace_view: payload.view,
    token: payload.token
  });
};

export const postClickExpose = payload => {
  return req("get", `/click/${payload.shelfId}`, {
    _trace_view: payload.view,
    token: payload.token
  });
};
