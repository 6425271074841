import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  C_MAIN_SHAPE_LAYOUT_NAME,
  COMPANY_LIST_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  cMainShapeLayoutSetting,
  companyListLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = {
  ...C_MAIN_SHAPE_LAYOUT_NAME,
  ...COMPANY_LIST_LAYOUT_NAME,
  SEARCH_BAR_PC: "SearchBarPc",
  SEARCH_BAR_MOBILE: "SearchBarMobile"
};

export default {
  ["pc_c_index_sponsor_company"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.RWD__C_MAIN,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: cMainShapeLayoutSetting
      }
    },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["pc_c_companylist_sponsor_company"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.RWD__COMPANY_LIST,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          [DEVICE_SHAPE_ENUM.PC]: LAYOUT_NAME.SEARCH_BAR_PC,
          [DEVICE_SHAPE_ENUM.MOBILE]: LAYOUT_NAME.SEARCH_BAR_MOBILE
        }
      }
    },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["pc_c_companylist_powerbroadcast"]: new BoardConfig({
    // 強力放送
    page: PAGE_ENUM.RWD__COMPANY_LIST,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: companyListLayoutSetting
      }
    },
    preview: {
      isWidthFill: true,
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 220
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 360
          }
        }
      }
    }
  })
};
