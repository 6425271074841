<template>
  <div class="c-main--shape-mobile">
    <div class="c-main--shape-mobile__wrapper">
      <div ref="swiper" class="swiper">
        <div class="swiper-wrapper">
          <a
            v-for="(material, index) of materials"
            :key="`slide-${index}`"
            :data-material-index="index"
            class="swiper-slide"
            :href="material.contents.sideImg.linkWithJobsource"
            target="_blank"
            @click.prevent
            @contextmenu.prevent
          >
            <CMainSlideShapeMobile :material="material" />
          </a>
        </div>
      </div>
      <div ref="swiper-pagination" class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import CMainSlideShapeMobile from "../components/CMainSlideShapeMobile.vue";

export default {
  name: "CMainShapeMobile",
  components: {
    CMainSlideShapeMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {},
  mounted() {
    this.createSwiper();
    this.onAdsEntry();
  },
  methods: {
    createSwiper() {
      const vm = this;
      this.swiper = new Swiper(this.$refs[`swiper`], {
        modules: [Autoplay, Pagination],
        slidesPerView: 1.1,
        spaceBetween: 16,
        observer: true,
        preventClicks: true,
        loop: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        pagination: {
          el: this.$refs[`swiper-pagination`],
          clickable: true
        },
        on: {
          click(swiper) {
            swiper.allowClick = false;
            vm.onAdsClick(swiper.realIndex);
          },
          realIndexChange(swiper) {
            if (!swiper.height) return;
            vm.onAdsView(swiper.realIndex);
          },
          resize(swiper) {
            if (swiper.height && !swiper.autoplay.running) {
              swiper.autoplay.start();
            }
          }
        }
      });
      if (!vm.swiper.height) vm.swiper.autoplay.stop();
    },
    onAdsEntry() {
      this.$emit("ads:entry", 0);
    },
    onAdsView(materialIndex) {
      this.$emit("ads:view", materialIndex);
    },
    onAdsClick(materialIndex) {
      const material = this.materials[materialIndex];
      this.$emit("ads:click", material.contents.sideImg.link, materialIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";
@import "node_modules/swiper/swiper.scss";

.c-main--shape-mobile__wrapper {
  padding-left: 16px;
  @include useCMainLayout(103, mobile, wrapper);
}
</style>
