<template>
  <div class="advert-type27" :class="eventName">
    <PreventLink
      tag="div"
      class="advert-type27__left"
      :href="material.contents.companyLogo.linkWithJobsource"
      :title="material.contents.companyName.element"
      @click.native="$emit('ads:click')"
    >
      <img
        class="advert-type27__left__logo"
        :src="material.contents.companyLogo.element"
      />
    </PreventLink>
    <div class="advert-type27__middle">
      <div class="advert-type27__middle__header">
        <PreventLink
          tag="div"
          class="advert-type27__middle__header__title"
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="material.contents.companyName.element"
          @click.native="$emit('ads:click')"
        >
          {{ material.contents.companyName.element }}
        </PreventLink>
        <PreventLink
          tag="div"
          class="advert-type27__middle__header__tag"
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="`看更多「${material.contents.industryTag.element}」公司與工作`"
          @click.native="$emit('ads:click')"
        >
          {{ material.contents.industryTag.element }}
        </PreventLink>
      </div>
      <div class="advert-type27__middle__body">
        <div class="advert-type27__middle__body__info">
          <span>{{ material.contents.jobLocation.element }}</span
          ><span>{{ material.contents.industryDes.element }}</span>
        </div>
        <div class="advert-type27__middle__body__description">
          {{ material.contents.textContent.element }}
        </div>
      </div>

      <div class="advert-type27__middle__footer">
        <span
          v-for="(welfareTag, index) of material.contents.welfareTags"
          :key="'tag' + index"
          class="advert-type27__middle__footer__tag"
          >{{ welfareTag.element }}</span
        >
      </div>
    </div>

    <div class="advert-type27__right">
      <PreventLink
        tag="div"
        :href="material.contents.companyLogo.linkWithJobsource"
        :title="material.contents.companyName.element"
        class="advert-type27__right__more"
        @click.native="$emit('ads:click')"
      >
        了解更多
      </PreventLink>
    </div>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      required: true
    },
    adsLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    eventName() {
      if (this.adsLayout.includes("semi")) {
        return "event-semi";
      } else if (this.adsLayout.includes("fresh")) {
        return "event-fresh";
      } else if (this.adsLayout.includes("living")) {
        return "event-living";
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

%tag {
  display: inline-block;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 24px;
}

.advert-type27 {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #fffaf2;
  border-bottom-color: rgb(238, 238, 238);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  &.event-fresh::before {
    content: "新鮮人展";
  }
  &.event-semi::before {
    content: "半導體展";
  }
  &.event-living::before {
    content: "民生特輯";
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    width: 90px;
    background-color: #39c8d0;
    color: white;
    transform: translate(-18.5px, 6.5px) rotate(-45deg) scale(0.9);
    transform-origin: center bottom;
  }

  &__left {
    margin-left: 44px;
    &__logo {
      width: 60px;
      height: 60px;
    }
  }

  &__middle {
    flex-grow: 1;
    margin-right: 48px;
    margin-left: 20px;
    font-size: 14px;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &__title {
        color: #1654b9;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;

        &:hover {
          color: #4e91ff;
          text-decoration: none;
        }
      }

      &__tag {
        @extend %tag;
        margin: 0 4px;
        border: 1px solid #78269f;
        color: #78269f;
      }
    }

    &__body {
      &__info {
        margin-bottom: 4px;
        font-weight: bold;
        line-height: 20px;

        > span:not(:first-child):before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 12px;
          margin: -1px 8px;
          background-color: #ddd;
        }
      }

      &__description {
        display: -webkit-box;
        max-height: 40px;
        color: #7e7e7e;
        font-weight: 400;
        line-height: 20px;
        word-break: break-word;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__footer {
      &__tag {
        @extend %tag;
        margin-top: 8px;
        margin-right: 8px;
        color: #7e7e7e;
        background-color: hsla(0, 0%, 66.3%, 0.16);
      }
    }
  }

  &__right {
    align-self: center;
    display: flex;
    align-items: center;

    &__more {
      display: inline-block;
      width: 132px;
      min-width: 90px;
      padding: 0 12px;
      border: 1px solid #7e7e7e;
      border-radius: 4px;
      text-align: center;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
      color: #7e7e7e;
      background-color: #fff;
      user-select: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        border-color: #ff9100;
        color: #ff9100;
        background-color: #fff;
        box-shadow: 0 0 4px 0 #ff9100;
      }

      &:active {
        border-color: #ff7800;
        color: #ff7800;
        background-color: transparent;
      }
    }
  }
}
</style>
