<script>
import IAdsmartUIWrapper from "./IAdsmartUIWrapper.js";
import AdsTypeModules from "../components/adsTypes/AdsTypeModules";

/*
  雷點： IAdsmartUIWrapper，不能使用 extends 或是 mixins 的方式來繼承，
        會讓 web component 抓不到 props 的設定，導致無法正常運作
 */

export default {
  name: "AdsmartUIWrapper",
  components: AdsTypeModules,
  props: {
    ...IAdsmartUIWrapper.props
  },
  data() {
    return {
      ...IAdsmartUIWrapper.data()
    };
  },
  computed: {
    ...IAdsmartUIWrapper.computed
  },
  mounted() {
    IAdsmartUIWrapper.mounted.call(this);
  },
  destroyed() {
    IAdsmartUIWrapper.destroyed.call(this);
  },
  methods: {
    ...IAdsmartUIWrapper.methods
  },
  render(h) {
    return IAdsmartUIWrapper.render.call(this, h);
  }
};
</script>
