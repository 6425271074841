import { DEVICE_SHAPE_ENUM } from "./enum/shape.js";

export const deviceShapeGroupNoSetting = {
  [DEVICE_SHAPE_ENUM.PC]: 1,
  [DEVICE_SHAPE_ENUM.MOBILE]: 2
};

export const deviceViewIdSetting = {
  1: "large",
  2: "small"
};

export const C_MAIN_SHAPE_LAYOUT_NAME = {
  C_MAIN_SHAPE_PC: "CMainShapePc",
  C_MAIN_SHAPE_MOBILE: "CMainShapeMobile"
};

export const cMainShapeLayoutSetting = {
  [DEVICE_SHAPE_ENUM.PC]: "CMainShapePc",
  [DEVICE_SHAPE_ENUM.MOBILE]: "CMainShapeMobile"
};

export const COMPANY_LIST_LAYOUT_NAME = {
  COMPANY_LIST_PC: "CompanyListPc",
  COMPANY_LIST_MOBILE: "CompanyListMobile"
};

export const companyListLayoutSetting = {
  [DEVICE_SHAPE_ENUM.PC]: "CompanyListPc",
  [DEVICE_SHAPE_ENUM.MOBILE]: "CompanyListMobile"
};

export const INDUSTRY_EVENT_LAYOUT_NAME = {
  INDUSTRY_EVENT_SHAPE_PC: "IndustryEventShapePC",
  INDUSTRY_EVENT_SHAPE_MOBILE: "IndustryEventShapeMobile"
};

export const industryEventShapeLayoutSetting = {
  [DEVICE_SHAPE_ENUM.PC]: "IndustryEventShapePC",
  [DEVICE_SHAPE_ENUM.MOBILE]: "IndustryEventShapeMobile"
};
