import PAGE_ENUM from "../enum/page.js";
import { BoardConfig } from "./BoardConfig.js";

const boardsPC = {
  ["cc57eaf0ce6fb9a6378605bbd3f22e56"]: new BoardConfig({
    // 黃金大版位
    page: PAGE_ENUM.PC__C_MAIN,
    meta: { imgWidth: 260, imgHeight: 210 },
    preview: { containerWidth: 260 }
  }),
  ["32a6bf4d39be8e5da1ad3bf98db1e361"]: new BoardConfig({
    // 特選企業1
    page: PAGE_ENUM.PC__C_MAIN,
    meta: { imgWidth: 500, imgHeight: 260 },
    preview: { containerWidth: 500 }
  }),
  ["915c0dc6a305cb8739add1369699c4eb"]: new BoardConfig({
    // 特選企業2
    page: PAGE_ENUM.PC__C_MAIN,
    meta: { imgWidth: 500, imgHeight: 260 },
    preview: { containerWidth: 500 }
  }),
  ["9c22eb271db67e73f00e5f2132fc6755"]: new BoardConfig({
    // 超級橫幅
    page: PAGE_ENUM.PC__C_MAIN,
    meta: { imgWidth: 1024, imgHeight: 120 },
    preview: { containerWidth: 1024 }
  }),
  ["ea9a075c8e5bd8bf87a712bc0ad097da"]: new BoardConfig({
    // 上方橫幅
    page: PAGE_ENUM.PC__JOB_LIST,
    meta: { imgWidth: 728, imgHeight: 60 },
    preview: { containerWidth: 728 }
  }),
  ["d9d5d107a11b43951deafbc8dc7c28a2"]: new BoardConfig({
    // 摩天樓
    page: PAGE_ENUM.PC__JOB_LIST,
    meta: { imgWidth: 200, imgHeight: 180 },
    preview: { containerWidth: 200 }
  }),
  ["49de080078e70f4da451debea17d15a9"]: new BoardConfig({
    // 上方橫幅
    page: PAGE_ENUM.PC__CATEGORY,
    meta: { imgWidth: 728, imgHeight: 60 },
    preview: { containerWidth: 716 }
  }),
  ["b4acc7c93e92a0c735df7b3bf01660ac"]: new BoardConfig({
    // 橫幅Banner
    page: PAGE_ENUM.PC__APPLY_DONE,
    meta: { imgWidth: 728, imgHeight: 60 },
    preview: { containerWidth: 728 }
  }),
  ["627de0117e08751a26f68fd5f115efed"]: new BoardConfig({
    // 主應看版
    page: PAGE_ENUM.PC__APPLY_DONE,
    meta: { imgWidth: 260, imgHeight: 210 },
    preview: { containerWidth: 260 }
  }),
  ["e22bf483fad9180e954e96fd8f721ae4"]: new BoardConfig({
    // 全站上方橫幅
    page: PAGE_ENUM.PC__FRESHMEN,
    meta: { imgWidth: 400, imgHeight: 60 },
    preview: { containerWidth: 400 }
  }),
  ["28636d1ffde13d420c8583cf457b3b7b"]: new BoardConfig({
    // 黃金大版位
    page: PAGE_ENUM.PC__STUDENT,
    meta: { imgWidth: 260, imgHeight: 210 },
    preview: { containerWidth: 260 }
  }),
  ["4c4b9f85724e50be8141405801f7fa42"]: new BoardConfig({
    // 全站輪播
    page: PAGE_ENUM.PC__TRANSFER,
    meta: { imgWidth: 400, imgHeight: 60 },
    preview: { containerWidth: 400 }
  }),
  ["1e0fb2004ad43aa3a9e2d5c6bdbd2572"]: new BoardConfig({
    // 上方橫幅
    page: PAGE_ENUM.PC__COMPANY_LIST,
    meta: { imgWidth: 728, imgHeight: 60 },
    preview: { containerWidth: 728 }
  }),
  ["5d751bed309018411f2e8d3a3d4f7cf9"]: new BoardConfig({
    // 摩天樓
    page: PAGE_ENUM.PC__COMPANY_LIST,
    meta: { imgWidth: 240, imgHeight: 180 },
    preview: { containerWidth: 220 }
  })
};

const boardsMOBILE = {
  ["6ac174088252b04626fb363687602d9c"]: new BoardConfig({
    // 黃金看版(APP聯播)
    page: PAGE_ENUM.MOBILE__C_MAIN,
    meta: { imgWidth: 720, imgHeight: 132 },
    preview: { containerWidth: 340 }
  }),
  ["ec431895d0806a91585a41e4b62f89ca"]: new BoardConfig({
    // 上方橫幅
    page: PAGE_ENUM.MOBILE__COMPANY_LIST,
    meta: { imgWidth: 382, imgHeight: 56 },
    preview: { containerWidth: 360 }
  }),
  ["f99aa5a83b77a306d68746ca68cee44b"]: new BoardConfig({
    // 摩天樓
    page: PAGE_ENUM.MOBILE__COMPANY_LIST,
    meta: { imgWidth: 382, imgHeight: 178 },
    preview: { containerWidth: 360 }
  })
};

const boardsAPP = {
  ["4382827167c455d836ed818dd659361e"]: new BoardConfig({
    // 專屬廣告
    page: PAGE_ENUM.APP__JOB_MATCH_LIST,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_match_exclusivebanner"]: new BoardConfig({
    // 專屬廣告
    page: PAGE_ENUM.APP__JOB_MATCH_LIST,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["6e2fd8b84c10d5b8f7c7d7a58fb50c7f"]: new BoardConfig({
    // 儲存banner
    page: PAGE_ENUM.APP__MY_SAVE,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_mystore_banner"]: new BoardConfig({
    // 儲存banner
    page: PAGE_ENUM.APP__MY_SAVE,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["0d4e1f736fdbd3307ddf7a27bc810057"]: new BoardConfig({
    // 應徵banner
    page: PAGE_ENUM.APP__APPLY_RECORD,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_apply_banner"]: new BoardConfig({
    // 應徵banner
    page: PAGE_ENUM.APP__APPLY_RECORD,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["a9d09d30db3b22ab5d7b9a1e4c5954f2"]: new BoardConfig({
    // 全版廣告
    page: PAGE_ENUM.APP__START,
    meta: { imgWidth: 720, imgHeight: 1280 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_activypage_fullpage"]: new BoardConfig({
    // 全版廣告
    page: PAGE_ENUM.APP__START,
    meta: { imgWidth: 720, imgHeight: 1280 },
    preview: { containerWidth: 360 }
  }),
  ["ad292aaffa0ba4d875da458bb1e1909c"]: new BoardConfig({
    // 專屬廣告
    page: PAGE_ENUM.APP__JOB_MATCH,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_matchindex_exclusive"]: new BoardConfig({
    // 專屬廣告
    page: PAGE_ENUM.APP__JOB_MATCH,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["bf8da314b73e366a9239b516cc0a3fa8"]: new BoardConfig({
    // 簡歷行銷版位
    page: PAGE_ENUM.APP__RESUME,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_simpleresume_marketingbanner"]: new BoardConfig({
    // 簡歷行銷版位
    page: PAGE_ENUM.APP__RESUME,
    meta: { imgWidth: 720, imgHeight: 160 },
    preview: { containerWidth: 360 }
  }),
  ["9ff8eba378b2bc9c9ef842792811a35e"]: new BoardConfig({
    // 活動 banner
    page: PAGE_ENUM.APP__COMPANY_LIST,
    meta: { imgWidth: 648, imgHeight: 320 },
    preview: { containerWidth: 360 }
  }),
  ["app_c_companyindex_eventbanner"]: new BoardConfig({
    // 活動 banner
    page: PAGE_ENUM.APP__COMPANY_LIST,
    meta: { imgWidth: 648, imgHeight: 320 },
    preview: { containerWidth: 360 }
  })
};

export default {
  ...boardsPC,
  ...boardsMOBILE,
  ...boardsAPP
};
