<template>
  <span class="app-popular-text">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AppPopularText"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-popular-text {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  a {
    color: #1654b9;
  }

  &::before {
    content: url("~@/assets/adType2/app_icon_fire.svg");
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
</style>
