<template lang="html">
  <component :is="pageLayout" :material="materials[0]"></component>
</template>

<script>
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import AppCMain from "./layout/AppCMain.vue";
import AppApplyDone from "./layout/AppApplyDone.vue";
import AppWhoNotify from "./layout/AppWhoNotify.vue";

export default {
  name: "AdsType4",
  components: {
    [PAGE_ENUM.APP__C_MAIN]: AppCMain,
    [PAGE_ENUM.APP__APPLY_DONE]: AppApplyDone,
    [PAGE_ENUM.APP__WHO_NOTIFY]: AppWhoNotify
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    pageLayout: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss"></style>
