<template>
  <div class="ads-type-9">
    <ul class="ads-type-9__wrapper">
      <CompanyCard
        v-for="(material, index) in materials"
        :key="`${material.shelfId}`"
        class="ads-type-9__item"
        :material="material"
        @click.native="onAdsClick(material.contents.headerImage.link, index)"
      />
    </ul>
  </div>
</template>

<script>
import CompanyCard from "../components/CompanyCard.vue";

export default {
  name: "PcCMain",
  components: {
    CompanyCard
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.$emit(
      "ads:entry",
      this.materials.map((el, index) => index)
    );
  },
  methods: {
    onAdsClick(linkHref, materialIndex) {
      this.$emit("ads:click", linkHref, materialIndex);
    }
  }
};
</script>

<style lang="scss">
.ads-type-9 {
  width: 1016px; // 4+1016+4 = 1024
  padding: 4px 3px;
  border: 4px solid #eeeeee;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #eeeeee;
  }
  &__item {
    cursor: pointer;
    &:nth-child(1),
    &:nth-child(8) {
      margin-left: 1px;
    }
  }
}
</style>
