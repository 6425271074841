<template>
  <span class="c-main--shape-pc">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "CMainShapePc"
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";

.c-main--shape-pc {
  @include useCMainLayout(100, pc);
}
</style>
