<template>
  <span class="pc-search-bar">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PcSearchBar"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.pc-search-bar {
  display: block;
  width: 100%;
  padding: 0 20px 0 12px;
  color: #ff7800;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
