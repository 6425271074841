<template>
  <div class="ads-type-16">
    <div class="ads-type-16__wrapper">
      <div
        v-if="showDefaultImage"
        class="ads-type-16__img-block cursor-pointer"
        @click.prevent="onAdsClick(material.contents.defaultImage.link)"
      >
        <PreventLink :href="material.contents.defaultImage.linkWithJobsource">
          <img :src="material.contents.defaultImage.element" />
        </PreventLink>
      </div>

      <div
        v-else
        class="ads-type-16__top-banner cursor-pointer"
        :style="{
          width: `${expand ? bigWidth : smallWidth}px`,
          height: `${expand ? bigHeight : smallHeight}px`
        }"
        @click.prevent="onAdsClick(material.contents.defaultImage.link)"
      >
        <PreventLink
          class="ads-type-16__top-banner__link"
          :href="material.contents.defaultImage.linkWithJobsource"
        >
          <transition-group
            name="iframe"
            tag="div"
            :style="{
              height: `${expand ? bigHeight : smallHeight}px`
            }"
          >
            <iframe
              v-show="expand"
              key="big"
              class="big"
              :width="bigWidth"
              :height="bigHeight"
              :src="material.contents.iframeSrcLg.element"
              frameborder="0"
            ></iframe>
            <iframe
              v-show="!expand"
              key="small"
              class="small"
              :width="smallWidth"
              :height="smallHeight"
              :src="material.contents.iframeSrcSm.element"
              frameborder="0"
            ></iframe>
          </transition-group>
        </PreventLink>
        <button
          class="ads-type-16__top-banner__button cursor-pointer"
          @click.stop="timerHandler"
        >
          {{ expand ? "關閉廣告" : "開啟廣告" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";
let timer = null;
export default {
  name: "AdsType16",
  components: {
    PreventLink
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      topBannerCount: 0,
      smallWidth: 1024 + 17,
      smallHeight: 60,
      bigWidth: 1024 + 17,
      bigHeight: 200,
      showTime: 10000,
      speed: 800,
      expand: false
    };
  },
  computed: {
    material() {
      return this.materials[0];
    },
    showDefaultImage() {
      return (
        this.options.lessThenIe11 ||
        this.getExtension(this.material.contents.iframeSrcLg.element) !==
          this.getExtension(this.material.contents.iframeSrcSm.element)
      );
    }
  },
  created() {
    if (localStorage.getItem(`adType16-${this.material.shelfId}`)) {
      this.topBannerCount = parseInt(
        localStorage.getItem(`adType16-${this.material.shelfId}`)
      );
    }
    this.expand = this.topBannerCount < 2;
  },
  mounted() {
    if (this.expand) this.collapseHander();
    localStorage.setItem(
      `adType16-${this.material.shelfId}`,
      this.topBannerCount + 1
    );
    this.$emit("ads:entry", 0);
  },
  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },
    collapseHander() {
      timer = setTimeout(() => {
        this.expand = false;
      }, this.speed + this.showTime);
    },
    timerHandler() {
      if (timer) clearTimeout(timer);
      this.toggleExpand();
      if (this.expand) this.collapseHander();
    },
    getExtension(src) {
      if (!src) return "";
      return src.split(".").pop().split(/#|\?/)[0];
    },
    onAdsClick(linkHref) {
      this.$emit("ads:click", linkHref, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";
.ads-type-16 {
  &__wrapper {
    // for hidden scrollbar use
    overflow: hidden;
    -ms-overflow-style: none; // for IE
    &::-webkit-scrollbar {
      // for Chrome
      display: none;
    }
  }

  &__img-block {
    width: 100%;
    text-align: center;
    > img {
      cursor: pointer;
    }
  }

  &__top-banner {
    position: relative;
    height: 0;
    margin-right: -17px; // for hidden scrollbar use
    overflow: hidden;
    overflow-x: hidden !important; // for hidden scrollbar use
    overflow-y: scroll !important; // for hidden scrollbar use
    -ms-overflow-style: none; // for IE
    scrollbar-width: none; // for Firefox
    transition: height 0.8s;

    &::-webkit-scrollbar {
      // for Chrome
      display: none;
    }

    &__link {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &__button {
      display: block;
      position: absolute;
      width: 68px; // for hidden scrollbar use
      left: 956px; // for hidden scrollbar use
      bottom: 0;
      border: 0;
      background-color: #979797;
      color: #fff;
      z-index: 20;
      font-size: 13px;
      padding-bottom: 1px;

      &:focus {
        outline: none;
      }
    }
  }
}
.iframe-leave-active.big {
  transition-property: opacity;
  transition-delay: 0.8s;
  transition-duration: 0s;
}
.iframe-leave-to.big {
  opacity: 1;
}
</style>
