<template>
  <div class="advert-type27">
    <PreventLink
      tag="div"
      class="advert-type27__left"
      :href="material.contents.companyLogo.linkWithJobsource"
      :title="material.contents.companyName.element"
      @click.native="$emit('ads:click')"
    >
      <img
        class="advert-type27__left__logo"
        :src="material.contents.companyLogo.element"
      />
    </PreventLink>
    <div class="advert-type27__middle">
      <div class="advert-type27__middle__header">
        <PreventLink
          tag="div"
          class="advert-type27__middle__header__title"
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="material.contents.companyName.element"
          @click.native="$emit('ads:click')"
        >
          {{ material.contents.companyName.element }}
        </PreventLink>
      </div>
      <div class="advert-type27__middle__body">
        <div class="advert-type27__middle__body__info">
          <span>{{ material.contents.jobLocation.element }}</span
          ><span>{{ material.contents.industryDes.element }}</span>
        </div>
        <div class="advert-type27__middle__body__description">
          {{ material.contents.textContent.element }}
        </div>
      </div>
      <div class="advert-type27__middle__footer">
        <span
          v-for="welfareTag of material.contents.welfareTags"
          :key="welfareTag.element"
          class="advert-type27__middle__footer__tag"
          >{{ welfareTag.element }}</span
        >
      </div>
    </div>
    <div class="flag">{{ material.contents.industryTag.element }}</div>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      required: true
    },
    adsLayout: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

%tag {
  display: inline-block;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
}

.advert-type27 {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: #fffaf2;
  border-bottom-color: rgb(238, 238, 238);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    width: 90px;
    background-color: #39c8d0;
    color: white;
    transform: translate(-18.5px, 6.5px) rotate(-45deg) scale(0.9);
    transform-origin: center bottom;
  }

  &__left {
    margin-right: 12px;
    &__logo {
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  &__middle {
    flex-grow: 1;
    font-size: 14px;

    &__header {
      margin-top: 16px;
      display: flex;
      align-items: center;

      &__title {
        color: #1654b9;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;

        &:hover {
          color: #4e91ff;
          text-decoration: none;
        }
      }
    }

    &__body {
      &__info {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
        color: #292929;

        > span:not(:first-child):before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 14px;
          margin: -2px 8px;
          background-color: #ddd;
        }
      }

      &__description {
        display: -webkit-box;
        max-height: 40px;
        margin-bottom: 8px;
        color: #7e7e7e;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        word-break: break-word;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;

      &__tag {
        @extend %tag;
        margin-right: 8px;
        margin-bottom: 8px;
        color: #7e7e7e;
        background-color: #f3f3f3;
      }
    }
  }
}

.flag {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  width: 90px;
  background-color: #39c8d0;
  color: white;
  transform: translate(-18.5px, 6.5px) rotate(-45deg) scale(0.9);
  transform-origin: center bottom;
}
</style>
