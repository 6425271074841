<template lang="html">
  <div class="app-job-page">
    <span class="app-job-page__sponsor">贊助</span>
    <span class="app-job-page__content">{{
      material.contents.bannerText.element
    }}</span>
  </div>
</template>

<script>
export default {
  name: "AppJobPage",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";

.app-job-page {
  display: flex;
  font-family: "Noto Sans TC", Arial, sans-serif;
  font-weight: normal;
  color: #1654b9;

  &__sponsor {
    font-size: 10px;
    line-height: 18px;
    border: 1px solid #1654b9;
    padding: 0 4px;
    border-radius: 4px;
    margin-right: 6px;
  }
  &__content {
    line-height: 20px;
    font-size: 14px;
  }
}
</style>
