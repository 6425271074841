<template>
  <div class="advert-type28" :class="eventName">
    <PreventLink
      tag="div"
      class="advert-type28__header cursor-pointer"
      :href="material.contents.companyLogo.linkWithJobsource"
      :title="material.contents.companyName.element"
      @click.native="$emit('ads:click')"
    >
      <img
        class="advert-type28__header__logo"
        :src="material.contents.companyLogo.element"
      />
      <div class="advert-type28__header__text">
        {{ material.contents.companyName.element }}
      </div>
    </PreventLink>

    <div class="advert-type28__body">
      <div class="advert-type28__body__info">
        <div class="advert-type28__body__info__location">
          <div class="icon">
            <img src="@/assets/adType28/icon_location.svg" />
          </div>
          <div class="text">
            {{ material.contents.jobLocation.element }}
          </div>
        </div>

        <div class="advert-type28__body__info__industry">
          <div class="icon">
            <img src="@/assets/adType28/icon_company_saved.svg" />
          </div>
          <div class="text">
            {{ material.contents.industryDes.element }}
          </div>
        </div>
      </div>

      <div class="advert-type28__body__description">
        <span>{{ material.contents.textContent.element }}</span>
      </div>
    </div>

    <div class="advert-type28__footer">
      <PreventLink
        tag="div"
        class="advert-type28__footer__button cursor-pointer"
        :href="material.contents.companyLogo.linkWithJobsource"
        :title="material.contents.companyName.element"
        @click.native="$emit('ads:click')"
      >
        了解更多
      </PreventLink>
    </div>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      required: true
    },
    adsLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    eventName() {
      if (this.adsLayout.includes("semi")) {
        return "event-semi";
      } else if (this.adsLayout.includes("fresh")) {
        return "event-fresh";
      } else if (this.adsLayout.includes("living")) {
        return "event-living";
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.advert-type28 {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #fffaf2;
  background-repeat: no-repeat;
  background-position: -1px 0;
  border: 1px solid #dddddd;
  border-radius: 4px;

  &.event-fresh::before {
    content: "新鮮人展";
  }
  &.event-semi::before {
    content: "半導體展";
  }
  &.event-living::before {
    content: "民生特輯";
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    width: 90px;
    background-color: #39c8d0;
    color: white;
    transform: translate(-18.5px, 6.5px) rotate(-45deg) scale(0.9);
    transform-origin: center bottom;
  }

  &:hover {
    box-shadow: 0 6px 16px 0 rgba(#292929, 0.2);
  }

  &__header {
    padding: 0 16px;
    text-align: center;

    &__logo {
      width: 72px;
      height: 72px;
      margin: 24px 0;
    }

    &__text {
      color: #1654b9;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__body {
    margin-bottom: 12px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;

    &__info {
      color: #292929;
      font-weight: 700;

      &__location {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      &__industry {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .icon {
        flex-shrink: 0;
        width: 16px;
        height: 20px;
        padding: 2px 0;
        margin-right: 8px;
        line-height: 16px;

        > img {
          vertical-align: unset;
        }
      }

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__description {
      color: #7e7e7e;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 57px;
    border-top-color: rgb(238, 238, 238);
    border-top-style: solid;
    border-top-width: 1px;

    &__button {
      color: #1654b9;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;

      &:hover {
        color: #4e91ff;
        text-decoration: none;
      }
    }
  }
}
</style>
