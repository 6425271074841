<template>
  <div class="job-list">
    <div class="job-list__center">
      <div class="job-list__center__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="job-list__center__sub-title">
        {{ material.contents.centerSubTitle.element }}
      </div>
      <div class="job-list__center__content">
        {{ material.contents.centerContent[0].element }}
        <br />
        {{ material.contents.centerContent[1].element }}
      </div>
    </div>
    <div class="job-list__right">
      <div class="job-list__right__sponsor">贊助</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobList",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";

.job-list {
  display: flex;
  padding: 16px;

  &__right {
    &__sponsor {
      padding: 0 3px;
      margin-left: 8px;
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: #ea475b;
      border-width: 1px;
      border-style: solid;
      border-color: #ea475b;
      border-radius: 4px;
    }
  }

  &__center {
    flex-grow: 1;
    line-height: 1.62;
    overflow: hidden;

    &__title {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.75;
      color: #1654b9;
    }
    &__sub-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.43;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__content {
      font-size: 14px;
      line-height: 1.43;
      color: #7e7e7e;
    }
  }
}
</style>
