import AdsmartHref from "./AdsmartHref";
import AdsmartGeneralNode from "./AdsmartGeneralNode.js";

export default class AdsmartComposeNode extends AdsmartGeneralNode {
  /**
   * 需要暴露給
   * src/components/preview/createPreviewComponent.js
   * 的 renderComposeType 使用
   */
  static formatToMaterialsGroup(materials) {
    const result = {};
    materials.forEach(adsMaterial => {
      const groups = {};
      Object.entries(adsMaterial.contents).forEach(([rawKey, adsContent]) => {
        // 1:text => groupNo: 1, key :text
        const groupNo = rawKey.match(/(\d+):/)[1];
        const key = rawKey.replace(/\d+:/, "");
        if (!groups[groupNo]) groups[groupNo] = {};
        groups[groupNo][key] = adsContent;
      });
      Object.entries(groups).forEach(([groupNo, contents]) => {
        if (!result[groupNo]) result[groupNo] = [];
        result[groupNo].push({
          shelfId: adsMaterial.shelfId,
          jobsource: adsMaterial.jobsource,
          contents
        });
      });
    });
    return result;
  }

  constructor(vm, adsmartBoard) {
    super(vm, adsmartBoard);
  }

  get #shapeGroupNoSetting() {
    return this.adsmartBoard.boardConfig.meta.compose.shapeGroupNo;
  }

  get #groupNo() {
    const shape = this.vm.props.adsShape;
    return this.#shapeGroupNoSetting[shape] ?? 0;
  }

  #createAdsmartHref(linkHref, jobsource, AdsmartHrefClass = AdsmartHref) {
    return new AdsmartHrefClass(linkHref, jobsource);
  }

  get materialsGroup() {
    return AdsmartComposeNode.formatToMaterialsGroup(this.adsmartMaterials);
  }

  entry(mIndex) {
    const shelfIdArray = this.getShelfIdArray(mIndex);
    this.adsmartBoard.entry(shelfIdArray, this.#groupNo);
  }

  view(mIndex) {
    const shelfIdArray = this.getShelfIdArray(mIndex);
    this.adsmartBoard.view(shelfIdArray, this.#groupNo);
  }

  click(linkHref, materialIndex) {
    const { shelfId, jobsource } = this.adsmartMaterials[materialIndex];
    if (shelfId) {
      this.adsmartBoard.click(shelfId, this.#groupNo);
      this.adsmartBoard.addNCCLog(shelfId, jobsource);
    }
    const adsmartHref = this.#createAdsmartHref(linkHref, jobsource);
    adsmartHref.goNewPage(); // _redirectPage
  }
}
