/* 
  config interface 
  使用這個 class 來建立 config 物件，幫助不同 type 的屬性統一管理
*/

/**
 * @class MetaComposeConfig
 * @classdesc 給組合渲染使用的
 *
 * @property {ShapeGroupNo} shapeGroupNo - 組合編號
 * @typedef {Object} ShapeGroupNo
 * @property {number} [type1] - 1
 * @property {number} [type2] - 2
 *
 * @property {ShapeLayout} shapeLayout - 組合版面
 * @typedef {Object} ShapeLayout
 * @property {string} [type1] - "CMainShapePc"
 * @property {string} [type2] - "CMainShapeMobile"
 */

class MetaComposeConfig {
  // 給組合渲染使用的
  constructor({ shapeGroupNo = {}, shapeLayout = {} }) {
    this.shapeGroupNo = shapeGroupNo;
    this.shapeLayout = shapeLayout;
  }
}

class MetaConfig {
  // 給媒體端渲染使用的
  constructor({
    imgWidth = 0,
    imgHeight = 0,
    layout = "",
    compose: composeOptions = {}
  }) {
    // type1
    this.imgWidth = imgWidth;
    this.imgHeight = imgHeight;
    // type2
    this.layout = layout;
    // type100 - 104
    this.compose = new MetaComposeConfig(composeOptions);
  }
}

class PreviewContainerConfig {
  constructor({ width = 0 }) {
    this.width = width;
  }
}

class PreviewComposeConfig {
  constructor({
    container = {
      // pc : new PreviewContainerConfig({}),
    }
  }) {
    this.container = container;
  }
}

class PreviewConfig {
  // 給預覽渲染使用的
  constructor({
    containerWidth = 0,
    mockupLayout = "",
    isWidthFill = false,
    container: containerConfig = {},
    compose: composeConfig = {}
  }) {
    // type1
    this.containerWidth = containerWidth;
    // type2
    this.mockupLayout = mockupLayout;
    // preview
    this.isWidthFill = isWidthFill;
    this.compose = new PreviewComposeConfig(composeConfig);
    this.container = new PreviewContainerConfig(containerConfig);
  }
}

export class BoardConfig {
  constructor({ page = "", meta = {}, preview = {} }) {
    this.page = page;
    this.meta = new MetaConfig(meta);
    this.preview = new PreviewConfig(preview);
  }
}
