import PAGE_ENUM from "./enum/page.js";

export { PAGE_ENUM };

export default {
  // dev
  ["dev-channel"]: "rwd--dev-channel",

  /* device: PC & MOBILE */

  // - 首頁 2024
  ["pc_c_index"]: PAGE_ENUM.RWD__C_MAIN,
  ["pc_c_industry_company"]: PAGE_ENUM.RWD__INDUSTRY_COMPANY,
  ["pc_c_industry"]: PAGE_ENUM.RWD__INDUSTRY_EVENT,
  ["pc_c_companylist"]: PAGE_ENUM.RWD__COMPANY_LIST,
  ["pc_c_freshman"]: PAGE_ENUM.RWD__FRESHMEN,
  ["pc_c_changejob"]: PAGE_ENUM.RWD__TRANSFER,

  /* device: PC */

  // - site: 人力銀行B主網
  // - channels:
  // VIP登入首頁
  ["5e9dc1fbe42d8122e2a42bdbed74e1ef"]: PAGE_ENUM.PC__B_VIP,

  // - site: 人力銀行C主網
  // - channels:
  // - 首頁
  ["ef9da48b5f614d7428f95147c9012b87"]: PAGE_ENUM.PC__C_MAIN,
  // - 分眾銀行頁
  ["1aff51decda645a8febfe693bb6cc89d"]: PAGE_ENUM.PC__CATEGORY,
  // - 應徵完成頁
  ["b0a4c2908a009721b20ecc49ddfac8f5"]: PAGE_ENUM.PC__APPLY_DONE,
  // - 社會新鮮人專區
  ["0ec17f0237abcb76e321a24ecb6e550e"]: PAGE_ENUM.PC__FRESHMEN,
  // - 學生首頁
  ["3f114e84bc70dc2dbc9966980b3da834"]: PAGE_ENUM.PC__STUDENT,
  // - 轉職專區首頁
  ["6d6bb9eefac5366d4ea427126b0cc098"]: PAGE_ENUM.PC__TRANSFER,
  // - My104會員中心
  ["344f66a913516eb350ca41e01dc152e9"]: PAGE_ENUM.PC__MY_104,
  // - 工作列表頁
  ["b87ee70e4df52ffe5783a91fe8ca33e6"]: PAGE_ENUM.PC__JOB_LIST,
  // - 職缺頁
  ["f07bec8a4f0573c9e31aa6448463472a"]: PAGE_ENUM.PC__JOB_PAGE,
  // - 公司列表頁
  ["da6418a9be53a2ea1a6581fcc34091b7"]: PAGE_ENUM.PC__COMPANY_LIST,
  // - 求職主動線_公司頁tag
  ["849f61c7bc49dee9b947dcb4fd4ce79e"]: PAGE_ENUM.PC__COMPANY_EVENT_TAG,
  // - 求職主動線_公司列表頁
  ["8e10e0ededafed7704847cbd57e64218"]: PAGE_ENUM.PC__COMPANY_EVENT_LIST,

  /* device: MOBILE */

  // - site: 人力銀行C主網
  // - channels:

  // - 首頁
  ["c01c14bbed7b376b4cf6d55efca9ef3b"]: PAGE_ENUM.MOBILE__C_MAIN,
  // - 工作列表頁
  ["99883563b0e15171f872eade53338f3f"]: PAGE_ENUM.MOBILE__JOB_LIST,
  // - 職缺頁
  ["e82d056748ab589632a428d6d674bb5f"]: PAGE_ENUM.MOBILE__JOB_PAGE,
  // - 搜尋工作頁
  ["a96ac8a188450cbacb847487a06b79df"]: PAGE_ENUM.MOBILE__JOB_SEARCH,
  // - 公司列表頁
  ["a729c3ab8ecfcef397eadead9cadfe0e"]: PAGE_ENUM.MOBILE__COMPANY_LIST,
  // - 求職主動線_公司頁tag
  ["ef8dae57fd0e1ef8b1656a5c31e37756"]: PAGE_ENUM.MOBILE__COMPANY_EVENT_TAG,
  // - 求職主動線_公司列表頁
  ["8781721bf60bae7db68c4ca15085d2dd"]: PAGE_ENUM.MOBILE__COMPANY_EVENT_LIST,

  /* device: APP */

  // - site: 工作快找
  // - channels:
  // - 首頁
  ["156ce5abb2070d290131f2c0a731d64b"]: PAGE_ENUM.APP__C_MAIN,
  ["app_c_index"]: PAGE_ENUM.APP__C_MAIN,
  // - 誰看過我頁
  ["9e116731ff5e60377764b17d5193a672"]: PAGE_ENUM.APP__WHO_SEEN,
  ["app_c_whoseeme"]: PAGE_ENUM.APP__WHO_SEEN,
  // - 誰通知我頁
  ["091f753875dd3d336401acdfb82ac7e0"]: PAGE_ENUM.APP__WHO_NOTIFY,
  ["app_c_notice"]: PAGE_ENUM.APP__WHO_NOTIFY,
  // - 職缺頁
  ["05b85453e01647479a3ac8310bfaf357"]: PAGE_ENUM.APP__JOB_PAGE,
  ["app_c_job"]: PAGE_ENUM.APP__JOB_PAGE,
  // - 配對工作首頁
  ["ef9b818121f910f3580b164f46bfb96c"]: PAGE_ENUM.APP__JOB_MATCH,
  ["app_c_matchindex"]: PAGE_ENUM.APP__JOB_MATCH,
  // - 配對工作列表頁
  ["2d6e3482764593493a4f38251e4e3e5c"]: PAGE_ENUM.APP__JOB_MATCH_LIST,
  ["app_c_match"]: PAGE_ENUM.APP__JOB_MATCH_LIST,
  // - 找公司首頁
  ["eac7148f077bf5ae3a2f8e2801ffd963"]: PAGE_ENUM.APP__COMPANY_LIST,
  ["app_c_companyindex"]: PAGE_ENUM.APP__COMPANY_LIST,
  // - 求職主動線_公司頁tag
  ["8fe7627c7e54d2b9f60cb57d236fa661"]: PAGE_ENUM.APP__COMPANY_EVENT_TAG,
  ["app_c_industry_company"]: PAGE_ENUM.APP__COMPANY_EVENT_TAG,
  // - 求職主動線_公司列表頁
  ["19fdde556e766dc47d7f7452b9fc7d0b"]: PAGE_ENUM.APP__COMPANY_EVENT_LIST,
  ["app_c_industry"]: PAGE_ENUM.APP__COMPANY_EVENT_LIST,
  // - 應徵紀錄
  ["3eb01000028b3031d1a9752a5897b4e5"]: PAGE_ENUM.APP__APPLY_RECORD,
  ["app_c_apply"]: PAGE_ENUM.APP__APPLY_RECORD,
  // - 應徵完成頁
  ["2228753a31ccb3c466a5f23a82c5b808"]: PAGE_ENUM.APP__APPLY_DONE,
  ["app_c_applycomplete"]: PAGE_ENUM.APP__APPLY_DONE,
  // - 搜尋頁
  ["4b18840bef913c47c3b41e360e32fa63"]: PAGE_ENUM.APP__SEARCH,
  ["app_c_searchpage"]: PAGE_ENUM.APP__SEARCH,
  // - 搜尋框下方
  ["7f05cff6f865874450d31933e08c5490"]: PAGE_ENUM.APP__SEARCH_BAR,
  ["app_c_search"]: PAGE_ENUM.APP__SEARCH_BAR,
  // - 搜尋結果頁
  ["e0bde55281ae714cac7cddeb97efddfe"]: PAGE_ENUM.APP__SEARCH_RESULT,
  ["app_c_searchlist"]: PAGE_ENUM.APP__SEARCH_RESULT,
  // - 公司搜尋頁
  ["fcb80182a32ec3e060b61d5438d8ba85"]: PAGE_ENUM.APP__SEARCH_COMPANY,
  ["app_c_companylist"]: PAGE_ENUM.APP__SEARCH_COMPANY,
  // - 我的儲存頁
  ["10cac5c368723c350166347f1db3a7e7"]: PAGE_ENUM.APP__MY_SAVE,
  ["app_c_mystore"]: PAGE_ENUM.APP__MY_SAVE,
  // - 啟動頁
  ["e46921e7e6c37938c4cb9027e7cd3f32"]: PAGE_ENUM.APP__START,
  ["app_c_activypage"]: PAGE_ENUM.APP__START,
  // - 推播
  ["8ca8ed0415db5eae8d4a724f99e7dfb9"]: PAGE_ENUM.APP__PUSH,
  ["app_c_push"]: PAGE_ENUM.APP__PUSH,
  // - 簡歷
  ["7fa67a962c0ef8ba94b014ede7057d1a"]: PAGE_ENUM.APP__RESUME,
  ["app_c_simpleresume"]: PAGE_ENUM.APP__RESUME
};
