<template>
  <component
    :is="layoutName"
    class="ads-type-2 cursor-pointer"
    @click.native.prevent="onClick"
  >
    <PreventLink
      :href="materials[currentIndex].contents.bannerText.linkWithJobsource"
      v-text="textContent"
    />
  </component>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type2.js";
import PcCategory from "./layout/PcCategory.vue";
import PcBVip from "./layout/PcBVip.vue";
import PcJobList from "./layout/PcJobList.vue";
import PcJobListSearchBar from "./layout/PcJobListSearchBar.vue";
import PcSearchBar from "./layout/PcSearchBar.vue";
import MobileSearchBar from "./layout/MobileSearchBar.vue";
import AppHashtag from "./layout/AppHashtag.vue";
import AppPopularText from "./layout/AppPopularText.vue";
import AppSearchBar from "./layout/AppSearchBar.vue";
import AppHotKeyword from "./layout/AppHotKeyword.vue";

export default {
  name: "AdsType2",
  components: {
    PreventLink,
    [LAYOUT_NAME.PC__CATEGORY]: PcCategory,
    [LAYOUT_NAME.PC__B_VIP]: PcBVip,
    [LAYOUT_NAME.PC__JOB_LIST]: PcJobList,
    [LAYOUT_NAME.PC__JOB_LIST_SEARCH_BAR]: PcJobListSearchBar,
    [LAYOUT_NAME.PC__SEARCH_BAR]: PcSearchBar,
    [LAYOUT_NAME.MOBILE__SEARCH_BAR]: MobileSearchBar,
    [LAYOUT_NAME.APP__HASHTAG]: AppHashtag,
    [LAYOUT_NAME.APP__POPULAR_TEXT]: AppPopularText,
    [LAYOUT_NAME.APP__SEARCH_BAR]: AppSearchBar,
    [LAYOUT_NAME.APP__HOT_KEYWORD]: AppHotKeyword
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    adsBoardIdentify: {
      type: String,
      default: ""
    },
    layoutName: {
      type: String,
      default: "span"
    }
  },
  data() {
    return {
      timer: null,
      currentIndex: 0
    };
  },
  computed: {
    textContent() {
      return this.materials[this.currentIndex].contents.bannerText.element;
    },
    linkHref() {
      return this.materials[this.currentIndex].contents.bannerText.link;
    }
  },
  mounted() {
    if (this.materials.length > 1) {
      this.startTimer();
    }
    this.$emit("ads:entry", 0);
  },
  beforeDestroy() {
    if (this.timer) this.clearTimer();
  },
  methods: {
    onClick() {
      this.$emit("ads:click", this.linkHref, this.currentIndex);
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.materials.length;
        this.$emit("ads:view", this.currentIndex);
      }, 15000);
    },
    clearTimer() {
      clearInterval(this.timer);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
</style>
