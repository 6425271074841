import AdsmartBoardsMap from "./AdsmartBoardsMap.js";
import AdsmartTracker from "./AdsmartTracker";

export default class AdsmartChannel {
  constructor(channelData = { boards: {}, token: "" }) {
    this.boards = channelData.boards;
    this.token = channelData.token;
    this.adsmartTracker = this.getAdsmartTracker();
    this.boardsMap = this.createBoardsMap();
    this.updateBoards(this.boards);
  }

  getAdsmartTracker(AdsmartTrackerClass = AdsmartTracker) {
    return new AdsmartTrackerClass(this.token);
  }

  createBoardsMap(AdsmartBoardsMapClass = AdsmartBoardsMap) {
    return new AdsmartBoardsMapClass();
  }

  _createBoard(boardData) {
    return this.boardsMap.createBoard(boardData, this.adsmartTracker);
  }

  _getWCNodes(boardIdentify) {
    const selector = `adsmart-ui[ads-board-identify="${boardIdentify}"]`;
    return window.document.querySelectorAll(selector);
  }

  getWCNodes(boardIdentify) {
    const selector = `adsmart-ui[ads-board-identify="${boardIdentify}"]`;
    return window.document.querySelectorAll(selector);
  }

  #isComposeType(typeId) {
    return Number(typeId) / 100 >= 1;
  }

  #getComposeWCNodes(boardIdentify) {
    const selector = `adsmart-ui-switch[ads-board-identify="${boardIdentify}"]`;
    return window.document.querySelectorAll(selector);
  }

  updateBoards(boards) {
    if (!boards) return; // 平台無預約廣告
    Object.values(boards).forEach(boardData => {
      const board = this._createBoard(boardData);
      this.boardsMap.set(boardData.boardIdentify, board);
      const isComposeType = this.#isComposeType(boardData.typeId);
      if (isComposeType) {
        const nodes = this.#getComposeWCNodes(boardData.boardIdentify);
        nodes.forEach(node => {
          board.reactiveNode(node._wrapper);
        });
      } else {
        const nodes = this.getWCNodes(boardData.boardIdentify);
        nodes.forEach(node => {
          board.reactiveNode(node._wrapper);
        });
      }
    });
  }

  hasBoard(boardIdentify) {
    return this.boardsMap.has(boardIdentify);
  }

  reactiveBoard(boardIdentify, vm) {
    const board = this.boardsMap.get(boardIdentify);
    board.reactiveNode(vm);
  }

  get isAllBoardNoAd() {
    const boards = this.boardsMap.values();
    for (let i = 0; i < this.boardsMap.size; i++) {
      const board = boards.next().value;
      if (!board.isNoAd) return false;
    }
    return true;
  }

  get isEveryBoardsShow() {
    return this.boardsMap.isEveryBoardsShow;
  }

  get isNoBoards() {
    return this.boards === null;
  }
}
