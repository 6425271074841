<template>
  <div class="job-list">
    <div class="job-list__left">
      <div class="job-list__left__sponsor">贊助</div>
    </div>
    <div class="job-list__center">
      <div class="job-list__center__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="job-list__center__sub-title">
        {{ material.contents.centerSubTitle.element }}
      </div>
      <div class="job-list__center__content">
        {{ material.contents.centerContent[0].element }}
        <br />
        {{ material.contents.centerContent[1].element }}
      </div>
    </div>
    <div class="job-list__right">
      <img :src="material.contents.sideImage.element" />
    </div>
  </div>
</template>

<script>
export default {
  name: "JobList",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.job-list {
  display: flex;
  padding: 0 12px;

  &__left {
    width: 43px;
    margin-top: 20px;
    text-align: center;

    &__sponsor {
      font-size: 14px;
      color: #a9a9a9;
    }
  }

  &__center {
    flex-grow: 1;
    margin-top: 18px;
    margin-bottom: 20px;
    line-height: 1.62;
    overflow: hidden;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      color: #0e66c7;
    }
    &__sub-title {
      margin: 4px 0;
      font-size: 14px;
      color: #0e66c7;
    }
    &__content {
      font-size: 14px;
      color: #7e7e7e;
    }
  }

  &__right {
    align-self: center;
    width: 119px;

    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
