import { render, staticRenderFns } from "./AdsType1.vue?vue&type=template&id=39c48046&scoped=true&"
import script from "./AdsType1.vue?vue&type=script&lang=js&"
export * from "./AdsType1.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./AdsType1.vue?vue&type=style&index=0&id=39c48046&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "39c48046",
  null
  ,true
)

export default component.exports