import { render, staticRenderFns } from "./AppPopularText.vue?vue&type=template&id=42bc2a7d&scoped=true&"
import script from "./AppPopularText.vue?vue&type=script&lang=js&"
export * from "./AppPopularText.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./AppPopularText.vue?vue&type=style&index=0&id=42bc2a7d&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "42bc2a7d",
  null
  ,true
)

export default component.exports