import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  C_MAIN_SHAPE_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  cMainShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = {
  ...C_MAIN_SHAPE_LAYOUT_NAME,
  COMPANY_LIST_TOP_BANNER_PC: "CompanyListTopBannerPc",
  COMPANY_LIST_TOP_BANNER_MOBILE: "CompanyListTopBannerMobile",
  COMPANY_LIST_SKYSCRAPER_PC: "CompanyListSkyscraperPc",
  COMPANY_LIST_SKYSCRAPER_MOBILE: "CompanyListSkyscraperMobile",
  FRESHMEN_TOP_BANNER_PC: "FreshmenTopBannerPc",
  FRESHMEN_TOP_BANNER_MOBILE: "FreshmenTopBannerMobile",
  TRANSFER_TOP_BANNER_PC: "TransferTopBannerPc",
  TRANSFER_TOP_BANNER_MOBILE: "TransferTopBannerMobile"
};

export default {
  ["pc_c_index_main_choice"]: new BoardConfig({
    // 複合元件 主打首選
    page: PAGE_ENUM.RWD__C_MAIN,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: cMainShapeLayoutSetting
      }
    },
    preview: {
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 324
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 393
          }
        }
      }
    }
  }),
  ["pc_c_companylist_topbanner"]: new BoardConfig({
    // 上方橫幅
    page: PAGE_ENUM.RWD__COMPANY_LIST,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          [DEVICE_SHAPE_ENUM.PC]: LAYOUT_NAME.COMPANY_LIST_TOP_BANNER_PC,
          [DEVICE_SHAPE_ENUM.MOBILE]: LAYOUT_NAME.COMPANY_LIST_TOP_BANNER_MOBILE
        }
      }
    },
    preview: {
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 728
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 382
          }
        }
      }
    }
  }),
  ["pc_c_companylist_skyscraper"]: new BoardConfig({
    // 摩天樓
    page: PAGE_ENUM.RWD__COMPANY_LIST,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          [DEVICE_SHAPE_ENUM.PC]: LAYOUT_NAME.COMPANY_LIST_SKYSCRAPER_PC,
          [DEVICE_SHAPE_ENUM.MOBILE]: LAYOUT_NAME.COMPANY_LIST_SKYSCRAPER_MOBILE
        }
      }
    },
    preview: {
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 220
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 382
          }
        }
      }
    }
  }),
  ["pc_c_freshman__topbanner"]: new BoardConfig({
    // 新鮮人專區 - 上方橫幅
    page: PAGE_ENUM.RWD__FRESHMEN,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          pc: LAYOUT_NAME.FRESHMEN_TOP_BANNER_PC,
          mobile: LAYOUT_NAME.FRESHMEN_TOP_BANNER_MOBILE
        }
      }
    },
    preview: {
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 400
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 400
          }
        }
      }
    }
  }),
  ["pc_c_changejob__topbanner"]: new BoardConfig({
    // 轉職專區 - 上方橫幅
    page: PAGE_ENUM.RWD__TRANSFER,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          pc: LAYOUT_NAME.TRANSFER_TOP_BANNER_PC,
          mobile: LAYOUT_NAME.TRANSFER_TOP_BANNER_MOBILE
        }
      }
    },
    preview: {
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 400
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 400
          }
        }
      }
    }
  })
};
