<template>
  <!--
  這個元件存在的目的是要阻擋 <a> 點擊右鍵時會出現的另開新頁功能，同時滿足 seo 必須有完整
  <a> 的需求。
  -->
  <a v-if="tag === 'a'" target="_blank" :href="href">
    <slot></slot>
  </a>
  <component :is="tag" v-else class="prevent-link">
    <a target="_blank" :href="href">
      <slot></slot>
    </a>
  </component>
</template>

<script>
export default {
  name: "PreventLink",
  props: {
    tag: {
      type: String,
      default: "a"
    },
    href: {
      type: [String, URL],
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.prevent-link {
  cursor: pointer;
  > a {
    display: inherit;
  }
}

a {
  color: inherit;
  text-decoration: none;
  pointer-events: none;
  /*
  注意： pointer-events: none 會讓後代的滑鼠事件都無效（click, hover...）
  但如果後代中有設定 pointer-events: auto 將會觸發上層事件，導致 <a> 的另開新頁功能
  又被打開。開發上務必注意後代不要設定 pointer-events: auto。
*/
}
</style>
