import { apiGetChannelData } from "@/api/pages";
import AdsmartChannelsMap from "./AdsmartChannelsMap.js";

export default class AdsmartUI {
  constructor() {
    // step1 資料階段相關
    this.channelsMap = this._getChannelsMap();

    window.adsmartUI = this;
  }

  _getChannelsMap() {
    return new AdsmartChannelsMap();
  }

  async _fetchChannelData(channelIdentify, conditions) {
    const response = await apiGetChannelData(channelIdentify, conditions);
    return response.data;
  }

  // 媒體端串接
  async entry(pageInfo) {
    try {
      const { channelIdentify } = pageInfo;
      const channelData = await this._fetchChannelData(channelIdentify);
      const adsmartChannel = this.channelsMap.registerChannel(
        channelIdentify,
        channelData
      );
      return Promise.resolve(adsmartChannel);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // 媒體端串接
  async update(pageInfo) {
    try {
      const { channelIdentify, conditions } = pageInfo;
      const channelData = await this._fetchChannelData(
        channelIdentify,
        conditions
      );
      const adsmartChannel = this.channelsMap.updateChannel(
        channelIdentify,
        channelData
      );
      return Promise.resolve(adsmartChannel);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  _filterBoardsWithSameCondition(boards) {
    const conditionIdsSet = new Set();
    const stagingConditionIds = {};
    Object.entries(boards).forEach(([bId, bInfo]) => {
      if (bInfo.materials.length > 0) {
        conditionIdsSet.add(bInfo.conditionId);
        if (stagingConditionIds[bInfo.conditionId]) {
          stagingConditionIds[bInfo.conditionId].push(bId);
        } else {
          stagingConditionIds[bInfo.conditionId] = [bId];
        }
      }
    });
    if (stagingConditionIds.length <= 1) {
      return boards;
    } else {
      const getRandomKeyFromObject = obj => {
        const keys = Object.keys(obj);
        const randomIndex = Math.floor(Math.random() * keys.length);
        return keys[randomIndex];
      };
      const debutConditionId = getRandomKeyFromObject(stagingConditionIds);
      const deepCloneObject = obj => JSON.parse(JSON.stringify(obj));
      const cloneBoards = deepCloneObject(boards);
      Object.keys(stagingConditionIds).forEach(conditionId => {
        if (conditionId !== debutConditionId) {
          stagingConditionIds[conditionId].forEach(boardId => {
            cloneBoards[boardId].materials = [];
          });
        }
      });
      return cloneBoards;
    }
  }

  // 媒體端串接--求職主動線
  async conditionalUpdate(pageInfo) {
    try {
      if (!pageInfo.channelIdentify)
        throw new Error("channelIdentify is required");
      if (!pageInfo.conditions) throw new Error("conditions is required");
      const channelData = await this._fetchChannelData(
        pageInfo.channelIdentify,
        pageInfo.conditions
      );

      const boards = channelData.boards
        ? this._filterBoardsWithSameCondition(channelData.boards)
        : null;

      const adsmartChannel = this.channelsMap.updateChannel(
        pageInfo.channelIdentify,
        { token: channelData.token, boards }
      );
      return Promise.resolve(adsmartChannel);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // mock env 串接
  inject(channelIdentify, channelData) {
    this.channelsMap.registerChannel(channelIdentify, channelData);
  }

  register(vm) {
    this.channelsMap.reactiveBoard(vm);
  }

  getBoard(boardIdentify) {
    let foundBoard;
    const list = this.channelsMap.values();
    let head = list.next();
    while (!foundBoard && head.value) {
      foundBoard = head.value.boardsMap.get(boardIdentify);
      head = list.next();
    }
    return foundBoard;
  }

  getChannel(channelIdentify) {
    return this.channelsMap.get(channelIdentify);
  }
}
