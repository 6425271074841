<template lang="html">
  <div class="ads-type-22">
    <div class="ads-type-22__content">
      <div class="ads-type-22__content__image">
        <img :src="material.contents.image.element" />
        <img
          class="ads-type-22__content__image__mask"
          src="~@/assets/adType22/video-mask.svg"
        />
      </div>

      <div class="ads-type-22__content__text">
        <p class="ads-type-22__content__text__title">
          {{ material.contents.title.element }}
        </p>
        <p class="ads-type-22__content__text__content">
          {{ material.contents.content[0].element }}
        </p>
        <p class="ads-type-22__content__text__content">
          {{ material.contents.content[1].element }}
        </p>
      </div>

      <div class="ads-type-22__content__sponsor">
        <span>贊助</span>
      </div>
    </div>

    <div class="ads-type-22__video">
      <div class="ads-type-22__video__button">
        <img src="@/assets/adType22/ic-reply.svg" />
        <span class="text">再看一次</span>
      </div>

      <div class="ads-type-22__video__button">
        <img src="@/assets/adType22/ic-link.svg" />
        <span class="text">
          {{ material.contents.clickForMoreText.element }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsType22",
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    material() {
      return this.materials[0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.ads-type-22 {
  &__content {
    display: flex;
    padding: 12px 0;

    &__image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 72px;
      padding: 0 16px;

      img {
        display: inline-block;
        width: 40px;
      }

      &__mask {
        position: absolute;
      }
    }

    &__text {
      flex-grow: 1;
      overflow: hidden;

      &__title {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        color: #292929;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__content {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        color: #7e7e7e;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__sponsor {
      font-family: Helvetica, Arial, sans-serif;
      display: flex;
      justify-content: center;
      align-items: start;
      min-width: 28px;
      margin-right: 16px;
      font-size: 10px;
      line-height: 18px;
      color: #ea475b;

      > span {
        padding: 0 4px;
        border: 1px solid #ea475b;
        border-radius: 4px;
        white-space: nowrap;
      }
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
    height: 208px;
    background-color: rgba(#000, 0.8);

    &__button {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        margin-bottom: 26px;
      }

      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      .text {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
</style>
