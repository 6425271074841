<template>
  <li class="company-card">
    <PreventLink :href="material.contents.headerImage.linkWithJobsource">
      <div
        class="company-card__link-wrapper"
        :href="material.contents.headerImage.link"
      >
        <div class="company-card__img">
          <img :src="material.contents.headerImage.element" />
        </div>
        <div class="company-card__title">
          {{ material.contents.bodyTitle.element }}
        </div>
        <div class="company-card__description-1">
          {{ material.contents.bodyContent[0].element }}
        </div>
        <div class="company-card__description-2">
          {{ material.contents.bodyContent[1].element }}
        </div>
        <div class="company-card__job-count">
          {{ material.contents.footerText.element }}
        </div>
      </div>
    </PreventLink>
  </li>
</template>
<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "CompanyCard",
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.company-card {
  display: flex;
  justify-content: center;
  width: 142px;
  height: 166px;
  padding: 8px 10px;
  margin-right: 1px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;

  &__link-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-decoration: none;
  }
  &__img img {
    display: block;
    width: 122px;
    height: 56px;
    object-fit: none;
  }
  &__title {
    width: 100%;
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: #292929;
    text-align: center;
  }
  &__description-1 {
    width: 100%;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #7e7e7e;
    text-align: center;
  }
  &__description-2 {
    width: 100%;
    margin-top: 0px;
    font-size: 12px;
    line-height: 18px;
    color: #7e7e7e;
    text-align: center;
  }
  &__job-count {
    width: 100%;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #7e7e7e;
    text-align: center;
  }

  &:hover {
    border: 1px solid #ff7800;
    .company-card__img img {
      opacity: 0.8;
    }
    .company-card__job-count {
      color: #ff7800;
    }
  }
}
</style>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
