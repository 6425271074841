import {
  postViewExpose as apiPostViewExpose,
  postClickExpose as apiPostClickExpose
} from "@/api/pages";

class ExposeHandler {
  constructor(token) {
    this.token = token;
    this.exposedIdSet = new Set();
  }
  addExposedShelfId(sId) {
    this.exposedIdSet.add(sId);
  }
  hasExposedShelfId(sId) {
    return this.exposedIdSet.has(sId);
  }
  #getExposeId(shelfId, viewId) {
    const isCompose = shelfId && viewId;
    return isCompose ? `${shelfId}_${viewId}` : shelfId;
  }
  addExposedId(shelfId, viewId) {
    const exposeId = this.#getExposeId(shelfId, viewId);
    this.exposedIdSet.add(exposeId);
  }
  hasExposedId(shelfId, viewId) {
    const exposeId = this.#getExposeId(shelfId, viewId);
    return this.exposedIdSet.has(exposeId);
  }
}

export class ClickExpose extends ExposeHandler {
  // 點擊曝光
  constructor(token) {
    super(token);
  }
  _apiExpose(shelfId) {
    try {
      if (shelfId) {
        return apiPostClickExpose({ shelfId, token: this.token });
      } else {
        throw new Error(`ClickExpose shelfId error: ${shelfId}`);
      }
    } catch (error) {
      console.error(error);
    }
  }
  expose(shelfId) {
    if (!this.hasExposedShelfId(shelfId)) {
      this.addExposedShelfId(shelfId);
      return this._apiExpose(shelfId);
    }
  }
}

export class ViewExpose extends ExposeHandler {
  // 靜態元件首次曝光（entry）
  // 輪播曝光 ＆ 動態載入曝光（view）
  constructor(token) {
    super(token);
  }
  _apiExpose(shelfIds) {
    try {
      if (shelfIds) {
        return apiPostViewExpose({ shelfIds, token: this.token });
      } else {
        throw new Error(`viewExpose shelfIds error: ${shelfIds}`);
      }
    } catch (error) {
      console.error(error);
    }
  }
  expose(shelfIdAry) {
    const sIdsString = shelfIdAry
      .filter(sId => {
        if (!this.hasExposedShelfId(sId)) {
          this.addExposedShelfId(sId);
          return true;
        }
      })
      .join(",");
    if (sIdsString.length > 0) {
      return this._apiExpose(sIdsString);
    }
  }
}

export class EntryExpose {
  constructor(viewExpose) {
    this.viewExpose = viewExpose;
    this.entryQueue = new Set();
    this.entryShelfIdAry = [];
  }
  _deleteEntryQueue(boardIdentify) {
    this.entryQueue.delete(boardIdentify);
  }
  _concatEntryShelfIds(shelfIdAry) {
    this.entryShelfIdAry = this.entryShelfIdAry.concat(shelfIdAry);
  }
  entry(boardIdentify, shelfIdAry) {
    this._concatEntryShelfIds(shelfIdAry);
    this._deleteEntryQueue(boardIdentify);
    if (this.entryQueue.size === 0) {
      this.viewExpose.expose(this.entryShelfIdAry);
    }
  }
  addEntryQueue(boardIdentify) {
    this.entryQueue.add(boardIdentify);
  }
}

export default class AdsmartExpose {
  /* 
  step3：
    跟曝光相關的方法放這裡
  */
  constructor(
    token = "",
    clickExposeConstructor = ClickExpose,
    viewExposeConstructor = ViewExpose,
    entryExposeConstructor = EntryExpose
  ) {
    this.clickExpose = new clickExposeConstructor(token);
    this.viewExpose = new viewExposeConstructor(token);
    this.entryExpose = new entryExposeConstructor(this.viewExpose);
  }
  click(shelfId) {
    this.clickExpose.expose(shelfId);
  }
  view(shelfIdAry) {
    this.viewExpose.expose(shelfIdAry);
  }
  entry(boardIdentify, shelfIdAry) {
    this.entryExpose.entry(boardIdentify, shelfIdAry);
  }
  addEntryQueue(boardIdentify) {
    this.entryExpose.addEntryQueue(boardIdentify);
  }
}
