<template>
  <div class="company-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CompanyList"
};
</script>

<style lang="scss">
@use "~scss/layout/colors.scss";
@use "~scss/layout/typography.scss";
@use "~scss/reset.scss";

.company-list {
  &,
  & * {
    font-family: typography.$font-family;
  }

  @include typography.t5;
  color: colors.$secondary;
  background-color: white;
  box-sizing: border-box;
  padding: 12px 16px;
  &::before {
    content: "強力放送";
    @include typography.h5;
    display: block;
    white-space: nowrap;
    margin-bottom: 16px;
    color: #292929;
  }
}
</style>
