<template>
  <div
    class="cursor-pointer"
    @click.prevent="onAdsClick(material.contents.centerTitle.link)"
  >
    <PreventLink
      class="ads-type-15"
      :href="material.contents.centerTitle.linkWithJobsource"
    >
      <component :is="pageLayout" :material="material" />
    </PreventLink>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import PcJobList from "./layout/PcJobList.vue";
import PcJobPage from "./layout/PcJobPage.vue";

export default {
  name: "AdsType15",
  components: {
    PreventLink,
    [PAGE_ENUM.PC__JOB_LIST]: PcJobList,
    [PAGE_ENUM.PC__JOB_PAGE]: PcJobPage,
    [PAGE_ENUM.PC__MY_104]: PcJobPage
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    pageLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    material() {
      return this.materials[0];
    }
  },
  mounted() {
    this.$emit("ads:entry", 0);
  },
  methods: {
    onAdsClick(linkHref) {
      this.$emit("ads:click", linkHref, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";
</style>
