<template>
  <span class="app-search-bar">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AppSearchBar"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-search-bar {
  a {
    font-size: 14px;
    line-height: 20px;
    color: #39c8d0;
  }
}
</style>
