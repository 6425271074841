<template>
  <div ref="ads-type-1" class="ads-type-1">
    <div
      ref="swiper"
      class="swiper"
      :style="{
        maxWidth,
        maxHeight,
        ...style
      }"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(material, index) of materials"
          :key="`slide-${index}`"
          :data-material-index="index"
          class="swiper-slide"
          @click.prevent="[materials.length === 1 ? onAdsClick(0) : null]"
        >
          <PreventLink :href="material.contents.bannerImage.linkWithJobsource">
            <img :src="material.contents.bannerImage.element" />
          </PreventLink>
        </div>
      </div>
      <template v-if="isControllerShow">
        <div ref="swiper-button-prev" class="swiper-button-prev">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-left-gray.png"
          />
        </div>
        <div ref="swiper-button-next" class="swiper-button-next">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-right-gray.png"
          />
        </div>
      </template>
    </div>
    <div
      v-if="isControllerShow"
      ref="swiper-pagination"
      class="swiper-pagination"
    />
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import PreventLink from "@/components/shared/PreventLink.vue";
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import boardsConfig from "@/adsmart-ui/settings/boards/type1.js";

export default {
  name: "AdsType1",
  components: {
    PreventLink
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    adsBoardIdentify: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      swiper: null,
      style: {} // for type 102 extend
    };
  },
  computed: {
    isMultiple() {
      return this.materials.length > 1;
    },
    boardConfig() {
      return boardsConfig[this.adsBoardIdentify] || {};
    },
    isControllerShow() {
      return this.boardConfig.page === PAGE_ENUM.PC__C_MAIN && this.isMultiple;
    },
    maxWidth() {
      const width = this.boardConfig.meta?.imgWidth;
      return width ? `${width}px` : null;
    },
    maxHeight() {
      const height = this.boardConfig.meta?.imgHeight;
      return height ? `${height}px` : null;
    }
  },
  mounted() {
    const vm = this;
    if (vm.isMultiple) {
      vm.swiper = new Swiper(this.$refs.swiper, {
        modules: [Autoplay, Navigation, Pagination],
        observer: true,
        allowTouchMove: false,
        preventClicks: true,
        loop: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        pagination: {
          el: this.$refs["swiper-pagination"],
          clickable: true
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"]
        },
        on: {
          click(swiper) {
            swiper.allowClick = false;
            const materialIndex = Number(
              swiper?.clickedSlide?.dataset?.materialIndex
            );
            vm.onAdsClick(materialIndex);
            if (swiper.realIndex !== materialIndex) {
              console.log("click", swiper.realIndex, materialIndex);
            }
          },
          realIndexChange(swiper) {
            if (!swiper.height) return;
            vm.$emit("ads:view", swiper.realIndex);
          },
          resize(swiper) {
            if (swiper.height && !swiper.autoplay.running)
              swiper.autoplay.start();
          }
        }
      });
      if (!vm.swiper.height) vm.swiper.autoplay.stop();
    }

    this.$emit("ads:entry", 0);
  },
  methods: {
    onAdsClick(materialIndex) {
      const material = this.materials[materialIndex];
      this.$emit(
        "ads:click",
        material.contents.bannerImage.link,
        materialIndex
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
@import "node_modules/swiper/swiper.scss";

.swiper {
  position: relative;
  cursor: pointer;
  z-index: 0;
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 16px;
  height: 16px;
  margin: 0;
  outline: 0;
  cursor: pointer;
  transform: translateY(-50%);
  > img {
    display: block;
  }
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}

.swiper-pagination {
  height: 24px;
  text-align: center;
}

::v-deep .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 0 4px;
  background: #dddddd;
  opacity: 1;
  outline: none;
  &-active {
    background: #ff7800;
  }
}
</style>
