function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (err) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export default class AdsmartContent {
  constructor(content, jobsource) {
    this.element = content.element;
    this.link = content.link;
    if (this.link && isValidHttpUrl(this.link)) {
      this.linkWithJobsource = new URL(this.link);
      this.linkWithJobsource.searchParams.append("jobsource", jobsource);
    }
  }
}
