const requireResult = require.context("./", true, /type\d*\.js$/);

const requireAll = requireResult => {
  return requireResult.keys().reduce((acc, path) => {
    const name = parseInt(path.match(/type(\d*)/)[1]);
    acc[name] = requireResult(path).default;
    return acc;
  }, {});
};

export default requireAll(requireResult);
