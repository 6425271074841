<template>
  <span class="search-bar--mobile">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "SearchBarMobile"
};
</script>

<style lang="scss">
@use "~scss/layout/search-bar.scss";

.search-bar--mobile {
  @include search-bar.layout(101, mobile);
}
</style>
