<template>
  <div class="app-job-page">
    <div class="app-job-page__content">
      <div class="app-job-page__content__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="app-job-page__content__subtitle">
        {{ material.contents.centerSubTitle.element }}
      </div>
      <p class="app-job-page__content__text">
        {{ material.contents.centerContent[0].element }}
      </p>
      <p class="app-job-page__content__text">
        {{ material.contents.centerContent[1].element }}
      </p>
    </div>

    <img
      class="sponsor-icon"
      :width="`28px`"
      :height="`20px`"
      src="~@/assets/adType20/sponsorLakeBlue.svg"
    />
  </div>
</template>

<script>
export default {
  name: "AppJobPage",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC&display=swap");

.app-job-page {
  font-family: "Noto Sans TC", Arial, sans-serif;
  display: flex;
  padding: 12px 16px;
  border-radius: 12px;
  line-height: normal;
  background-color: #fff;

  &__content {
    flex-grow: 1;
    overflow: hidden;

    &__title {
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: normal;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: normal;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__text {
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 143%;
      font-weight: normal;
      color: #7e7e7e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
