<template>
  <div class="app-apply-done">
    <div class="app-apply-done__icon">
      <img
        :src="
          material.contents.image.element ||
          `${env.VUE_APP_CDN_STATIC_URL}images/personalHeader/img-blank-profile@2x.png`
        "
      />
    </div>

    <div class="app-apply-done__content">
      <p class="app-apply-done__content__title">
        {{ material.contents.title.element }}
      </p>
      <p class="app-apply-done__content__subtitle">
        {{ material.contents.subtitle.element }}
      </p>
    </div>
  </div>
</template>

<script>
import env from "@/utils/env.js";

export default {
  name: "AppApplyDone",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      env
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-apply-done {
  display: flex;
  align-items: center;
  width: 136px;
  background: #fff;

  &__icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 44px;
    height: 44px;
    img {
      width: 100%;
    }
  }

  &__content {
    margin-left: 8px;
    overflow: hidden;

    &__title {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      color: #7e7e7e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
