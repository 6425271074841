<template>
  <div class="pc-job">
    <PreventLink :href="linkWithJobSource" class="pc-job__wrapper">
      <span class="pc-job__sponsor">贊助</span>
      <span class="pc-job__text">{{ textContent }}</span>
    </PreventLink>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "PcJob",
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    textContent() {
      return this.material.contents.bannerText.element;
    },
    linkWithJobSource() {
      return this.material.contents.bannerText.linkWithJobsource;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.pc-job {
  &__wrapper {
    display: flex;
    align-items: center;
    height: 28px;
  }

  &__sponsor {
    padding: 5px 5px 4px;
    margin-right: 4px;
    border: 1px solid #1654b9;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1;
    font-weight: normal;
    color: #1654b9;
  }

  &__text {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    color: #1654b9;
  }

  &:hover {
    .pc-job__sponsor {
      border-color: #4e91ff;
      color: #4e91ff;
    }

    .pc-job__text {
      color: #4e91ff;
    }
  }
}
</style>
