import PAGE_ENUM from "../enum/page.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = {
  PC__JOB_LIST: "pc--job-list",
  PC__CATEGORY: "pc--category",
  PC__B_VIP: "pc--b-vip",
  APP__HASHTAG: "app--hashtag",
  APP__POPULAR_TEXT: "app--popular-text",
  APP__HOT_KEYWORD: "app--hot-keyword",
  PC__JOB_LIST_SEARCH_BAR: "pc--job-list-search-bar",
  PC__SEARCH_BAR: "pc--search-bar",
  MOBILE__SEARCH_BAR: "mobile--search-bar",
  APP__SEARCH_BAR: "app--search-bar"
};

/*  
  ads-type 2 因為與搜尋匡的贊助公司共用 type，會有同一個 channel，
  不同 layout 的問題，解決方式是使用 boardIdentify 判斷 layout 
*/

const boardsPC = {
  ["af16a5c6edfa1ffd53504e66f66035eb"]: new BoardConfig({
    // 快訊文字
    page: PAGE_ENUM.PC__CATEGORY,
    meta: { layout: LAYOUT_NAME.PC__CATEGORY },
    preview: { mockupLayout: "news-text" }
  }),
  ["b0b57eebb8d71cc3588055b2c27ae043"]: new BoardConfig({
    // VIP最新消息
    page: PAGE_ENUM.PC__B_VIP,
    meta: { layout: LAYOUT_NAME.PC__B_VIP },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["f20c1d9b550c979c83a417e613c3400f"]: new BoardConfig({
    // 強力放送
    page: PAGE_ENUM.PC__JOB_LIST,
    meta: { layout: LAYOUT_NAME.PC__JOB_LIST },
    preview: { mockupLayout: "power-broadcast--job-list" }
  }),
  ["b3647587e9c96f063ff278ed69f8ff64"]: new BoardConfig({
    // 強力放送
    page: PAGE_ENUM.PC__COMPANY_LIST,
    meta: { layout: LAYOUT_NAME.PC__JOB_LIST },
    preview: { mockupLayout: "power-broadcast--company" }
  })
};

const boardsMobile = {
  ["3f0b2f68913daad983a55524e2f07ae9"]: new BoardConfig({
    // 強力放送
    page: PAGE_ENUM.MOBILE__JOB_LIST,
    meta: { layout: LAYOUT_NAME.PC__JOB_LIST }
  })
};

const boardsAPP = {
  ["412cc703ce07058237fca6f81592ae6c"]: new BoardConfig({
    // (全)Hashtag1(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_full1"]: new BoardConfig({
    // (全)Hashtag1(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["cc6d0c59ac19c6899377f020a17822cf"]: new BoardConfig({
    // (兼)Hashtag1(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_part1"]: new BoardConfig({
    // (兼)Hashtag1(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["078ee3e9d15f15554efddfd3ebf97723"]: new BoardConfig({
    // (全)Hashtag2(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_full2"]: new BoardConfig({
    // (全)Hashtag2(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["0e037983df3939f7ed00b6165c618345"]: new BoardConfig({
    // (兼)Hashtag2(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_part2"]: new BoardConfig({
    // (兼)Hashtag2(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["f110b7b20b8fbf3ed208c12a2cbf46ac"]: new BoardConfig({
    // (全)Hashtag3(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_full3"]: new BoardConfig({
    // (全)Hashtag3(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["48b2c1e986f6e56f9a1e1f08266b98ed"]: new BoardConfig({
    // (兼)Hashtag3(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_part3"]: new BoardConfig({
    // (兼)Hashtag3(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["f127b97ebe33829ceae1c5a145b3761a"]: new BoardConfig({
    // (全)Hashtag4(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_full4"]: new BoardConfig({
    // (全)Hashtag4(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["ac4cad185899e845cb622559819a7e24"]: new BoardConfig({
    // (兼)Hashtag4(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage_hashtag_part4"]: new BoardConfig({
    // (兼)Hashtag4(cache 30分)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__HASHTAG },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["568794a137fbee902a80a7ddcc0c51a6"]: new BoardConfig({
    // 人氣文字1(全職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_searchpage_pop_full1"]: new BoardConfig({
    // 人氣文字1(全職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["a2a0e1026e4a23354ab253dc4ca2cfb0"]: new BoardConfig({
    // 人氣文字1(兼職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_searchpage_pop_part1"]: new BoardConfig({
    // 人氣文字1(兼職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["451a13cb2d9aa77ba0c0855f3183e5ab"]: new BoardConfig({
    // 人氣文字2(全職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_searchpage_pop_full2"]: new BoardConfig({
    // 人氣文字2(全職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["74503639ab284304748d5e354f10bc4a"]: new BoardConfig({
    // 人氣文字2(兼職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_searchpage_pop_part2"]: new BoardConfig({
    // 人氣文字2(兼職)
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["c1e99d4873cb18d5bbdbca2b5514786b"]: new BoardConfig({
    // 人氣文字1(全職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_search_pop_full1"]: new BoardConfig({
    // 人氣文字1(全職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["62d3a009d0684e53aad4e93c6d049372"]: new BoardConfig({
    // 人氣文字1(兼職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_search_pop_part1"]: new BoardConfig({
    // 人氣文字1(兼職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["337c50e73d6eed74179bf035f3361390"]: new BoardConfig({
    // 人氣文字2(全職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_search_pop_full2"]: new BoardConfig({
    // 人氣文字2(全職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["211a70189467fbebae494f96363f327d"]: new BoardConfig({
    // 人氣文字2(兼職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["app_c_search_pop_part2"]: new BoardConfig({
    // 人氣文字2(兼職)
    page: PAGE_ENUM.APP__SEARCH_BAR,
    meta: { layout: LAYOUT_NAME.APP__POPULAR_TEXT }
  }),
  ["f4fe5a569d4c9bb424499e7350f71a4e"]: new BoardConfig({
    // 熱門關鍵字1
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_companylist_popkeyword1"]: new BoardConfig({
    // 熱門關鍵字1
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["98ce4dd7fd1b8830b729c5c234bc42eb"]: new BoardConfig({
    // 熱門關鍵字2
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_companylist_popkeyword2"]: new BoardConfig({
    // 熱門關鍵字2
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["81c5d1f2b195cc7797f049824026c197"]: new BoardConfig({
    // 熱門關鍵字3
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_companylist_popkeyword3"]: new BoardConfig({
    // 熱門關鍵字3
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["99652812b2b28fe91e058a1ec228324c"]: new BoardConfig({
    // 熱門關鍵字4
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_companylist_popkeyword4"]: new BoardConfig({
    // 熱門關鍵字4
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["81567ef28e63b39d761474711fadb458"]: new BoardConfig({
    // 熱門關鍵字5
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_companylist_popkeyword5"]: new BoardConfig({
    // 熱門關鍵字5
    page: PAGE_ENUM.APP__SEARCH_COMPANY,
    meta: { layout: LAYOUT_NAME.APP__HOT_KEYWORD },
    preview: { mockupLayout: "d-inline-block" }
  })
};

const boardsSponsor = {
  ["0fc4b1836c22cf289d2250e124e30be1"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.PC__C_MAIN,
    meta: { layout: LAYOUT_NAME.PC__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["d6fcc9dac6ae8a32fe1c36e28e26aab6"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.PC__MY_104,
    meta: { layout: LAYOUT_NAME.PC__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["8ab48214fd2aedd9059c7f61a9e00133"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.PC__JOB_LIST,
    meta: { layout: LAYOUT_NAME.PC__JOB_LIST_SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["70018ef3b8ad65e2605b3c90973e1a71"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.PC__COMPANY_LIST,
    meta: { layout: LAYOUT_NAME.PC__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["99a978f375ede05fe37a4299cec4b1cd"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.MOBILE__C_MAIN,
    meta: { layout: LAYOUT_NAME.MOBILE__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["e53daab23d3d8541989be4b117ba3b2c"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.MOBILE__JOB_SEARCH,
    meta: { layout: LAYOUT_NAME.MOBILE__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["b3a718a31af197e46898e8ffc9b177a6"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.MOBILE__COMPANY_LIST,
    meta: { layout: LAYOUT_NAME.PC__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["51df6726c1fa3d96fb5d7f81342c449b"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  }),
  ["app_c_searchpage__sponsor_company"]: new BoardConfig({
    // 贊助公司
    page: PAGE_ENUM.APP__SEARCH,
    meta: { layout: LAYOUT_NAME.APP__SEARCH_BAR },
    preview: { mockupLayout: "d-inline-block" }
  })
};

export default {
  ...boardsPC,
  ...boardsMobile,
  ...boardsAPP,
  ...boardsSponsor
};
