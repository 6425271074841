import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  INDUSTRY_EVENT_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  industryEventShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = INDUSTRY_EVENT_LAYOUT_NAME;

const commonConfig = new BoardConfig({
  // 產業特輯 列表
  page: PAGE_ENUM.RWD__INDUSTRY_EVENT,
  meta: {
    compose: {
      shapeGroupNo: deviceShapeGroupNoSetting,
      shapeLayout: industryEventShapeLayoutSetting
    }
  },
  preview: {
    isWidthFill: true,
    compose: {
      container: {
        [DEVICE_SHAPE_ENUM.PC]: {
          width: 716
        },
        [DEVICE_SHAPE_ENUM.MOBILE]: {
          width: 360
        }
      }
    }
  }
});

export default {
  // 零售服務業_列表
  ["pc_c_industry_retailservice"]: commonConfig,
  // 顧問金融業_列表
  ["pc_c_industry_consultingfinance"]: commonConfig,
  // 工程製造業_列表
  ["pc_c_industry_engineeringmanufacturing"]: commonConfig,
  // 科技研發業_列表
  ["pc_c_industry_technologyrd"]: commonConfig,
  // 半導體業_列表
  ["pc_c_industry_semiconductor"]: commonConfig,
  // 住宿旅遊_列表(民生)
  ["pc_c_industry_travelaccommodation"]: commonConfig,
  // 餐飲門市_列表(民生)
  ["pc_c_industry_cateringstore"]: commonConfig,
  // 百貨零售_列表(民生)
  ["pc_c_industry_departmentstoreretail"]: commonConfig,
  // 其他服務_列表(民生)
  ["pc_c_industry_otherservice"]: commonConfig
};
