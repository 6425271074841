<template>
  <component
    :is="layoutName"
    :material="materials[0]"
    :has-event-tag="hasEventTag"
    :has-tail-btn="hasTailBtn"
    @ads:click="onAdsClick"
    @ads:entry="onAdsEntry"
  ></component>
</template>

<script>
import IndustryEventShapePC from "./layout/IndustryEventShapePC.vue";
import IndustryEventShapeMobile from "./layout/IndustryEventShapeMobile.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type106.js";

export default {
  name: "AdsType106",
  components: {
    [LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_PC]: IndustryEventShapePC,
    [LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_MOBILE]: IndustryEventShapeMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasEventTag() {
      return this.layoutName === LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_PC;
    },
    hasTailBtn() {
      return this.layoutName === LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_PC;
    }
  },
  methods: {
    onAdsClick() {
      this.$emit("ads:click", this.materials[0].contents.companyLogo.link, 0);
    },
    onAdsEntry() {
      this.$emit("ads:entry", 0);
    }
  }
};
</script>
