import TYPE_ENUM from "./enum/type.js";

export const MODE_ENUM = {
  ENTRY: "entry",
  DYNAMIC: "dynamic",
  KEYWORD: "keyword",
  CUSTNO: "custno",
  COMPOSE: "compose",
  COMPOSE_ENTRY: "composeEntry",
  COMPOSE_DYNAMIC: "composeDynamic",
  COMPOSE_KEYWORD: "composeKeyword",
  COMPOSE_CUSTNO: "composeCustno"
};

const config = {};

/*  source: [typeGroupNo, sort] */

config[TYPE_ENUM.TYPE_1] = {
  mode: MODE_ENUM.ENTRY,
  contents: [{ name: "bannerImage", source: [1, 1] }]
};

config[TYPE_ENUM.TYPE_2] = {
  mode: MODE_ENUM.ENTRY,
  contents: [{ name: "bannerText", source: [1, 1] }]
};

config[TYPE_ENUM.TYPE_4] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "image", source: [1, 1] },
    { name: "title", source: [1, 2] },
    { name: "subtitle", source: [1, 3] }
  ]
};

config[TYPE_ENUM.TYPE_5] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "image", source: [1, 1] },
    { name: "textContent", source: [1, 2] }
  ]
};

config[TYPE_ENUM.TYPE_7] = {
  mode: MODE_ENUM.KEYWORD,
  contents: [
    { name: "keyword", source: [1, 1] },
    { name: "bannerText", source: [1, 2] }
  ]
};

config[TYPE_ENUM.TYPE_8] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "title", source: [1, 1] },
    { name: "subtitle", source: [1, 2] },
    {
      name: "content",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_9] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "headerImage", source: [1, 1] },
    { name: "bodyTitle", source: [1, 2] },
    {
      name: "bodyContent",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    },
    { name: "footerText", source: [1, 5] }
  ]
};

config[TYPE_ENUM.TYPE_14] = {
  mode: MODE_ENUM.ENTRY,
  contents: [{ name: "image", source: [1, 1] }]
};

config[TYPE_ENUM.TYPE_15] = {
  mode: MODE_ENUM.DYNAMIC,
  contents: [
    { name: "centerTitle", source: [1, 1] },
    { name: "centerSubTitle", source: [1, 2] },
    {
      name: "centerContent",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    },
    { name: "sideImage", source: [1, 5] }
  ]
};

config[TYPE_ENUM.TYPE_16] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "defaultImage", source: [1, 1] },
    { name: "iframeSrcSm", source: [1, 2] },
    { name: "iframeSrcLg", source: [2, 1] }
  ]
};

config[TYPE_ENUM.TYPE_17] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "headerImage", source: [1, 1] },
    { name: "bodyTitle", source: [1, 2] },
    { name: "bodyContent", source: [1, 3] },
    { name: "footerTextLeft", source: [1, 4] },
    { name: "footerTextRight", source: [1, 5] }
  ]
};

config[TYPE_ENUM.TYPE_18] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "imageLg", source: [1, 1] },
    { name: "imageMd", source: [2, 11] },
    { name: "imageSm", source: [2, 1] },
    { name: "bodyTitle", source: [2, 2] },
    { name: "bodyContent", source: [2, 3] },
    {
      name: "bodyList",
      sourceArray: [
        [2, 4],
        [2, 5],
        [2, 6],
        [2, 7]
      ]
    },
    { name: "moreButton", source: [2, 8] },
    { name: "footerLeft", source: [2, 9] },
    { name: "footerRight", source: [2, 10] }
  ]
};

config[TYPE_ENUM.TYPE_20] = {
  mode: MODE_ENUM.DYNAMIC,
  contents: [
    { name: "centerTitle", source: [1, 1] },
    { name: "centerSubTitle", source: [1, 2] },
    {
      name: "centerContent",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_21] = {
  mode: MODE_ENUM.ENTRY,
  contents: [{ name: "bannerText", source: [1, 1] }]
};

config[TYPE_ENUM.TYPE_22] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "image", source: [1, 1] },
    { name: "title", source: [1, 2] },
    {
      name: "content",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    },
    { name: "clickForMoreText", source: [2, 1] }
  ]
};

config[TYPE_ENUM.TYPE_25] = {
  mode: MODE_ENUM.ENTRY,
  contents: [{ name: "bannerImage", source: [1, 1] }]
};

config[TYPE_ENUM.TYPE_26] = {
  mode: MODE_ENUM.ENTRY,
  contents: [
    { name: "centerTitle", source: [1, 1] },
    { name: "centerContent", source: [1, 2] },
    { name: "rightContent", source: [1, 3] }
  ]
};

config[TYPE_ENUM.TYPE_27] = {
  mode: MODE_ENUM.DYNAMIC,
  contents: [
    { name: "companyLogo", source: [1, 1] },
    { name: "companyName", source: [1, 2] },
    { name: "industryTag", source: [1, 3] },
    { name: "jobLocation", source: [1, 4] },
    { name: "industryDes", source: [1, 5] },
    { name: "textContent", source: [1, 6] },
    {
      name: "welfareTags",
      sourceArray: [
        [1, 7],
        [1, 8],
        [1, 9],
        [1, 10]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_28] = {
  mode: MODE_ENUM.DYNAMIC,
  contents: [
    { name: "companyLogo", source: [1, 1] },
    { name: "companyName", source: [1, 2] },
    { name: "jobLocation", source: [1, 3] },
    { name: "industryDes", source: [1, 4] },
    { name: "textContent", source: [1, 5] }
  ]
};

config[TYPE_ENUM.TYPE_29] = {
  mode: MODE_ENUM.CUSTNO,
  contents: [
    { name: "textContent", source: [1, 1] },
    { name: "custno", source: [1, 2] }
  ]
};

// 複合元素型態元件
config[TYPE_ENUM.TYPE_100] = {
  mode: MODE_ENUM.COMPOSE_ENTRY,
  contents: [
    { name: "1:bannerText", source: [1, 1] },
    { name: "2:bannerText", source: [2, 1] }
  ]
};

config[TYPE_ENUM.TYPE_101] = {
  mode: MODE_ENUM.COMPOSE_KEYWORD,
  contents: [
    { name: "1:keyword", source: [1, 1] },
    { name: "1:bannerText", source: [1, 2] },
    { name: "2:keyword", source: [2, 1] },
    { name: "2:bannerText", source: [2, 2] }
  ]
};

config[TYPE_ENUM.TYPE_102] = {
  mode: MODE_ENUM.COMPOSE,
  contents: [
    { name: "1:bannerImage", source: [1, 1] },
    { name: "2:bannerImage", source: [2, 1] }
  ]
};

config[TYPE_ENUM.TYPE_103] = {
  mode: MODE_ENUM.COMPOSE_ENTRY,
  contents: [
    { name: "1:sideImg", source: [1, 1] },
    { name: "1:title", source: [1, 2] },
    { name: "1:description", source: [1, 3] },
    {
      name: "1:list",
      sourceArray: [
        [1, 4],
        [1, 5],
        [1, 6]
      ]
    },
    { name: "2:sideImg", source: [2, 1] },
    { name: "2:title", source: [2, 2] },
    { name: "2:description", source: [2, 3] },
    {
      name: "2:list",
      sourceArray: [
        [2, 4],
        [2, 5],
        [2, 6]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_104] = {
  mode: MODE_ENUM.COMPOSE_DYNAMIC,
  contents: [
    { name: "1:title", source: [1, 1] },
    { name: "1:subtitle", source: [1, 2] },
    {
      name: "1:content",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    },
    { name: "2:title", source: [2, 1] },
    { name: "2:subtitle", source: [2, 2] },
    {
      name: "2:content",
      sourceArray: [
        [2, 3],
        [2, 4]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_105] = {
  mode: MODE_ENUM.COMPOSE_ENTRY,
  contents: [
    { name: "1:headerImage", source: [1, 1] },
    { name: "1:bodyTitle", source: [1, 2] },
    {
      name: "1:bodyContent",
      sourceArray: [
        [1, 3],
        [1, 4]
      ]
    },
    { name: "1:footerText", source: [1, 5] },
    { name: "2:headerImage", source: [2, 1] },
    { name: "2:bodyTitle", source: [2, 2] },
    {
      name: "2:bodyContent",
      sourceArray: [
        [2, 3],
        [2, 4]
      ]
    },
    { name: "2:footerText", source: [2, 5] }
  ]
};

config[TYPE_ENUM.TYPE_106] = {
  mode: MODE_ENUM.COMPOSE_DYNAMIC,
  contents: [
    { name: "1:companyLogo", source: [1, 1] },
    { name: "1:eventBanner", source: [1, 2] },
    { name: "1:companyName", source: [1, 3] },
    { name: "1:industryTag", source: [1, 4] },
    { name: "1:jobLocation", source: [1, 5] },
    { name: "1:industryDes", source: [1, 6] },
    { name: "1:textContent", source: [1, 7] },
    {
      name: "1:welfareTags",
      sourceArray: [
        [1, 8],
        [1, 9],
        [1, 10],
        [1, 11]
      ]
    },
    { name: "2:companyLogo", source: [2, 1] },
    { name: "2:eventBanner", source: [2, 2] },
    { name: "2:companyName", source: [2, 3] },
    { name: "2:industryTag", source: [2, 4] },
    { name: "2:jobLocation", source: [2, 5] },
    { name: "2:industryDes", source: [2, 6] },
    { name: "2:textContent", source: [2, 7] },
    {
      name: "2:welfareTags",
      sourceArray: [
        [2, 8],
        [2, 9],
        [2, 10],
        [2, 11]
      ]
    }
  ]
};

config[TYPE_ENUM.TYPE_107] = {
  mode: MODE_ENUM.COMPOSE_DYNAMIC,
  contents: [
    { name: "1:companyLogo", source: [1, 1] },
    { name: "1:eventBanner", source: [1, 2] },
    { name: "1:companyName", source: [1, 3] },
    { name: "1:jobLocation", source: [1, 4] },
    { name: "1:industryDes", source: [1, 5] },
    { name: "1:textContent", source: [1, 6] },
    { name: "2:companyLogo", source: [2, 1] },
    { name: "2:eventBanner", source: [2, 2] },
    { name: "2:companyName", source: [2, 3] },
    { name: "2:jobLocation", source: [2, 4] },
    { name: "2:industryDes", source: [2, 5] },
    { name: "2:textContent", source: [2, 6] }
  ]
};

config[TYPE_ENUM.TYPE_108] = {
  mode: MODE_ENUM.COMPOSE_CUSTNO,
  contents: [
    { name: "1:textContent", source: [1, 1] },
    { name: "1:custno", source: [1, 2] },
    { name: "2:textContent", source: [2, 1] },
    { name: "2:custno", source: [2, 2] }
  ]
};

export default config;
