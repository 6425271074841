<template>
  <div class="c-main-slide-shape-pc">
    <div class="company-card">
      <div class="company-card__img">
        <img :src="material.contents.headerImage.element" />
      </div>
      <div class="company-card__title">
        {{ material.contents.bodyTitle.element }}
      </div>
      <div class="company-card__description-1">
        {{ material.contents.bodyContent[0].element }}
      </div>
      <div class="company-card__description-2">
        {{ material.contents.bodyContent[1].element }}
      </div>
      <div class="company-card__job-count">
        {{ material.contents.footerText.element }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CMainSlideShapePc",
  components: {},
  props: {
    material: {
      type: Object,
      default() {
        return {
          contents: {
            headerImage: {},
            bodyTitle: {},
            bodyContent: [{}, {}],
            footerText: {}
          }
        };
      }
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
@import "~scss/layout/c-main.scss";

.c-main-slide-shape-pc {
  @include useCMainLayout(9, pc, slide);
}
</style>
