<template>
  <span class="pc-job-list-search-bar">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PcJobListSearchBar"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.pc-job-list-search-bar {
  display: block;
  width: 100%;
  color: #ff7800;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
