import { postClickExpose as apiPostClickExpose } from "@/api/pages.js";
import { deviceViewIdSetting } from "../../settings/composeSettings.js";
import Expose from "./Expose.js";

export default class ClickExpose extends Expose {
  constructor(token) {
    super(token);
  }
  async expose(shelfId, groupNo) {
    try {
      if (!this.hasExposedId(shelfId)) {
        await apiPostClickExpose({
          shelfId,
          view: deviceViewIdSetting[groupNo],
          token: this.token
        });
        this.addExposedId(shelfId);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
