<script>
import IndustryEvent from "./IndustryEvent.vue";

export default {
  extends: IndustryEvent
};
</script>

<style lang="scss" scoped>
@use "~scss/layout/industry-event.scss";
.ads-type-106 {
  @include industry-event.layout(106, mobile);
}
</style>
