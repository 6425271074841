<template lang="html">
  <div class="ads-type-8">
    <div class="ads-type-8__content">
      <p class="ads-type-8__content__title">
        {{ material.contents.title.element }}
      </p>
      <p class="ads-type-8__content__subtitle">
        {{ material.contents.subtitle.element }}
      </p>
      <p class="ads-type-8__content__tag">
        <span>{{ material.contents.content[0].element }}</span>
        <span>{{ material.contents.content[1].element }}</span>
      </p>
    </div>

    <div class="ads-type-8__sponsor">
      <span>贊助</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdType8",
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    material() {
      return this.materials[0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.ads-type-8 {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;

  &__content {
    flex-grow: 1;
    overflow: hidden;

    &__title {
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: normal;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__tag {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      color: #292929;

      span:nth-child(1) {
        color: #ff7800;
      }
      span:nth-child(2) {
        position: relative;
        color: #7e7e7e;
        margin-left: 12px;

        &::before {
          content: "";
          position: absolute;
          top: 1px;
          left: -6px;
          height: 11px;
          width: 1px;
          background-color: #7e7e7e;
        }
      }
    }
  }

  &__sponsor {
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: start;
    min-width: 28px;
    font-size: 10px;
    line-height: 18px;
    color: #ea475b;

    > span {
      padding: 0 4px;
      border: 1px solid #ea475b;
      border-radius: 4px;
      white-space: nowrap;
    }
  }
}
</style>
