<template>
  <div class="app-hashtag">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppHashtag"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-hashtag {
  margin: 12px;
  width: 108px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 3px 4px 3px rgba(#a9a9a9, 0.16);
  font-size: 12px;

  a {
    display: block;
    font-size: 12px;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #292929;

    &:before {
      content: "#";
      display: inline-block;
      text-align: center;
      width: 12px;
      color: #ff7800;
      font-weight: bold;
    }
  }

  &:after {
    content: "10,000個職缺";
    display: block;
    color: #a9a9a9;
  }
}
</style>
