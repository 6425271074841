import pageLayout from "@/adsmart-ui/settings/pageLayout.js";

/** 
 * @param {Object} boardData
 * @example
 * {
    "siteId": 1,
    "channelId": 4,
    "boardId": 16,
    "siteIdentify": "46317460b85eb60d2ed54ea2d0ea0392",
    "channelIdentify": "ef9da48b5f614d7428f95147c9012b87",
    "boardIdentify": "b7adef25f59dbc81b8a05d5b5c27c562",
    "typeId": 17,
    "style": "",
    "lowerLimit": 1,
    "upperLimit": 2,
    "conditionId": 0,
    "materials": []
  }
 * @param {String} pageLayout
*/

export default class NCCLogHandler {
  constructor(boardData) {
    this.boardData = boardData;
  }

  add(shelfId, jobsource) {
    window._elog = window._elog || [];
    window._elog.push({
      web: this.#getWeb(),
      track: ["clickBanner", ""],
      ext: this.#getExt(shelfId, jobsource)
    });
  }

  get #device() {
    return pageLayout[this.boardData.channelIdentify].split("--")[0];
  }

  #getWeb() {
    return this.#device == "mobile" ? "104_cmw" : "104_bank";
  }

  #getExt(shelfId, jobsource) {
    return {
      siteId: String(this.boardData.siteId),
      channelId: String(this.boardData.channelId),
      boardId: String(this.boardData.boardId),
      typeId: String(this.boardData.typeId),
      boardIdentify: String(this.boardData.boardIdentify),
      shelfId,
      jobsource
    };
  }
}
