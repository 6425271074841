<template>
  <div class="job-page">
    <div class="job-page__left">
      <div class="job-page__left__sponsor">贊助</div>
    </div>
    <div class="job-page__center">
      <div class="job-page__center__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="job-page__center__sub-title">
        {{ material.contents.centerSubTitle.element }}
      </div>
      <div class="job-page__center__content">
        {{ material.contents.centerContent[0].element }}
        <br />
        {{ material.contents.centerContent[1].element }}
      </div>
    </div>
    <div class="job-page__right">
      <img :src="material.contents.sideImage.element" />
    </div>
  </div>
</template>

<script>
export default {
  name: "JobList",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.job-page {
  display: flex;
  padding: 12px 24px;
  @media (min-width: 1366px) {
    padding: 12px 40px;
  }
  @media (min-width: 1680px) {
    padding: 12px 64px;
  }

  &__left {
    margin-right: 24px;
    text-align: center;

    &__sponsor {
      font-size: 14px;
      line-height: 24px;
      color: #a9a9a9;
    }
  }

  &__center {
    flex-grow: 1;

    &__title {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #1654b9;
    }
    &__sub-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #1654b9;
    }
    &__content {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #7e7e7e;
    }
  }

  &__right {
    padding-top: 32px;
    width: 120px;
    @media (min-width: 1366px) {
      width: 132px;
    }
    @media (min-width: 1680px) {
      width: 148px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
