<template>
  <div class="c-main--shape-pc">
    <div class="c-main--shape-pc__wrapper">
      <div ref="swiper-0" class="swiper">
        <div class="swiper-wrapper">
          <a
            v-for="(material, index) of evenMaterials"
            :key="`slide-${index}`"
            :data-material-index="index + 1"
            class="swiper-slide"
            :href="material.contents.sideImg.linkWithJobsource"
            target="_blank"
            @click.prevent
            @contextmenu.prevent
          >
            <CMainSlideShapePc :material="material" />
          </a>
        </div>
      </div>
      <div ref="swiper-pagination-0" class="swiper-pagination"></div>
    </div>
    <div class="c-main--shape-pc__wrapper">
      <div ref="swiper-1" class="swiper">
        <div class="swiper-wrapper">
          <a
            v-for="(material, index) of oddMaterials"
            :key="`slide-${index}`"
            :data-material-index="index"
            class="swiper-slide"
            :href="material.contents.sideImg.linkWithJobsource"
            target="_blank"
            @click.prevent
            @contextmenu.prevent
          >
            <CMainSlideShapePc :material="material" />
          </a>
        </div>
      </div>
      <div ref="swiper-pagination-1" class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import CMainSlideShapePc from "../components/CMainSlideShapePc.vue";

export default {
  name: "CMainShapePc",
  components: {
    CMainSlideShapePc
  },
  props: {
    materials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swipers: []
    };
  },
  computed: {
    evenMaterials() {
      return this.materials.filter((_, i) => i % 2 === 0);
    },
    oddMaterials() {
      return this.materials.filter((_, i) => i % 2 === 1);
    }
  },
  mounted() {
    this.createSwiper(0);
    this.createSwiper(1);

    this.onAdsEntry();
  },
  methods: {
    createSwiper(index) {
      const vm = this;
      this.swipers[index] = new Swiper(this.$refs[`swiper-${index}`], {
        modules: [Autoplay, Pagination],
        spaceBetween: 16,
        observer: true,
        preventClicks: true,
        loop: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        pagination: {
          el: this.$refs[`swiper-pagination-${index}`],
          clickable: true
        },
        on: {
          click(swiper) {
            swiper.allowClick = false;
            // if index = 0 => 0,2,4
            // if index = 1 => 1,3,5
            const clickIndex = swiper.realIndex * 2 + index;
            vm.onAdsClick(clickIndex);
          },
          realIndexChange(swiper) {
            if (!swiper.height) return;
            vm.onAdsView(swiper.realIndex * 2 + index);
          },
          resize(swiper) {
            if (swiper.height && !swiper.autoplay.running) {
              swiper.autoplay.start();
            }
          }
        }
      });
      if (!vm.swipers[index].height) vm.swipers[index].autoplay.stop();
    },
    onAdsEntry() {
      this.$emit("ads:entry", [0, 1]);
    },
    onAdsView(materialIndex) {
      this.$emit("ads:view", materialIndex);
    },
    onAdsClick(materialIndex) {
      const material = this.materials[materialIndex];
      this.$emit("ads:click", material.contents.sideImg.link, materialIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";
@import "node_modules/swiper/swiper.scss";

.c-main--shape-pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-main--shape-pc__wrapper {
  width: calc(50% - 20px);
  @include useCMainLayout(103, pc, wrapper);
}
</style>
