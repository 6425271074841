import PAGE_ENUM from "../enum/page.js";
// import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  INDUSTRY_EVENT_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  industryEventShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = INDUSTRY_EVENT_LAYOUT_NAME;

export default {
  ["pc_c_industry_company_tag"]: new BoardConfig({
    // 複合元件 產業特輯 TAG
    page: PAGE_ENUM.RWD__INDUSTRY_COMPANY,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: industryEventShapeLayoutSetting
      }
    }
  })
};
