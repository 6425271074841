<template>
  <span class="pc-job-list">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PcJobList"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";

.pc-job-list {
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
}
</style>
