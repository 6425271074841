<template>
  <div ref="type-105-c-main--shape-pc" class="type-105-c-main--shape-pc">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <a
          v-for="(material, index) of materials"
          :key="`slide-${index}`"
          :data-material-index="index"
          class="swiper-slide"
          :href="material.contents.headerImage.linkWithJobsource"
          @click.prevent
          @contextmenu.prevent
        >
          <CMainSlideShapePc :material="material"></CMainSlideShapePc>
        </a>
      </div>
    </div>
    <div ref="swiper-button-prev" class="swiper-button-prev">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4668 14.2733C11.7575 13.9798 11.7575 13.5069 11.4668 13.2133L6.14017 8L11.4735 2.78667C11.7699 2.49764 11.7759 2.02306 11.4868 1.72667C11.1978 1.43028 10.7232 1.42431 10.4268 1.71334L4.54684 7.46667C4.40179 7.60618 4.31982 7.79875 4.31982 8C4.31982 8.20126 4.40179 8.39383 4.54684 8.53334L10.4068 14.2867C10.5488 14.4262 10.7406 14.5033 10.9396 14.5008C11.1386 14.4983 11.3284 14.4164 11.4668 14.2733Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div ref="swiper-button-next" class="swiper-button-next">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5329 1.72669C4.24227 2.02026 4.24227 2.49312 4.5329 2.78669L9.86623 8.00002L4.5329 13.2134C4.23651 13.5024 4.23054 13.977 4.51957 14.2734C4.8086 14.5697 5.28317 14.5757 5.57957 14.2867L11.4396 8.53335C11.5846 8.39384 11.6666 8.20127 11.6666 8.00002C11.6666 7.79877 11.5846 7.6062 11.4396 7.46669L5.5929 1.71335C5.45094 1.57383 5.25915 1.49676 5.06012 1.49927C4.86109 1.50177 4.6713 1.58364 4.5329 1.72669Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div ref="swiper-pagination" class="swiper-pagination" />
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import CMainSlideShapePc from "../components/CMainSlideShapePc.vue";

export default {
  name: "CMainShapePc",
  components: {
    CMainSlideShapePc
  },
  props: {
    materials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {},
  mounted() {
    const vm = this;
    vm.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 16,
      observer: true,
      preventClicks: true,
      loop: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: this.$refs["swiper-pagination"],
        clickable: true
      },
      navigation: {
        nextEl: this.$refs["swiper-button-next"],
        prevEl: this.$refs["swiper-button-prev"]
      },
      on: {
        click(swiper) {
          swiper.allowClick = false;
          const materialIndex = Number(
            swiper?.clickedSlide?.dataset?.materialIndex
          );
          vm.onAdsClick(materialIndex);
        },
        realIndexChange(swiper) {
          if (!swiper.height) return;
          vm.onAdsView(swiper.realIndex);
        },
        resize(swiper) {
          if (swiper.height && !swiper.autoplay.running) {
            swiper.autoplay.start();
          }
        }
      }
    });
    if (!vm.swiper.height) vm.swiper.autoplay.stop();

    this.onAdsEntry();
  },
  methods: {
    getStagingMaterialIndexList(startIndex) {
      const maxLength = this.materials.length;
      return Array.from({ length: 6 }, (_, i) => {
        const index = i + startIndex;
        return index >= maxLength ? index - maxLength : index;
      });
    },
    onAdsEntry() {
      const getStagingMaterialIndexList = this.getStagingMaterialIndexList(0);
      this.$emit("ads:entry", getStagingMaterialIndexList);
    },
    onAdsView(startMaterialIndex) {
      const getStagingMaterialIndexList =
        this.getStagingMaterialIndexList(startMaterialIndex);
      this.$emit("ads:view", getStagingMaterialIndexList);
    },
    onAdsClick(materialIndex) {
      const material = this.materials[materialIndex];
      this.$emit(
        "ads:click",
        material.contents.headerImage.link,
        materialIndex
      );
    }
  }
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";
@import "node_modules/swiper/swiper.scss";

.type-105-c-main--shape-pc {
  position: relative;
  @include useCMainLayout(105, pc, wrapper);
}
</style>
