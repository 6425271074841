<template>
  <div class="ads-type-5">
    <div class="ads-type-5__icon-container">
      <img
        v-if="material.contents.image"
        :src="material.contents.image.element"
      />
      <img
        v-else
        :src="`${env.VUE_APP_CDN_STATIC_URL}images/personalHeader/img-blank-profile@2x.png`"
      />
    </div>
    <p class="ads-type-5__text-container">
      {{ material.contents.textContent.element }}
    </p>
  </div>
</template>

<script>
import env from "@/utils/env.js";

export default {
  name: "AdsType5",
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      env
    };
  },
  computed: {
    material() {
      return this.materials[0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.ads-type-5 {
  background: #fff;
  width: 72px;

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin: 0px auto 10px auto;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  &__text-container {
    margin-bottom: 0px;
    color: #292929;
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
