export default class EntryViewExpose {
  #viewExpose;
  #entryQueueSet = new Set();
  #entryShelfIdArray = [];

  constructor(viewExpose) {
    this.#viewExpose = viewExpose;
  }

  #concatEntryShelfIdArray(shelfIdArray) {
    this.#entryShelfIdArray = this.#entryShelfIdArray.concat(shelfIdArray);
  }

  #deleteEntryQueue(boardIdentify) {
    this.#entryQueueSet.delete(boardIdentify);
  }

  addEntryQueue(boardIdentify) {
    this.#entryQueueSet.add(boardIdentify);
  }

  async entry(boardIdentify, shelfIdArray, groupNo) {
    try {
      this.#concatEntryShelfIdArray(shelfIdArray);
      this.#deleteEntryQueue(boardIdentify);
      const isReady = this.#entryQueueSet.size === 0;
      if (isReady) {
        await this.#viewExpose.expose(this.#entryShelfIdArray, groupNo);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
