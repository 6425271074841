import PAGE_ENUM from "../enum/page.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = {
  PC_C_MAIN: "PcCMain"
};

export default {
  ["fcc34744723bd4cb85805cc687dcaa73"]: new BoardConfig({
    // 舊版首頁 熱門企業
    page: PAGE_ENUM.PC__C_MAIN,
    meta: {
      layout: LAYOUT_NAME.PC_C_MAIN
    },
    preview: {}
  })
};
