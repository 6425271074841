const requireResult = require.context(
  "./",
  true,
  /index\.vue$|AdsType\d*\.vue$/
);

const requireAll = requireResult => {
  return requireResult.keys().reduce((acc, path) => {
    const name = path.match(/AdsType\d*/)[0];
    acc[name] = requireResult(path).default;
    return acc;
  }, {});
};

/**
 * @description 給 AdsmartUIWrapper.vue 使用的模組
 */

export default requireAll(requireResult);
