<template>
  <div class="c-main--shape-mobile">
    <div class="card">
      <div class="card__header">{{ material.contents.title.element }}</div>
      <div class="card__body">{{ material.contents.subtitle.element }}</div>
      <div class="card__footer">
        <div>{{ material.contents.content[0].element }}</div>
        <div>{{ material.contents.content[1].element }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CMainShapeMobile",
  components: {},
  props: {
    material: {
      type: Object,
      default() {
        return {
          contents: {
            title: {},
            subtitle: {},
            content: [{}, {}]
          }
        };
      }
    }
  },
  mounted() {
    this.$emit("ads:entry");
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
@import "~scss/layout/c-main.scss";

.c-main--shape-mobile {
  @include useCMainLayout(104, mobile);
}
</style>
