<template>
  <component
    :is="layoutName"
    :materials="materials"
    @ads:entry="onAdsEntry"
    @ads:view="onAdsView"
    @ads:click="onAdsClick"
  >
  </component>
</template>

<script>
import PcCMain from "./layout/PcCMain.vue";
import CMainShapePc from "./layout/CMainShapePc.vue";
import { LAYOUT_NAME } from "../../../adsmart-ui/settings/boards/type9";

export default {
  name: "AdsType9",
  components: {
    [LAYOUT_NAME.PC_C_MAIN]: PcCMain,
    [LAYOUT_NAME.C_MAIN_SHAPE_PC]: CMainShapePc
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  mounted() {},
  methods: {
    onAdsEntry(materialIndex) {
      this.$emit("ads:entry", materialIndex);
    },
    onAdsView(materialIndex) {
      this.$emit("ads:view", materialIndex);
    },
    onAdsClick(linkHref, materialIndex) {
      this.$emit("ads:click", linkHref, materialIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
