<template>
  <div
    class="ads-type-25"
    @mouseover="stopAutoPlay"
    @mouseleave="startAutoPlay"
  >
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div
          v-for="(material, index) of materials"
          :key="`slide-${index}`"
          class="swiper-slide"
        >
          <PreventLink :href="material.contents.bannerImage.link">
            <img :src="material.contents.bannerImage.element" />
          </PreventLink>
        </div>
      </div>
      <template v-if="isControllerShow">
        <div ref="swiper-button-prev" class="swiper-button-prev">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-left-gray.png"
          />
        </div>
        <div ref="swiper-button-next" class="swiper-button-next">
          <img
            src="https://www.104.com.tw/jobs/main/static/img/icon-next-right-gray.png"
          />
        </div>
      </template>

      <div class="mockup">
        <img src="@/assets/adType25/appMockup.svg" />
      </div>
    </div>
    <div
      v-if="isControllerShow"
      ref="swiper-pagination"
      class="swiper-pagination"
    />
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "AdsType25",
  components: {
    PreventLink
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {
    isMultiple() {
      return this.materials.length > 1;
    },
    isControllerShow() {
      return this.isMultiple;
    }
  },
  mounted() {
    const vm = this;
    if (vm.isMultiple) {
      vm.swiper = new Swiper(this.$refs.swiper, {
        modules: [Autoplay, Navigation, Pagination],
        observer: true,
        allowTouchMove: false,
        preventClicks: true,
        loop: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        pagination: {
          el: this.$refs["swiper-pagination"],
          clickable: true
        },
        navigation: {
          nextEl: this.$refs["swiper-button-next"],
          prevEl: this.$refs["swiper-button-prev"]
        }
      });
      if (!vm.swiper.height) vm.swiper.autoplay.stop();
    }

    this.$emit("ads:entry", 0);
  },
  methods: {
    startAutoPlay() {
      const { swiper } = this;
      swiper.autoplay.start();
    },
    stopAutoPlay() {
      const { swiper } = this;
      swiper.autoplay.stop();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";
@import "node_modules/swiper/swiper.scss";

.swiper {
  position: relative;
  cursor: pointer;
  z-index: 0;
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
  }
}

.mockup {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  img {
    display: block;
    width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 16px;
  height: 16px;
  margin: 0;
  outline: 0;
  cursor: pointer;
  transform: translateY(-50%);
  > img {
    display: block;
  }
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}

.swiper-pagination {
  height: 24px;
  line-height: 24px;
  text-align: center;
}

::v-deep .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 100%;
  background: #dddddd;
  opacity: 1;
  outline: none;
  &-active {
    background: #ff7800;
  }
}
</style>
