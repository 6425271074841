/**
 * @interface
 * @name IAdsmartUIWrapper
 * @description
 * Interface for AdsmartUIWrapper.vue 可以查看 prop 跟 method 的說明，
 * 與 AdsmartNode 的關係
 */

export default {
  props: {
    adsBoardIdentify: {
      /**
       * @description 用來識別是哪個版位的識別字串，必填
       */
      type: String,
      required: true
    },
    adsCustno: {
      /**
       * @description 只有 type29 mode costno 使用
       */
      type: String,
      default: ""
    },
    adsKeywordInput: {
      /**
       * @description 只有 type7 mode keyword 使用
       */
      type: String,
      default: ""
    },
    isEntry: {
      /**
       * @description mode entry 使用，用來判斷是否要執行 entry 的曝光
       */
      type: Boolean,
      default: false
    },
    adsNode: {
      /**
       * @description 主要跟 AdsmartUI 實體互動的狀態接口，以下兩種初始化說明：
       * 1. 初始有值代表是動態版位，AdsmartUI 已建立。
       * 2. 初始沒值代表是靜態版位，AdsmartUI 未建立，會在實作後才會傳入。
       */
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      io: null,
      isIntersecting: false,
      entryCount: 0,
      currentMaterialIds: []
    };
  },
  computed: {
    /**
     * @description 取得 adsNode 元件需要使用到的屬性
     */
    isShow() {
      return this.adsNode?.isShow;
    },
    materials() {
      return this.isShow ? this.adsNode?.materials : [];
    },
    pageLayout() {
      return this.isShow ? this.adsNode?.pageLayout : "";
    },
    adsLayout() {
      return this.isShow ? this.adsNode?.style : "";
    },
    boardConfig() {
      return this.isShow ? this.adsNode?.boardConfig : {};
    },
    layoutName() {
      return this.boardConfig?.meta?.layout ?? "";
    }
  },
  mounted() {
    if (window.adsmartUI) {
      window.adsmartUI.register(this.$root);
    }
  },
  destroyed() {
    if (this.io) {
      this.io.disconnect();
    }
  },
  methods: {
    getCustomElement() {
      return this.$el?.parentNode?.host ?? null;
    },
    adsOnHide() {
      /**
       * @description 以下幾點跟外部互動相關：
       * 1. type7 mode keyword 使用，用來通知媒體端廣告已隱藏
       * 2. 移除 class adsmart-ui--mounted 媒體端會判斷廣告隱藏
       */
      if (this.getCustomElement()) {
        this.getCustomElement().classList.remove("adsmart-ui--mounted");
      }
      this.$emit("ads:hide");
    },
    adsNodeHandlerForAdsOnEntry(materialIndex, isFirst) {
      /**
       * @description 以下幾點跟外部互動相關：
       * 1. type7 mode keyword 使用，用來通知媒體端廣告已曝光
       * 2. 加上 class adsmart-ui--mounted 媒體端會判斷廣告渲染完成
       * 3. 通知 AdsmartNode 執行 entry 曝光，或是通知 AdsmartNode 執行 view 曝光
       */
      if (this.getCustomElement()) {
        this.getCustomElement().classList.add("adsmart-ui--mounted");
      }
      if (this.isEntry && isFirst) {
        // 只有首次出現在畫面上並且渲染完成才會執行 entry 曝光
        // 並且在畫面中的版位才會真的打曝光，畫面外的會打 []
        // ps. 已經渲染完成的元件再次出現時執行 view 曝光
        const entryMaterials = this.isIntersecting ? materialIndex : [];
        this.adsNode.entry(entryMaterials);
      } else {
        this.adsOnView(materialIndex);
      }
      this.$emit("ads:entry");
    },
    createIntersectionObserver() {
      return new IntersectionObserver(entries => {
        for (let entry of entries) {
          this.entryCount++;
          this.isIntersecting = entry.isIntersecting; // for adsOnView
          this.adsNodeHandlerForAdsOnEntry(
            this.currentMaterialIds,
            this.entryCount === 1
          );
        }
      });
    },
    adsOnEntry(materialIndex) {
      this.currentMaterialIds = materialIndex;
      this.$nextTick(() => {
        const ce = this.getCustomElement();
        if (this.io) {
          // 當 shape 變更時，重渲染之後，需要再次初始化 IntersectionObserver
          this.entryCount = 0;
          this.isIntersecting = false;
          this.io.disconnect();
        }
        this.io = this.createIntersectionObserver();
        this.io.observe(ce);
      });
    },
    adsOnView(materialIndex) {
      /**
       * @description 通知 AdsmartNode 執行 view 曝光
       */
      this.currentMaterialIds = materialIndex;
      if (this.isIntersecting) {
        this.adsNode.view(materialIndex);
      }
    },
    adsOnClick(linkHref, materialIndex) {
      /**
       * @description 通知 AdsmartNode 執行 click 曝光
       */
      this.adsNode.click(linkHref, materialIndex);
    }
  },
  render(h) {
    const AdsTypeComponent =
      this.$options.components[`AdsType${this.adsNode.typeId}`];
    if (!AdsTypeComponent || !this.isShow) {
      return null;
    } else {
      return h(AdsTypeComponent, {
        props: {
          "ads-board-identify": this.adsBoardIdentify,
          materials: this.materials,
          "page-layout": this.pageLayout,
          "ads-layout": this.adsLayout,
          "ads-keyword-input": this.adsKeywordInput,
          "ads-custno": this.adsCustno,
          "layout-name": this.layoutName
        },
        on: {
          "ads:click": this.adsOnClick,
          "ads:view": this.adsOnView,
          "ads:entry": this.adsOnEntry,
          "ads:hide": this.adsOnHide
        }
      });
    }
  }
};
