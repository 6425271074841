<template>
  <a
    v-if="isMatched"
    :href="matchedMaterial.contents.textContent.linkWithJobsource"
    @click.prevent="onAdsClick(matchedMaterial.contents.textContent.link)"
    @contextmenu.prevent
  >
    <component :is="layoutName" :material="matchedMaterial">
      {{ matchedMaterial.contents.textContent.element }}
    </component>
  </a>
</template>

<script>
import IndustryEventShapePC from "./layout/IndustryEventShapePC.vue";
import IndustryEventShapeMobile from "./layout/IndustryEventShapeMobile.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type107.js";

export default {
  name: "AdsType108",
  components: {
    [LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_PC]: IndustryEventShapePC,
    [LAYOUT_NAME.INDUSTRY_EVENT_SHAPE_MOBILE]: IndustryEventShapeMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    },
    adsCustno: {
      type: String,
      default: ""
    }
  },
  computed: {
    matchedMaterialIndex() {
      return this.materials.findIndex(material => {
        return material.contents.custno.element === this.adsCustno;
      });
    },
    matchedMaterial() {
      return this.materials[this.matchedMaterialIndex];
    },
    isMatched() {
      return this.matchedMaterialIndex > -1;
    }
  },
  watch: {
    matchedMaterialIndex: {
      handler(value) {
        if (value > -1) {
          this.onAdsEntry(value);
        }
      },
      immediate: true
    }
  },
  methods: {
    onAdsClick(linkHref) {
      this.$emit("ads:click", linkHref, this.matchedMaterialIndex);
    },
    onAdsEntry(index) {
      this.$emit("ads:entry", index);
    }
  }
};
</script>
