<template>
  <div class="ads-type-106">
    <a
      class="ads-type-106__left"
      :href="material.contents.companyLogo.linkWithJobsource"
      :title="material.contents.companyName.element"
      @click.prevent="$emit('ads:click')"
      @contextmenu.prevent
    >
      <img
        class="ads-type-106__left__logo"
        :src="material.contents.companyLogo.element"
      />
    </a>
    <div class="ads-type-106__middle">
      <div class="ads-type-106__middle__header">
        <a
          class="ads-type-106__middle__header__title"
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="material.contents.companyName.element"
          @click.prevent="$emit('ads:click')"
          @contextmenu.prevent
        >
          {{ material.contents.companyName.element }}
        </a>
        <a
          v-if="hasEventTag"
          class="ads-type-106__middle__header__tag"
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="`看更多「${material.contents.industryTag.element}」公司與工作`"
          @click.prevent="$emit('ads:click')"
          @contextmenu.prevent
        >
          {{ material.contents.industryTag.element }}
        </a>
      </div>
      <div class="ads-type-106__middle__body">
        <div class="ads-type-106__middle__body__info">
          <span>{{ material.contents.jobLocation.element }}</span
          ><span>{{ material.contents.industryDes.element }}</span>
        </div>
        <div class="ads-type-106__middle__body__description">
          {{ material.contents.textContent.element }}
        </div>
      </div>

      <div class="ads-type-106__middle__footer">
        <span
          v-for="(welfareTag, index) of material.contents.welfareTags"
          :key="'tag' + index"
          class="ads-type-106__middle__footer__tag"
          >{{ welfareTag.element }}</span
        >
      </div>
    </div>
    <div v-if="hasTailBtn" class="ads-type-106__right">
      <a
        :href="material.contents.companyLogo.linkWithJobsource"
        :title="material.contents.companyName.element"
        class="ads-type-106__right__more"
        @click.prevent="$emit('ads:click')"
        @contextmenu.prevent
      >
        了解更多
      </a>
    </div>
    <div class="ads-type-106__banner">
      {{ material.contents.eventBanner.element }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    material: {
      type: Object,
      default() {
        return {
          contents: {
            companyLogo: {},
            companyName: {},
            industryTag: {},
            jobLocation: {},
            industryDes: {},
            textContent: {},
            welfareTags: []
          }
        };
      }
    },
    adsLayout: {
      type: String,
      default: ""
    },
    hasEventTag: {
      type: Boolean,
      default: false
    },
    hasTailBtn: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.onAdsEntry();
  },
  methods: {
    onAdsClick() {
      this.$emit("ads:click");
    },
    onAdsEntry() {
      this.$emit("ads:entry");
    }
  }
};
</script>
