import axios from "axios";

/**
 * axios instance
 */
const instance = axios.create({
  baseURL:
    process.env.VUE_APP_ENV === "development"
      ? "/api"
      : `https:${process.env.VUE_APP_AIDMA_API}`,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
  crossDomain: true,
  timeout: 20000
});

const errorHandle = (status, message) => {
  switch (status) {
    case 400:
      break;
    case 401:
      break;
    case 403:
      break;
    case 404:
      break;
    case 500:
      break;
    case 503:
      break;
    default:
      console.log(`response 沒有攔截到的錯誤：${message}}`);
  }
};

/**
 * request interceptors
 */
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * response interceptors
 */
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;

    if (response) {
      errorHandle(response.status, response.data.error);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

/**
 * 封裝請求
 * @param {*} method
 * @param {*} url
 * @param {*} data
 */
export default function (method, url, data = null) {
  method = method.toLowerCase();
  switch (method) {
    case "get":
      return instance.get(url, { params: data });
    default:
      console.log(`未知的 method: ${method}`);
      return false;
  }
}
