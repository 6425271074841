<template lang="html">
  <div class="ads-type-26">
    <div class="ads-type-26__text">
      <div class="ads-type-26__text__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="ads-type-26__text__content">
        {{ material.contents.centerContent.element }}
      </div>
    </div>
    <div class="ads-type-26__label">
      {{ material.contents.rightContent.element }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsType26",
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    material() {
      return this.materials[0];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.ads-type-26 {
  display: flex;
  align-items: start;
  padding: 12px 16px;

  &__text {
    flex-grow: 1;
    overflow: hidden;

    &__title {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__content {
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #7e7e7e;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__label {
    margin-left: 8px;
    padding: 1px 3px;
    border: 1px solid #92cf53;
    border-radius: 4px;
    font-size: 10px;
    color: #92cf53;
  }
}
</style>
