import AdsmartHref from "./AdsmartHref.js";

export default class AdsmartGeneralNode {
  /* 給 vue 元件使用的開放介面 */
  constructor(vm, adsmartBoard) {
    this.vm = vm;
    this.adsmartBoard = adsmartBoard;
    this.adsmartMaterials = adsmartBoard.adsmartMaterials;
    this.vm.props.isEntry = this.adsmartBoard.isEntry;
    this.vm.props.adsNode = this;
  }

  get typeId() {
    return this.adsmartBoard.typeId;
  }
  get style() {
    return this.adsmartBoard.style;
  }
  get boardIdentify() {
    return this.adsmartBoard.boardIdentify;
  }
  get pageLayout() {
    return this.adsmartBoard.pageLayout;
  }
  get boardConfig() {
    return this.adsmartBoard.boardConfig;
  }
  get materials() {
    return this.adsmartMaterials;
  }
  get isShow() {
    return this.adsmartMaterials && this.adsmartMaterials.length > 0;
  }

  entry(mIndex) {
    const shelfIdArray = this.getShelfIdArray(mIndex);
    this.adsmartBoard.entry(shelfIdArray);
  }

  view(mIndex) {
    const shelfIdArray = this.getShelfIdArray(mIndex);
    this.adsmartBoard.view(shelfIdArray);
  }

  click(linkHref, materialIndex, AdsmartHrefCreator = AdsmartHref) {
    const { shelfId, jobsource } = this.adsmartMaterials[materialIndex];
    if (shelfId) {
      this.adsmartBoard.click(shelfId);
      this.adsmartBoard.addNCCLog(shelfId, jobsource);
    }
    new AdsmartHrefCreator(linkHref, jobsource).goNewPage(); // _redirectPage
  }

  destroy() {}

  getShelfId(index) {
    try {
      const shelfId = this.materials?.[index]?.shelfId;
      if (!shelfId) throw new Error(`error materialIndex: ${index}`);
      return shelfId;
    } catch (error) {
      console.error(error);
    }
  }

  getShelfIdArray(payload) {
    if (typeof payload === "number") {
      const mIndex = payload;
      return [this.getShelfId(mIndex)];
    } else if (Array.isArray(payload)) {
      const mIndexArray = payload;
      return mIndexArray.map(mIndex => {
        return this.getShelfId(mIndex);
      });
    }
  }
}
