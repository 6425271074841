import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  INDUSTRY_EVENT_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  industryEventShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = INDUSTRY_EVENT_LAYOUT_NAME;

const commonConfig = new BoardConfig({
  // 產業特輯 卡片
  page: PAGE_ENUM.RWD__INDUSTRY_EVENT,
  meta: {
    compose: {
      shapeGroupNo: deviceShapeGroupNoSetting,
      shapeLayout: industryEventShapeLayoutSetting
    }
  },
  preview: {
    isWidthFill: true,
    compose: {
      container: {
        [DEVICE_SHAPE_ENUM.PC]: {
          width: 204
        },
        [DEVICE_SHAPE_ENUM.MOBILE]: {
          width: 175
        }
      }
    }
  }
});

export default {
  // 零售服務業_卡片
  ["pc_c_industry_retailservice_card"]: commonConfig,
  // 顧問金融業_卡片
  ["pc_c_industry_consultingfinance_card"]: commonConfig,
  // 工程製造業_卡片
  ["pc_c_industry_engineeringmanufacturing_card"]: commonConfig,
  // 科技研發業_卡片
  ["pc_c_industry_technologyrd_card"]: commonConfig,
  // 半導體業_列表
  ["pc_c_industry_semiconductor_card"]: commonConfig,
  // 住宿旅遊_列表(民生)
  ["pc_c_industry_travelaccommodation_card"]: commonConfig,
  // 餐飲門市_列表(民生)
  ["pc_c_industry_cateringstore_card"]: commonConfig,
  // 百貨零售_列表(民生)
  ["pc_c_industry_departmentstoreretail_card"]: commonConfig,
  // 其他服務_列表(民生)
  ["pc_c_industry_otherservice_card"]: commonConfig
};
