<template>
  <a
    :href="seoHref"
    target="_blank"
    @click.prevent="adsOnClick"
    @contextmenu.prevent
  >
    <component
      :is="layoutName"
      :material="materials[0]"
      @ads:entry="onAdsEntry"
    ></component>
  </a>
</template>

<script>
import CMainShapePc from "./layout/CMainShapePc.vue";
import CMainShapeMobile from "./layout/CMainShapeMobile.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type104.js";

export default {
  name: "AdsType104",
  components: {
    [LAYOUT_NAME.C_MAIN_SHAPE_PC]: CMainShapePc,
    [LAYOUT_NAME.C_MAIN_SHAPE_MOBILE]: CMainShapeMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  computed: {
    seoHref() {
      return this.materials[0].contents.title.link;
    }
  },
  methods: {
    adsOnClick() {
      const href = this.materials[0].contents.title.link;
      this.$emit("ads:click", href, 0);
    },
    onAdsEntry() {
      this.$emit("ads:entry", 0);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
