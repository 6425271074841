<!-- eslint-disable vue/no-v-html -->
<template>
  <a
    v-if="matchedMaterialIndex > -1"
    :href="bannerLinkWithJobsource"
    target="_blank"
    :gtm-data-ac="keywordElement"
    @click.prevent="adsOnClick"
    @contextmenu.prevent
  >
    <component :is="layoutName" v-html="bannerInnerHTML" />
  </a>
</template>

<script>
import CMainShapePc from "./layout/CMainShapePc.vue";
import CMainShapeMobile from "./layout/CMainShapeMobile.vue";
import SearchBarPc from "./layout/SearchBarPc.vue";
import SearchBarMobile from "./layout/SearchBarMobile.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type100.js";

export default {
  name: "AdsType101",
  components: {
    [LAYOUT_NAME.C_MAIN_SHAPE_PC]: CMainShapePc,
    [LAYOUT_NAME.C_MAIN_SHAPE_MOBILE]: CMainShapeMobile,
    [LAYOUT_NAME.SEARCH_BAR_PC]: SearchBarPc,
    [LAYOUT_NAME.SEARCH_BAR_MOBILE]: SearchBarMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    adsKeywordInput: {
      type: String,
      default: ""
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    matchedMaterialIndex() {
      if (!this.adsKeywordInput || !this.materials.length) return -1;
      return this.materials.findIndex(material => {
        return this.adsKeywordInput.includes(material.contents.keyword.element);
      });
    },
    bannerElement() {
      return this.materials[this.matchedMaterialIndex]?.contents?.bannerText
        ?.element;
    },
    bannerLink() {
      return this.materials[this.matchedMaterialIndex]?.contents?.bannerText
        ?.link;
    },
    bannerLinkWithJobsource() {
      return this.materials[this.matchedMaterialIndex]?.contents?.bannerText
        ?.linkWithJobsource;
    },
    keywordElement() {
      return this.materials[this.matchedMaterialIndex]?.contents?.keyword
        ?.element;
    },
    bannerInnerHTML() {
      const strong = `<strong>${this.keywordElement}</strong>`;
      return this.bannerElement?.replace(this.keywordElement, strong);
    }
  },
  watch: {
    layoutName() {
      if (this.matchedMaterialIndex > -1) {
        this.$emit("ads:entry", this.matchedMaterialIndex);
      }
    },
    matchedMaterialIndex: {
      handler(value) {
        if (value > -1) {
          this.$emit("ads:entry", value);
        } else {
          this.$emit("ads:hide", 0);
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    adsOnClick() {
      this.$emit("ads:click", this.bannerLink, this.matchedMaterialIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
