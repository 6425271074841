import PAGE_ENUM from "../enum/page.js";
import { BoardConfig } from "./BoardConfig.js";

export default {
  ["7f390a804f4a7c69fcd778e6b0f402a7"]: new BoardConfig({
    // 列表P鑽
    page: PAGE_ENUM.PC__JOB_LIST,
    preview: { containerWidth: 800 }
  }),
  ["04d31d068760b80c6f3b95fd98582a87"]: new BoardConfig({
    // 鑽石橫幅
    page: PAGE_ENUM.PC__MY_104,
    preview: { containerWidth: 716 }
  }),
  ["50a9405678362ba06ea89936de270519"]: new BoardConfig({
    // 職缺P鑽
    page: PAGE_ENUM.PC__JOB_PAGE,
    preview: { containerWidth: 716 }
  })
};
