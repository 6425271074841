<template>
  <span class="pc-b-vip">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PcBVip"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";

.pc-b-vip {
  font-size: 16px;
  line-height: 24px;
  color: #1654b9;
  &:hover {
    color: #4e91ff;
  }
  &:visited {
    color: #78269f;
  }
}
</style>
