import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  C_MAIN_SHAPE_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  cMainShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = {
  ...C_MAIN_SHAPE_LAYOUT_NAME,
  SEARCH_BAR_PC: "SearchBarPc",
  SEARCH_BAR_MOBILE: "SearchBarMobile"
};

export default {
  ["pc_c_index_keywords_job"]: new BoardConfig({
    page: PAGE_ENUM.RWD__C_MAIN,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: cMainShapeLayoutSetting
      }
    }
  }),
  ["pc_c_companylist_keywords_job"]: new BoardConfig({
    page: PAGE_ENUM.RWD__COMPANY_LIST,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: {
          [DEVICE_SHAPE_ENUM.PC]: LAYOUT_NAME.SEARCH_BAR_PC,
          [DEVICE_SHAPE_ENUM.MOBILE]: LAYOUT_NAME.SEARCH_BAR_MOBILE
        }
      }
    }
  })
};
