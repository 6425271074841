<script>
import AdsType1 from "../../AdsType1.vue";
export default {
  extends: AdsType1,
  data() {
    return {
      style: {
        maxWidth: "400px",
        maxHeight: "60px"
      }
    };
  }
};
</script>
