<template>
  <span class="app-search-bar">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AppSearchBar"
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-search-bar {
  display: block;
  width: 100%;
  padding: 0 20px 0 12px;
  font-size: 14px;
  line-height: 20px;
  color: #39c8d0;
}
</style>
