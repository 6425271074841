import AdsmartContent from "./AdsmartContent";
import AdsmartTypeConfig from "./AdsmartTypeConfig";

export class AdsmartContents {
  constructor(material, typeConfig) {
    material.contents.forEach(content => {
      const name = typeConfig.getContentName(content);
      if (name) {
        const adsmartContent = new AdsmartContent(content, material.jobsource);
        this._addContent(name, adsmartContent);
      }
    });
  }
  _addContent(name, adsmartContent) {
    if (this[name] === undefined) {
      this[name] = adsmartContent;
    } else if (this[name] instanceof AdsmartContent) {
      this[name] = [this[name], adsmartContent];
    } else if (Array.isArray(this[name])) {
      this[name] = [...this[name], adsmartContent];
    }
  }
}

export default class AdsmartMaterial {
  constructor(material = { shelfId: 0, jobsource: "", contents: [] }, typeId) {
    this.shelfId = material.shelfId;
    this.jobsource = material.jobsource;
    this.typeConfig = this.createAdsmartTypeConfig(typeId);
    this.contents = new AdsmartContents(material, this.typeConfig);
  }
  createAdsmartTypeConfig(typeId, AdsmartTypeConfigClass = AdsmartTypeConfig) {
    return new AdsmartTypeConfigClass(typeId);
  }
}
