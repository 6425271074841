<template>
  <a
    :href="bannerLinkWithJobsource"
    target="_blank"
    @click.prevent="adsOnClick"
    @contextmenu.prevent
  >
    <component :is="layoutName" v-text="bannerElement" />
  </a>
</template>

<script>
import CMainShapePc from "./layout/CMainShapePc.vue";
import CMainShapeMobile from "./layout/CMainShapeMobile.vue";
import SearchBarPc from "./layout/SearchBarPc.vue";
import SearchBarMobile from "./layout/SearchBarMobile.vue";
import CompanyList from "./layout/CompanyList.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type100.js";

export default {
  name: "AdsType100",
  components: {
    [LAYOUT_NAME.C_MAIN_SHAPE_PC]: CMainShapePc,
    [LAYOUT_NAME.C_MAIN_SHAPE_MOBILE]: CMainShapeMobile,
    [LAYOUT_NAME.SEARCH_BAR_PC]: SearchBarPc,
    [LAYOUT_NAME.SEARCH_BAR_MOBILE]: SearchBarMobile,
    [LAYOUT_NAME.COMPANY_LIST_PC]: CompanyList,
    [LAYOUT_NAME.COMPANY_LIST_MOBILE]: CompanyList
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    bannerElement() {
      return this.materials[this.currentIndex]?.contents?.bannerText?.element;
    },
    bannerLink() {
      return this.materials[this.currentIndex]?.contents?.bannerText?.link;
    },
    bannerLinkWithJobsource() {
      return this.materials[this.currentIndex]?.contents?.bannerText
        ?.linkWithJobsource;
    }
  },
  watch: {
    layoutName: {
      handler() {
        this.adsOnEntry();
      },
      immediate: true
    }
  },
  methods: {
    adsOnClick() {
      this.$emit("ads:click", this.bannerLink, this.currentIndex);
    },
    adsOnEntry() {
      this.$emit("ads:entry", this.currentIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
