<template>
  <div class="c-main-slide-shape-pc">
    <div class="slide">
      <div class="slide__aside">
        <img
          class="slide__aside__image"
          :src="material.contents.sideImg.element"
          alt=""
        />
      </div>
      <div class="slide__main card">
        <div class="card__header">{{ material.contents.title.element }}</div>
        <div class="card__body">
          <div class="card__body__description">
            {{ material.contents.description.element }}
          </div>
          <ul class="card__body__list">
            <li
              v-for="(item, i) of material.contents.list"
              :key="i"
              class="card__body__list__item"
            >
              <span>{{ item.element }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CMainSlideShapePc",
  props: {
    material: {
      type: Object,
      default() {
        return {
          contents: {
            sideImg: {},
            title: {},
            description: {},
            list: [{}, {}, {}]
          }
        };
      }
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
@import "~scss/layout/c-main.scss";

.c-main-slide-shape-pc {
  @include useCMainLayout(103, pc, slide);
}
</style>
