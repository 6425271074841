<template>
  <section class="signboard" :class="{ active }">
    <div class="signboard__line"></div>

    <div
      class="cursor-pointer"
      @click="onClick(material.contents.imageSm.link)"
    >
      <PreventLink :href="material.contents.imageSm.linkWithJobsource">
        <img
          class="signboard__img"
          :src="material.contents.imageSm.element"
          :alt="material.contents.bodyTitle.element"
        />
      </PreventLink>
    </div>

    <div class="signboard__body">
      <h5
        class="signboard__body__title cursor-pointer"
        @click="onClick(material.contents.bodyTitle.link)"
      >
        <PreventLink
          :href="material.contents.bodyTitle.linkWithJobsource"
          v-text="material.contents.bodyTitle.element"
        />
      </h5>
      <p
        class="signboard__body__text cursor-pointer"
        @click="onClick(material.contents.bodyContent.link)"
      >
        <PreventLink
          :href="material.contents.bodyContent.linkWithJobsource"
          v-text="material.contents.bodyContent.element"
        />
      </p>
      <ul class="list-unstyled list-spec-style signboard__body__job-list">
        <li
          v-for="(listItem, index) of material.contents.bodyList"
          :key="'list' + index"
          class="cursor-pointer"
          @click="onClick(listItem.link)"
        >
          <PreventLink
            :href="listItem.linkWithJobsource"
            v-text="listItem.element"
          />
        </li>
      </ul>
      <div
        class="signboard__body__job-list-more cursor-pointer"
        @click="onClick(material.contents.moreButton.link)"
      >
        <PreventLink
          :href="material.contents.moreButton.linkWithJobsource"
          v-text="material.contents.moreButton.element"
        />
      </div>
    </div>

    <div class="signboard__divider"></div>
    <div class="signboard__footer">
      {{ material.contents.footerLeft.element }}‧{{
        material.contents.footerRight.element
      }}
    </div>
    <div class="signboard__job-list-ad">
      <img :src="material.contents.imageMd.element" />
    </div>
  </section>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";

export default {
  name: "SubSlider",
  components: {
    PreventLink
  },
  props: {
    material: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  methods: {
    onClick(linkHref) {
      this.$emit("ads:click", linkHref);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.signboard {
  position: relative;
  height: 200px;
  border: none;
  border-radius: 0;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);

  &__line {
    height: 5px;
    background-color: #f3f3f3;
  }

  &__img {
    display: block;
    width: auto;
    height: 44px;
    margin: 15px auto 16px auto;
  }

  &__body {
    padding: 0 16px;
    margin-bottom: 12px;

    &__title {
      height: 21px;
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      text-align: center;

      &:hover a {
        color: #ff7800;
      }

      a {
        color: #292929;
      }
    }

    &__text {
      height: 38px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;

      &:hover a {
        color: #ff7800;
      }

      a {
        color: #292929;
      }
    }

    &__job-list {
      display: none;
      height: 108px;
      padding-top: 10px;
      padding-bottom: 6px;

      li {
        line-height: 19px;
        padding: 2px 0;

        &::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 4px;
          margin-bottom: 1.5px;
          border: 2px solid #a9a9a9;
        }

        &:hover a {
          color: #4e91ff;
        }

        > a {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: #1653b9;
        }
      }
    }

    &__job-list-more {
      display: none;
      height: 17px;
      font-size: 12px;
      line-height: 15px;
      text-align: right;

      a {
        color: #ff7800;
      }
    }
  }

  &__divider {
    height: 1px;
    margin: 0 20px;
    background-color: #eeeeee;
  }

  &__footer {
    padding: 12px 20px 0 20px;
    border-top: none;
    font-size: 12px;
    line-height: 16px;
    color: #7e7e7e;
    background-color: #fff;
  }

  &__job-list-ad {
    display: none;
    height: 80px;
    margin-top: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &.active {
    height: 100%;

    .signboard__line {
      background-color: #ff7800;
    }
    .signboard__body__job-list,
    .signboard__body__job-list-more,
    .signboard__job-list-ad {
      display: block;
    }
  }
}
</style>
