<template>
  <component
    :is="layoutName"
    :materials="materials"
    @ads:click="adsOnClick"
    @ads:view="adsOnView"
    @ads:entry="adsOnEntry"
  ></component>
</template>

<script>
import CMainShapePc from "./layout/CMainShapePc.vue";
import CMainShapeMobile from "./layout/CMainShapeMobile.vue";
import CompanyListTopBannerPc from "./layout/CompanyListTopBannerPc.vue";
import CompanyListTopBannerMobile from "./layout/CompanyListTopBannerMobile.vue";
import CompanyListSkyscraperPc from "./layout/CompanyListSkyscraperPc.vue";
import CompanyListSkyscraperMobile from "./layout/CompanyListSkyscraperMobile.vue";
import FreshmenTopBannerPc from "./layout/FreshmenTopBannerPc.vue";
import FreshmenTopBannerMobile from "./layout/FreshmenTopBannerMobile.vue";
import TransferTopBannerPc from "./layout/TransferTopBannerPc.vue";
import TransferTopBannerMobile from "./layout/TransferTopBannerMobile.vue";
import { LAYOUT_NAME } from "@/adsmart-ui/settings/boards/type102.js";

export default {
  name: "AdsType102",
  components: {
    [LAYOUT_NAME.C_MAIN_SHAPE_PC]: CMainShapePc,
    [LAYOUT_NAME.C_MAIN_SHAPE_MOBILE]: CMainShapeMobile,
    [LAYOUT_NAME.COMPANY_LIST_TOP_BANNER_PC]: CompanyListTopBannerPc,
    [LAYOUT_NAME.COMPANY_LIST_TOP_BANNER_MOBILE]: CompanyListTopBannerMobile,
    [LAYOUT_NAME.COMPANY_LIST_SKYSCRAPER_PC]: CompanyListSkyscraperPc,
    [LAYOUT_NAME.COMPANY_LIST_SKYSCRAPER_MOBILE]: CompanyListSkyscraperMobile,
    [LAYOUT_NAME.FRESHMEN_TOP_BANNER_PC]: FreshmenTopBannerPc,
    [LAYOUT_NAME.FRESHMEN_TOP_BANNER_MOBILE]: FreshmenTopBannerMobile,
    [LAYOUT_NAME.TRANSFER_TOP_BANNER_PC]: TransferTopBannerPc,
    [LAYOUT_NAME.TRANSFER_TOP_BANNER_MOBILE]: TransferTopBannerMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    },
    layoutName: {
      type: String,
      default: ""
    }
  },
  methods: {
    adsOnClick(link, index) {
      this.$emit("ads:click", link, index);
    },
    adsOnView(mIndex) {
      this.$emit("ads:view", mIndex);
    },
    adsOnEntry(mIndex) {
      this.$emit("ads:entry", mIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
</style>
