<script>
import IAdsmartUIWrapper from "./IAdsmartUIWrapper";
import AdsTypeModules from "../components/adsTypes/AdsTypeModules";

/*
  雷點： IAdsmartUIWrapper，不能使用 extends 或是 mixins 的方式來繼承，
        會讓 web component 抓不到 props 的設定，導致無法正常運作
 */

export default {
  name: "AdsmartUISwitchWrapper",
  components: AdsTypeModules,
  props: {
    ...IAdsmartUIWrapper.props,
    adsShape: {
      type: String,
      default: "pc"
    }
  },
  data() {
    return {
      ...IAdsmartUIWrapper.data()
    };
  },
  computed: {
    ...IAdsmartUIWrapper.computed,
    materials() {
      const groupNo =
        this.boardConfig?.meta?.compose?.shapeGroupNo[this.adsShape];
      return this.isShow ? this.adsNode?.materialsGroup?.[groupNo] : [];
    },
    layoutName() {
      const shapeLayout = this.boardConfig?.meta?.compose?.shapeLayout;
      if (typeof shapeLayout === "undefined") return "";
      else if (typeof shapeLayout === "string") return shapeLayout;
      else return shapeLayout[this.adsShape];
    }
  },
  mounted() {
    IAdsmartUIWrapper.mounted.call(this);
  },
  destroyed() {
    IAdsmartUIWrapper.destroyed.call(this);
  },
  methods: {
    ...IAdsmartUIWrapper.methods
  },
  render(h) {
    return IAdsmartUIWrapper.render.call(this, h);
  }
};
</script>
