import PAGE_ENUM from "../enum/page.js";
import { DEVICE_SHAPE_ENUM } from "../enum/shape.js";
import {
  C_MAIN_SHAPE_LAYOUT_NAME,
  deviceShapeGroupNoSetting,
  cMainShapeLayoutSetting
} from "../composeSettings.js";
import { BoardConfig } from "./BoardConfig.js";

export const LAYOUT_NAME = C_MAIN_SHAPE_LAYOUT_NAME;

export default {
  ["pc_c_index_featured_company"]: new BoardConfig({
    // 複合元件 精選推薦
    page: PAGE_ENUM.RWD__C_MAIN,
    meta: {
      compose: {
        shapeGroupNo: deviceShapeGroupNoSetting,
        shapeLayout: cMainShapeLayoutSetting
      }
    },
    preview: {
      isWidthFill: true,
      compose: {
        container: {
          [DEVICE_SHAPE_ENUM.PC]: {
            width: 546
          },
          [DEVICE_SHAPE_ENUM.MOBILE]: {
            width: 340
          }
        }
      }
    }
  })
};
