<template>
  <div class="job-list">
    <div class="job-list__left">
      <div class="job-list__left__sponsor">贊助</div>
    </div>
    <div class="job-list__center">
      <div class="job-list__center__title">
        {{ material.contents.centerTitle.element }}
      </div>
      <div class="job-list__center__sub-title">
        {{ material.contents.centerSubTitle.element }}
      </div>
      <div class="job-list__center__content">
        {{ material.contents.centerContent[0].element }}
        <br />
        {{ material.contents.centerContent[1].element }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobList",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";

.job-list {
  display: flex;
  padding: 6px 0;

  &__left {
    width: 50px;
    text-align: center;
    align-self: center;

    &__sponsor {
      font-size: 13px;
      line-height: 24px;
      color: #ff6200;
    }
  }

  &__center {
    flex-grow: 1;
    font-size: 13px;
    line-height: 24px;
    overflow: hidden;

    &__title {
      font-size: 18px;
      line-height: 23.94px;
      color: #0057b1;
    }
    &__sub-title {
      padding-top: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__content {
      color: #666666;
    }
  }
}
</style>
