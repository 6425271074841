<template>
  <div class="ads-type-107" :class="eventName">
    <a
      class="ads-type-107__header"
      :href="material.contents.companyLogo.linkWithJobsource"
      :title="material.contents.companyName.element"
      @click.prevent="onAdsClick"
      @contextmenu.prevent
    >
      <img
        class="ads-type-107__header__logo"
        :src="material.contents.companyLogo.element"
      />
      <div class="ads-type-107__header__title">
        {{ material.contents.companyName.element }}
      </div>
    </a>

    <div class="ads-type-107__body">
      <div class="ads-type-107__body__info">
        <div class="ads-type-107__body__info__location">
          <div class="icon">
            <img src="@/assets/adType28/icon_location.svg" />
          </div>
          <div class="text">{{ material.contents.jobLocation.element }}</div>
        </div>

        <div class="ads-type-107__body__info__industry">
          <div class="icon">
            <img src="@/assets/adType28/icon_company_saved.svg" />
          </div>
          <div class="text">{{ material.contents.industryDes.element }}</div>
        </div>
      </div>

      <div class="ads-type-107__body__description">
        <span>{{ material.contents.textContent.element }}</span>
      </div>
    </div>

    <div class="ads-type-107__footer">
      <a
        class="ads-type-107__footer__button"
        :href="material.contents.companyLogo.linkWithJobsource"
        :title="material.contents.companyName.element"
        @click.prevent="onAdsClick"
        @contextmenu.prevent
      >
        了解更多
      </a>
    </div>
    <div class="ads-type-107__banner">
      {{ material.contents.eventBanner.element }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    material: {
      type: Object,
      default: () => ({
        contents: {
          companyLogo: {},
          companyName: {},
          jobLocation: {},
          industryDes: {},
          textContent: {}
        }
      })
    },
    adsLayout: {
      type: String,
      default: ""
    }
  },
  computed: {
    eventName() {
      if (this.adsLayout.includes("semi")) {
        return "event-semi";
      } else if (this.adsLayout.includes("fresh")) {
        return "event-fresh";
      } else if (this.adsLayout.includes("living")) {
        return "event-living";
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.onAdsEntry();
  },
  methods: {
    onAdsClick() {
      this.$emit("ads:click");
    },
    onAdsEntry() {
      this.$emit("ads:entry");
    }
  }
};
</script>
