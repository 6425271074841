<template>
  <div
    ref="type-105-c-main--shape-mobile"
    class="type-105-c-main--shape-mobile"
  >
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <a
          v-for="(material, index) of materials"
          :key="`slide-${index}`"
          :data-material-index="index"
          class="swiper-slide"
          :href="material.contents.headerImage.linkWithJobsource"
          @click.prevent
          @contextmenu.prevent
        >
          <CMainSlideShapeMobile :material="material"></CMainSlideShapeMobile>
        </a>
      </div>
    </div>
    <div ref="swiper-pagination" class="swiper-pagination" />
  </div>
</template>

<script>
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import CMainSlideShapeMobile from "../components/CMainSlideShapeMobile.vue";

export default {
  name: "CMainShapeMobile",
  components: {
    CMainSlideShapeMobile
  },
  props: {
    materials: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {},
  mounted() {
    const vm = this;
    vm.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 2.075,
      slidesPerGroup: 2,
      spaceBetween: 16,
      observer: true,
      preventClicks: true,
      loop: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: this.$refs["swiper-pagination"],
        clickable: true
      },
      navigation: {
        nextEl: this.$refs["swiper-button-next"],
        prevEl: this.$refs["swiper-button-prev"]
      },
      on: {
        click(swiper) {
          swiper.allowClick = false;
          const materialIndex = Number(
            swiper?.clickedSlide?.dataset?.materialIndex
          );
          vm.onAdsClick(materialIndex);
        },
        realIndexChange(swiper) {
          if (!swiper.height) return;
          vm.onAdsView(swiper.realIndex);
        },
        resize(swiper) {
          if (swiper.height && !swiper.autoplay.running) {
            swiper.autoplay.start();
          }
        }
      }
    });
    if (!vm.swiper.height) vm.swiper.autoplay.stop();

    this.onAdsEntry();
  },
  methods: {
    getStagingMaterialIndexList(startIndex) {
      const maxLength = this.materials.length;
      return Array.from({ length: 2 }, (_, i) => {
        const index = i + startIndex;
        return index >= maxLength ? index - maxLength : index;
      });
    },
    onAdsEntry() {
      const getStagingMaterialIndexList = this.getStagingMaterialIndexList(0);
      this.$emit("ads:entry", getStagingMaterialIndexList);
    },
    onAdsView(startMaterialIndex) {
      const getStagingMaterialIndexList =
        this.getStagingMaterialIndexList(startMaterialIndex);
      this.$emit("ads:view", getStagingMaterialIndexList);
    },
    onAdsClick(materialIndex) {
      const material = this.materials[materialIndex];
      this.$emit(
        "ads:click",
        material.contents.headerImage.link,
        materialIndex
      );
    }
  }
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";
@import "node_modules/swiper/swiper.scss";

.type-105-c-main--shape-mobile {
  @include useCMainLayout(105, mobile, wrapper);
}
</style>
