import {
  AdsmartGeneralNode,
  AdsmartComposeNode
} from "../AdsmartNode/index.js";
import AdsmartMaterial from "./AdsmartMaterial.js";
import pageLayoutMap from "../../settings/pageLayout.js";
import boardConfigModules from "../../settings/boards/boardConfigModules.js";

class AdsmartBoardBase {
  #pageLayoutMap;
  #boardConfigMap;

  constructor(
    boardData = {
      typeId: 0,
      style: "",
      boardIdentify: "",
      channelIdentify: "",
      materials: []
    },
    adsmartTracker
  ) {
    this.#pageLayoutMap = this.getPageLayoutMap();
    this.#boardConfigMap = this.getBoardConfigMap();

    this.nodesArray = [];
    // board data props
    this.typeId = boardData.typeId;
    this.style = boardData.style;
    this.boardIdentify = boardData.boardIdentify;
    this._rawMaterials = boardData.materials;
    this.adsmartMaterials = [];
    // reference
    this.pageLayout = this.#pageLayoutMap[boardData.channelIdentify];
    // config
    this.boardConfig = this.#getBoardConfig();
    // tracker
    this.boardData = boardData;
    this.adsmartTracker = adsmartTracker;
    this.isCompose = false;
    this.isEntry = false;
  }

  getPageLayoutMap() {
    return pageLayoutMap;
  }
  getBoardConfigMap() {
    return boardConfigModules;
  }

  #getBoardConfig() {
    return this.#boardConfigMap?.[this.typeId]?.[this.boardIdentify] ?? {};
  }

  get isMaterialsEmpty() {
    return this._rawMaterials.length === 0;
  }

  get isNoAd() {
    return this.isMaterialsEmpty;
  }

  createAdsmartMaterial(rawMaterial, AdsmartMaterialClass = AdsmartMaterial) {
    return new AdsmartMaterialClass(rawMaterial, this.typeId);
  }

  createAdsmartNode(vm, AdsmartNodeClass = AdsmartGeneralNode) {
    return new AdsmartNodeClass(vm, this);
  }

  reactiveNode(vm, rawMaterials = this._rawMaterials) {
    this.adsmartMaterials = rawMaterials.map(material => {
      return this.createAdsmartMaterial(material);
    });
    const node = this.createAdsmartNode(vm);
    this.nodesArray.push(node);
    return node;
  }

  async entry(shelfIdAry, groupNo) {
    await this.adsmartTracker.entry(this.boardIdentify, shelfIdAry, groupNo);
  }

  async view(shelfIdAry, groupNo) {
    await this.adsmartTracker.view(shelfIdAry, groupNo);
  }

  async click(shelfId, groupNo) {
    await this.adsmartTracker.click(shelfId, groupNo);
  }

  addNCCLog(shelfId, jobsource) {
    this.adsmartTracker.addNCCLog(
      this.boardData,
      this.pageLayout,
      shelfId,
      jobsource
    );
  }
}

export class AdsmartBoard extends AdsmartBoardBase {
  constructor(...arg) {
    super(...arg);
  }

  createAdsmartNode(vm, AdsmartNodeClass = AdsmartGeneralNode) {
    return super.createAdsmartNode(vm, AdsmartNodeClass);
  }
}

export class AdsmartBoardEntry extends AdsmartBoard {
  constructor(...arg) {
    super(...arg);
    this.isEntry = true;
  }

  addEntryQueue() {
    this.adsmartTracker.addEntryQueue(this.boardIdentify);
  }

  reactiveNode(vm) {
    this.addEntryQueue();
    return super.reactiveNode(vm);
  }
}

export class AdsmartBoardDynamic extends AdsmartBoard {
  constructor(...arg) {
    super(...arg);
  }

  getNodesHeadMaterial() {
    if (this.nodesArray.length < this._rawMaterials.length) {
      const dynamicCount = this.nodesArray.length;
      return this._rawMaterials[dynamicCount];
    }
  }

  reactiveNode(vm) {
    const headMaterial = this.getNodesHeadMaterial();
    let rawMaterials = headMaterial ? [headMaterial] : [];
    return super.reactiveNode(vm, rawMaterials);
  }
}

export class AdsmartBoardKeyword extends AdsmartBoard {
  constructor(...arg) {
    super(...arg);
    this.keywords = this.getKeywords();
  }

  getKeywords() {
    const result = [];
    this._rawMaterials.forEach(material => {
      material.contents.forEach(content => {
        if (content.sort === 1 && content.typeGroupNo === 1) {
          result.push(content.element);
        }
      });
    });
    return result;
  }
}

export class AdsmartBoardCompose extends AdsmartBoardBase {
  constructor(...arg) {
    super(...arg);
    this.isCompose = true;
  }

  get shapeGroupNoSetting() {
    return this.boardConfig.meta.compose.shapeGroupNo;
  }

  getGroupNo(shape = "") {
    const shapeGroupNoSetting = this.shapeGroupNoSetting;
    return shapeGroupNoSetting[shape] ?? 0;
  }

  createAdsmartNode(vm, AdsmartNodeClass = AdsmartComposeNode) {
    return super.createAdsmartNode(vm, AdsmartNodeClass);
  }
}

export class AdsmartBoardComposeEntry extends AdsmartBoardCompose {
  constructor(...arg) {
    super(...arg);
    this.isCompose = true;
    this.isEntry = true;
  }

  #addEntryQueue(vm) {
    const groupNo = this.getGroupNo(vm.props.adsShape);
    this.adsmartTracker.addEntryQueue(this.boardIdentify, groupNo);
  }

  reactiveNode(vm) {
    this.#addEntryQueue(vm);
    return super.reactiveNode(vm);
  }
}

export class AdsmartBoardComposeDynamic extends AdsmartBoardCompose {
  constructor(...arg) {
    super(...arg);
    this.isCompose = true;
    this.composeNodesMap = new Map();
  }

  #getComposeNodesHeadMaterial(groupNo) {
    const targetNodesArray = this.composeNodesMap.get(groupNo);
    if (targetNodesArray.length < this._rawMaterials.length) {
      const dynamicCount = targetNodesArray.length;
      return this._rawMaterials[dynamicCount];
    }
  }

  reactiveNode(vm) {
    const groupNo = this.getGroupNo(vm.props.adsShape);
    if (!this.composeNodesMap.has(groupNo)) {
      this.composeNodesMap.set(groupNo, []);
    }
    const headMaterial = this.#getComposeNodesHeadMaterial(groupNo);
    let rawMaterials = headMaterial ? [headMaterial] : [];
    const node = super.reactiveNode(vm, rawMaterials);
    this.composeNodesMap.get(groupNo).push(node);
    return node;
  }
}

export class AdsmartBoardComposeKeyword extends AdsmartBoardCompose {
  constructor(...arg) {
    super(...arg);
    this.isCompose = true;
    this.shapeKeywords = {
      pc: this.getKeywords(1),
      mobile: this.getKeywords(2)
    };
  }

  getKeywords(groupId) {
    const result = [];
    this._rawMaterials.forEach(material => {
      material.contents.forEach(content => {
        if (content.sort === 1 && content.typeGroupNo === groupId) {
          result.push(content.element);
        }
      });
    });
    return result;
  }
}
