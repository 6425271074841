export default class Expose {
  #token = "";
  #exposedIdSet = new Set();

  constructor(token) {
    this.#token = token;
  }

  get token() {
    return this.#token;
  }

  addExposedId(shelfId) {
    this.#exposedIdSet.add(shelfId);
  }
  hasExposedId(shelfId) {
    return this.#exposedIdSet.has(shelfId);
  }
}
