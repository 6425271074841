<template>
  <span class="app-popular-text">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AppPopularText"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-popular-text {
  display: inline-block;
  border: 1px solid #39c8d0;
  border-radius: 999px;
  padding: 6px 12px;
  color: #39c8d0;
  font-size: 14px;
  line-height: 20px;
}
</style>
