import Vue from "vue";
import wrap from "@vue/web-component-wrapper";
import AdsmartUIWrapper from "@/adsmart-ui/AdsmartUIWrapper.vue";
import AdsmartUISwitchWrapper from "@/adsmart-ui/AdsmartUISwitchWrapper.vue";
import AdsmartUI from "@/adsmart-ui/core";

window.customElements.define("adsmart-ui", wrap(Vue, AdsmartUIWrapper));
window.customElements.define(
  "adsmart-ui-switch",
  wrap(Vue, AdsmartUISwitchWrapper)
);
window.AdsmartUI = AdsmartUI;
