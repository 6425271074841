import AdsmartChannel from "./AdsmartChannel";

export default class AdsmartChannelsMap extends Map {
  constructor() {
    super();
  }

  getAdsmartChannel(channelData, AdsmartChannelClass = AdsmartChannel) {
    return new AdsmartChannelClass(channelData);
  }

  registerChannel(channelIdentify, channelData) {
    const channel = this.getAdsmartChannel(channelData);
    this.set(channelIdentify, channel);
    return channel;
  }

  updateChannel(channelIdentify, channelData) {
    let channel;
    if (this.has(channelIdentify)) {
      channel = this.get(channelIdentify);
      channel.updateBoards(channelData.boards);
    } else {
      channel = this.registerChannel(channelIdentify, channelData);
    }
    return channel;
  }

  reactiveBoard(vm) {
    const { adsBoardIdentify } = vm.props;
    const head = this.values();
    for (let i = 0; i < this.size; i++) {
      const channel = head.next().value;
      if (channel.hasBoard(adsBoardIdentify)) {
        channel.reactiveBoard(adsBoardIdentify, vm);
        break;
      }
    }
  }
}
