import AdsmartExposeController from "./AdsmartExposeController/index.js";
import AdsmartLegacyExpose from "./AdsmartExpose.js";
import NCCLogHandler from "./AdsmartExposeController/NCCLogHandler.js";

const LEGACY_EXPOSE_GROUP_NO = -1;

export default class AdsmartTracker {
  #adsmartExposeMap = new Map();

  constructor(token = "") {
    this.token = token;
  }

  #getAdsmartExposeFromMap(groupNo) {
    if (!this.#adsmartExposeMap.has(groupNo)) {
      const adsmartExpose =
        groupNo === LEGACY_EXPOSE_GROUP_NO
          ? new AdsmartLegacyExpose(this.token)
          : new AdsmartExposeController(this.token, groupNo);
      this.#adsmartExposeMap.set(groupNo, adsmartExpose);
    }
    return this.#adsmartExposeMap.get(groupNo);
  }

  async click(shelfId, groupNo = LEGACY_EXPOSE_GROUP_NO) {
    const adsmartExpose = this.#getAdsmartExposeFromMap(groupNo);
    await adsmartExpose.click(shelfId);
  }

  async view(shelfIdList, groupNo = LEGACY_EXPOSE_GROUP_NO) {
    const adsmartExpose = this.#getAdsmartExposeFromMap(groupNo);
    await adsmartExpose.view(shelfIdList);
  }

  async entry(boardIdentify, shelfIdList, groupNo = LEGACY_EXPOSE_GROUP_NO) {
    const adsmartExpose = this.#getAdsmartExposeFromMap(groupNo);
    await adsmartExpose.entry(boardIdentify, shelfIdList);
  }

  addEntryQueue(boardIdentify, groupNo = LEGACY_EXPOSE_GROUP_NO) {
    const adsmartExpose = this.#getAdsmartExposeFromMap(groupNo);
    adsmartExpose.addEntryQueue(boardIdentify);
  }

  addNCCLog(boardData, pageLayout, shelfId, jobsource) {
    const nccLog = new NCCLogHandler(boardData, pageLayout);
    nccLog.add(shelfId, jobsource);
  }
}
