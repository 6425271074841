<template>
  <div class="app-company-list">
    <div class="app-company-list__left">
      <a
        :href="material.contents.companyLogo.linkWithJobsource"
        :title="material.contents.companyName.element"
      >
        <img class="logo" :src="material.contents.companyLogo.element" />
      </a>
    </div>

    <div class="app-company-list__middle">
      <div class="app-company-list__middle__header">
        <a
          :href="material.contents.companyLogo.linkWithJobsource"
          :title="material.contents.companyName.element"
          >{{ material.contents.companyName.element }}</a
        >
      </div>

      <div class="app-company-list__middle__body">
        <div class="info">
          <span>{{ material.contents.jobLocation.element }}</span>
          <span>{{ material.contents.industryDes.element }}</span>
        </div>
        <div class="description">
          {{ material.contents.textContent.element }}
        </div>
      </div>

      <div class="app-company-list__middle__footer">
        <span
          v-for="welfareTag of material.contents.welfareTags"
          :key="welfareTag.element"
          class="tag"
          >{{ welfareTag.element }}</span
        >
      </div>
    </div>

    <div class="flag">{{ material.contents.industryTag.element }}</div>
  </div>
</template>

<script>
export default {
  name: "AppCompanyList",
  props: {
    material: {
      type: Object,
      required: true
    },
    adsLayout: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.app-company-list {
  position: relative;
  display: flex;
  border-radius: 12px;
  padding: 10px 16px;
  background-color: #fff;
  background-repeat: no-repeat;
  overflow: hidden;

  &__left {
    align-self: center;
    margin-right: 16px;

    .logo {
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  &__middle {
    flex-grow: 1;
    font-size: 7px;
    overflow: hidden;

    &__header a {
      font-size: 16px;
      line-height: 24px;
      color: #292929;
      text-decoration: none;
    }

    &__body {
      margin-bottom: 4px;

      .info {
        line-height: 20px;
        color: #7e7e7e;
        font-size: 12px;

        > span:not(:first-child):before {
          content: "";
          width: 1px;
          height: 12px;
          background-color: #7e7e7e;
          margin: 0px 4px;
          margin-top: -2px;
          vertical-align: middle;
          display: inline-block;
        }
      }

      .description {
        color: #ff7800;
        font-size: 14px;
        line-height: 22px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__footer {
      .tag {
        font-size: 12px;
        border-radius: 8px;
        height: 28px;
        display: inline-block;
        line-height: 20px;
        padding: 4px 8px;
        margin-right: 9px;
        margin-bottom: 9px;
        box-sizing: border-box;
        color: #7e7e7e;
        background-color: #f3f3f3;
      }
    }
  }
}

.flag {
  position: absolute;
  top: 22px;
  left: 22px;
  padding: 2px 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  background-color: #39c8d0;
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: center center;
}
</style>
