<template>
  <div
    ref="type-root"
    class="ads-type-18"
    @mouseenter="pauseTimer"
    @mouseleave="restartTimer"
  >
    <MainSlider :material="mainMaterial" @ads:click="clickMainSlider" />
    <SubSliderWindow>
      <template
        v-for="(name, index) of [
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right'
        ]"
        #[name]="{ activeSlot }"
      >
        <SubSlider
          :key="name"
          :material="subMaterials[index]"
          :active="activeSlot === name"
          @ads:click="linkHref => clickSubSlider(linkHref, index)"
        />
      </template>
    </SubSliderWindow>
  </div>
</template>

<script>
import MainSlider from "./components/MainSlider.vue";
import SubSlider from "./components/SubSlider.vue";
import SubSliderWindow from "./components/SubSliderWindow.vue";

export default {
  name: "AdsType18",
  components: {
    MainSlider,
    SubSlider,
    SubSliderWindow
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headIndex: 0,
      timer: null
    };
  },
  computed: {
    isMock() {
      return this.materials.length === 1;
    },
    mockMaterials() {
      return Array.from({ length: 5 }, (_, index) => ({
        ...this.materials[0],
        shelfId: this.materials[0].shelfId + index
      }));
    },
    sourceMaterials() {
      return this.isMock ? this.mockMaterials : this.materials;
    },
    mainMaterial() {
      return this.sourceMaterials[this.headIndex];
    },
    subMaterials() {
      return [
        ...this.sourceMaterials.slice(this.headIndex + 1),
        ...this.sourceMaterials.slice(0, this.headIndex)
      ];
    }
  },
  mounted() {
    this.startTimer();
    this.$emit(
      "ads:entry",
      this.materials.map((el, index) => index)
    );
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    startTimer() {
      const intervalTime = 30000;
      this.timer = setInterval(() => {
        this.headIndex = (this.headIndex + 1) % 5;
      }, intervalTime);
    },
    pauseTimer() {
      this.clearTimer();
    },
    restartTimer() {
      this.startTimer();
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    clickMainSlider(linkHref) {
      const clickIndex = this.isMock ? 0 : this.headIndex;
      this.$emit("ads:click", linkHref, clickIndex);
    },
    clickSubSlider(linkHref, index) {
      const clickIndex = this.isMock ? 0 : (this.headIndex + index + 1) % 5;
      this.$emit("ads:click", linkHref, clickIndex);
    }
  }
};
</script>

<style lang="scss">
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.ads-type-18 {
  position: relative;
  display: flex;
  overflow: hidden;

  &::before {
    content: "品牌特區";
    position: absolute;
    top: 12px;
    z-index: 1000;
    width: 80px;
    height: 50px;
    line-height: 50px;
    display: block;
    background-color: #ff9100;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.1px;
    text-align: center;
  }
}
</style>
