<template>
  <div class="slider-window">
    <div
      v-for="frame of ['left', 'right']"
      :key="frame"
      class="slider-window__frame"
    >
      <div
        v-for="blinds of ['top', 'bottom']"
        :key="blinds"
        class="blinds"
        @mouseenter="activeSlot = `${blinds}-${frame}`"
        @mouseleave="activeSlot = ''"
      >
        <slot :name="`${blinds}-${frame}`" v-bind="{ activeSlot }" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubSliderWindow",
  data() {
    return {
      activeSlot: ""
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.slider-window {
  width: 502px;
  height: 402px;
  display: flex;
}
.slider-window__frame {
  width: 50%;
  overflow: hidden;

  &:hover {
    .blinds {
      height: 0%;
    }
  }
  .blinds {
    background-color: #fff;
    border-right: 1px solid #d6d6d6;
    transition-property: height;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    height: 50%;
    overflow: hidden;
    &:last-child {
      border-bottom: 1px solid #d6d6d6;
    }
    &:hover {
      height: 100%;
    }
  }
}
</style>
