export default {
  RWD__C_MAIN: "rwd--c-main",
  RWD__COMPANY_LIST: "rwd--company-list",
  RWD__INDUSTRY_EVENT: "rwd--industry-event",
  RWD__INDUSTRY_COMPANY: "rwd--industry-company",
  RWD__FRESHMEN: "rwd--freshman",
  RWD__TRANSFER: "rwd--transfer",
  PC__B_VIP: "pc--b-vip",
  PC__C_MAIN: "pc--c-main",
  PC__CATEGORY: "pc--category",
  PC__APPLY_DONE: "pc--apply-done",
  PC__FRESHMEN: "pc--freshmen",
  PC__STUDENT: "pc--student",
  PC__TRANSFER: "pc--transfer",
  PC__MY_104: "pc--my-104",
  PC__JOB_LIST: "pc--job-list",
  PC__JOB_PAGE: "pc--job-page",
  PC__COMPANY_LIST: "pc--company-list",
  PC__COMPANY_EVENT_TAG: "pc--company-event-tag",
  PC__COMPANY_EVENT_LIST: "pc--company-event-list",
  MOBILE__C_MAIN: "mobile--c-main",
  MOBILE__JOB_PAGE: "mobile--job-page",
  MOBILE__JOB_LIST: "mobile--job-list",
  MOBILE__JOB_SEARCH: "mobile--job-search",
  MOBILE__COMPANY_LIST: "mobile--company-list",
  MOBILE__COMPANY_EVENT_TAG: "mobile--company-event-tag",
  MOBILE__COMPANY_EVENT_LIST: "mobile--company-event-list",
  APP__C_MAIN: "app--c-main",
  APP__WHO_SEEN: "app--who-seen",
  APP__WHO_NOTIFY: "app--who-notify",
  APP__JOB_PAGE: "app--job-page",
  APP__JOB_MATCH: "app--job-match",
  APP__JOB_MATCH_LIST: "app--job-match-list",
  APP__COMPANY_LIST: "app--company-list",
  APP__COMPANY_EVENT_TAG: "app--company-event-tag",
  APP__COMPANY_EVENT_LIST: "app--company-event-list",
  APP__APPLY_DONE: "app--apply-done",
  APP__APPLY_RECORD: "app--apply-record",
  APP__SEARCH: "app--search",
  APP__SEARCH_BAR: "app--search-bar",
  APP__SEARCH_RESULT: "app--search-result",
  APP__SEARCH_COMPANY: "app--search-company",
  APP__MY_SAVE: "app--my-save",
  APP__START: "app--start",
  APP__PUSH: "app--push",
  APP__RESUME: "app--resume"
};
