<template>
  <span
    v-if="matchedMaterial"
    class="ads-type-29 cursor-pointer"
    :class="layoutTheme"
    @click.prevent="onAdsClick(matchedMaterial.contents.textContent.link)"
  >
    <PreventLink
      :href="matchedMaterial.contents.textContent.linkWithJobsrouce"
      v-text="matchedMaterial.contents.textContent.element"
    />
  </span>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";

const PAGE_THEME_MAPPING = {
  [PAGE_ENUM.PC__COMPANY_EVENT_TAG]: "purple",
  [PAGE_ENUM.MOBILE__COMPANY_EVENT_TAG]: "purple",
  [PAGE_ENUM.APP__COMPANY_EVENT_TAG]: "blue"
};

export default {
  name: "AdsType29",
  components: {
    PreventLink
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    pageLayout: {
      type: String,
      default: ""
    },
    adsCustno: {
      type: String,
      default: ""
    }
  },
  computed: {
    matchedMaterialIndex() {
      return this.materials.findIndex(material => {
        return material.contents.custno.element === this.adsCustno;
      });
    },
    matchedMaterial() {
      return this.materials[this.matchedMaterialIndex];
    },
    layoutTheme() {
      return PAGE_THEME_MAPPING[this.pageLayout];
    }
  },
  watch: {
    matchedMaterialIndex: {
      handler(value) {
        if (value > -1) {
          this.$emit("ads:entry", value);
        }
      },
      immediate: true
    }
  },
  methods: {
    onAdsClick(linkHref) {
      this.$emit("ads:click", linkHref, this.matchedMaterialIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
@import "~scss/fontFamilyPC.scss";

.ads-type-29 {
  display: inline-block;
  border-radius: 24px;
  height: 24px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  // theme
  // 往後有新增不同的主題色請在 $theme-color 的 maps 新增對應的 key & value
  $theme-color: (
    purple: #78269f,
    blue: #0e66c7
  );

  @each $key, $value in $theme-color {
    &.#{"" + $key} {
      color: $value;
      border-color: $value;
    }
  }
}
</style>
