<template>
  <span class="pc-category">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PcCategory"
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyPC.scss";
.pc-category {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  color: #0055aa;
  &:hover {
    color: #499abf;
  }
}
</style>
