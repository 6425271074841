<template>
  <span class="search-bar--pc">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "SearchBarPc"
};
</script>

<style lang="scss">
@use "~scss/layout/search-bar.scss";

.search-bar--pc {
  @include search-bar.layout(101, pc);
}
</style>
