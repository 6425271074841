<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="matchedMaterial"
    class="ads-type-7 cursor-pointer"
    :gtm-data-ac="matchedMaterial.contents.keyword.element"
    @click="onAdsClick"
  >
    <component :is="pageLayoutName[pageLayout] || 'span'">
      <PreventLink
        :href="matchedMaterial.contents.bannerText.linkWithJobsource"
        v-html="textContentHtml"
      />
    </component>
  </div>
</template>

<script>
import PreventLink from "@/components/shared/PreventLink.vue";
import { PAGE_ENUM } from "@/adsmart-ui/settings/pageLayout.js";
import PcSearchBar from "./layout/PcSearchBar.vue";
import AppSearchBar from "./layout/AppSearchBar.vue";

const PC__SEARCH_BAR = "pc--search-bar";
const APP__SEARCH_BAR = "app--search-bar";

const pageLayoutName = {
  [PAGE_ENUM.PC__C_MAIN]: PC__SEARCH_BAR,
  [PAGE_ENUM.PC__JOB_LIST]: PC__SEARCH_BAR,
  [PAGE_ENUM.PC__COMPANY_LIST]: PC__SEARCH_BAR,
  [PAGE_ENUM.PC__MY_104]: PC__SEARCH_BAR,
  [PAGE_ENUM.MOBILE__C_MAIN]: PC__SEARCH_BAR,
  [PAGE_ENUM.MOBILE__JOB_SEARCH]: PC__SEARCH_BAR,
  [PAGE_ENUM.MOBILE__COMPANY_LIST]: PC__SEARCH_BAR,
  [PAGE_ENUM.APP__SEARCH]: APP__SEARCH_BAR
};
export default {
  name: "AdsType7",
  components: {
    PreventLink,
    [PC__SEARCH_BAR]: PcSearchBar,
    [APP__SEARCH_BAR]: AppSearchBar
  },
  props: {
    materials: {
      type: Array,
      default: () => []
    },
    pageLayout: {
      type: String,
      default: ""
    },
    adsKeywordInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      pageLayoutName
    };
  },
  computed: {
    matchedMaterialIndex() {
      if (!this.adsKeywordInput || !this.materials.length) return -1;
      return this.materials.findIndex(material => {
        return this.adsKeywordInput.includes(material.contents.keyword.element);
      });
    },
    matchedMaterial() {
      return this.materials[this.matchedMaterialIndex];
    },
    textContentHtml() {
      const origin = this.matchedMaterial.contents.bannerText.element;
      const keyword = this.matchedMaterial.contents.keyword.element;
      const strong = `<strong>${keyword}</strong>`;
      return origin.replace(keyword, strong);
    }
  },
  watch: {
    matchedMaterialIndex: {
      handler(value) {
        if (value > -1) {
          this.$emit("ads:entry", value);
        } else {
          this.$emit("ads:hide", 0);
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    onAdsClick() {
      this.$emit(
        "ads:click",
        this.matchedMaterial.contents.bannerText.link,
        this.matchedMaterialIndex
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/common.scss";
</style>
