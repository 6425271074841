<template>
  <span class="c-main--shape-mobile">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "CMainShapeMobile"
};
</script>

<style lang="scss">
@import "~scss/layout/c-main.scss";

.c-main--shape-mobile {
  @include useCMainLayout(100, mobile);
}
</style>
