export default class AdsmartHref {
  constructor(url, jobsource) {
    this.url = new URL(url);
    if (!this.url.searchParams.has("jobsource")) {
      this.url.searchParams.append("jobsource", jobsource);
    }
  }

  get _isInsideDomain() {
    return this.url.host.indexOf("104") !== -1;
  }

  outsideDomainConfirm() {
    return window.confirm(
      "您即將離開104人力銀行暨一零四集團相關網域，提醒您！確定離開後，請小心謹慎使用您的個人資料！"
    );
  }

  goNewPage() {
    if (!this._isInsideDomain && !this.outsideDomainConfirm()) {
      return false;
    } else {
      const a = document.createElement("a");
      a.href = this.url;
      a.target = "_blank";
      a.click();
    }
  }
}
