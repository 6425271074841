import { postViewExpose as apiPostViewExpose } from "@/api/pages.js";
import { deviceViewIdSetting } from "../../settings/composeSettings.js";
import Expose from "./Expose.js";

export default class ViewExpose extends Expose {
  constructor(token) {
    super(token);
  }
  async expose(shelfIdAry, groupNo) {
    try {
      const stagingShelfIdAry = shelfIdAry.filter(shelfId => {
        return !this.hasExposedId(shelfId);
      });
      if (stagingShelfIdAry.length > 0) {
        await apiPostViewExpose({
          shelfIds: stagingShelfIdAry.join(","),
          view: deviceViewIdSetting[groupNo],
          token: this.token
        });
        stagingShelfIdAry.forEach(shelfId => {
          this.addExposedId(shelfId);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
