import typesConfig from "../../settings/typesConfig";

export default class AdsmartTypeConfig {
  #contentMapping = {};
  #currentTypeConfig = {};
  #contentSettings = [];
  constructor(typeId, config = typesConfig) {
    this.#currentTypeConfig = config[typeId];
    this.#contentSettings = this.#currentTypeConfig.contents;
    this.#contentSettings.forEach((setting, settingIndex) => {
      const { source, sourceArray } = setting;
      // source, sourceArray 在 typesConfig 只會擇一設定
      if (source) {
        this._addContentSetting(source, settingIndex);
      } else if (sourceArray) {
        sourceArray.forEach(source => {
          this._addContentSetting(source, settingIndex);
        });
      }
    });
  }

  get mode() {
    return this.#currentTypeConfig?.mode ?? "";
  }

  get compose() {
    return this.#currentTypeConfig?.compose ?? false;
  }

  getContentName(content = {}) {
    return this._getContentSetting(content)?.name;
  }

  _addContentSetting(source = [], settingIndex) {
    const [typeGroupNo, sort] = source;
    if (this.#contentMapping[typeGroupNo] === undefined) {
      this.#contentMapping[typeGroupNo] = {};
    }
    this.#contentMapping[typeGroupNo][sort] = settingIndex;
  }
  _getSettingIndex(typeGroupNo, sort) {
    return this.#contentMapping?.[typeGroupNo]?.[sort];
  }
  _getContentSetting(content = {}) {
    const { typeGroupNo, sort } = content;
    const settingIndex = this._getSettingIndex(typeGroupNo, sort);
    if (settingIndex !== undefined) {
      return this.#contentSettings[settingIndex];
    }
  }
}
