<template>
  <span class="ads-type-108">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AdsType108IndustryEventShapePC"
};
</script>

<style lang="scss" scoped>
@use "~scss/layout/industry-event.scss";

.ads-type-108 {
  @include industry-event.layout(108, pc);
}
</style>
