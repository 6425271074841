<template>
  <div class="app-c-main">
    <div class="app-c-main__image">
      <img
        :src="
          material.contents.image.element ||
          `${env.VUE_APP_CDN_STATIC_URL}images/personalHeader/img-blank-profile@2x.png`
        "
      />
    </div>

    <div class="app-c-main__content">
      <p class="app-c-main__content__title">
        {{ material.contents.title.element }}
      </p>
      <p class="app-c-main__content__subtitle">
        {{ material.contents.subtitle.element }}
      </p>
    </div>
  </div>
</template>

<script>
import env from "@/utils/env.js";

export default {
  name: "AppCMain",
  props: {
    material: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      env
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/reset.scss";
@import "~scss/fontFamilyAPP.scss";

.app-c-main {
  display: flex;
  width: 176px;
  padding: 0 12px;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 90px;
    margin-left: 12px;
    overflow: hidden;

    &__title {
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: normal;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      color: #a9a9a9;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
