<template>
  <div class="rwd-c-main-mobile">
    <div
      v-for="(material, index) of materials"
      :key="index"
      class="slide"
      @click.prevent="
        $emit('ads:click', material.contents.bannerImage.link, index)
      "
    >
      <a
        :href="material.contents.bannerImage.linkWithJobsource"
        target="_blank"
        @click.prevent
        @contextmenu.prevent
      >
        <img :src="material.contents.bannerImage.element" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "CMainShapeMobile",
  props: {
    materials: {
      type: Array,
      default() {
        return [
          {
            contents: {
              bannerImage: {}
            }
          },
          {
            contents: {
              bannerImage: {}
            }
          }
        ];
      }
    }
  },
  mounted() {
    this.$emit("ads:entry", [0, 1]);
  }
};
</script>
<style lang="scss">
@import "~scss/layout/c-main.scss";

.rwd-c-main-mobile {
  @include useCMainLayout(102, mobile);
}
</style>
